import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserAction } from "../../../Redux/users/userActions";

import {
  SetRoleForUserAction,
  GetRoleForUserAction,
} from "../../../Redux/sidebar/roleForUser/RoleForUserActions";

import { openRole} from "../../../Redux/modals/modalActions";
import { GetRoleAction } from "../../../Redux/roles/roleAction";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FormDropDown,MultiSelectDropDown} from "../crudTableComponents/FormSelect";

import {
  crossIcon,
  noti_info_error,
  plusIcon,
} from "../../../styling_files/newUiStyles/icons";
const UserUpdateDialog = ({
  selectedID,
  setSelectedID,
  showModal,
  setShowModal,
  gridRef,
}) => {
  
  const dispatch = useDispatch();
  const navigate=useNavigate()

  const users = useSelector((state) => state.users.users);

  //get all available roles
  const roles = useSelector((state) => state.roles.roles);
  
  const role_of_current_user = useSelector(
    (state) => state.roleForUser.roleForUser
  );

  const [formData, setFormData] = useState();

  const selectedUser = users
    ? users.find((user) => user.id == selectedID)
    : null;

  const [currentUserRole, setCurrentUserRole] = useState();
  const [currentTemplateUser, setCurrentTemplateUser] = useState();

  const [prevRoles, setPrevRoles] = useState();

  //creating options for react select

  const role_options = roles?.map((role) => {
    return { value: role.id, label: role.name };
  });

  const template_user_options = users?.map((user) => {
    return { value: user.id, label: user.email };
  });

  const data = {
    email: "",
    full_name: "",
    role: "",
    template_user: "",
    is_active: "false",
    is_superuser: "false",
    password: "",
  };

  //getting all roles for redux state
  useEffect(() => {
    dispatch(GetRoleAction());
  }, []);

  useEffect(() => {
    dispatch(GetRoleForUserAction(selectedID));
  }, [selectedID]);

  useEffect(() => {
    setFormData({ ...selectedUser, password: "", template_user: "" });
  
  }, [selectedUser]);

  //usestate to get current user roles
  useEffect(() => {
    let fetched_roles = role_of_current_user.map((role) => {
      return { value: role.id, label: role.name };
    });
    setCurrentUserRole(fetched_roles);
    setPrevRoles(fetched_roles);
  }, [role_of_current_user]);

  const onSubmit = (values) => {
    setShowModal(false);

    if (selectedID) {
      dispatch(
        UpdateUserAction({
          updateData: values,
          id: selectedID,
          template_user_id: currentTemplateUser?.value,
        })
      );

      dispatch(
        SetRoleForUserAction(selectedID, prevRoles, currentUserRole)
      );
    }

    formik.resetForm();
    setSelectedID(null);
    setCurrentTemplateUser(null);
    gridRef?.current?.api.deselectAll();
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("enter valid email format").required("*required"),
    full_name: Yup.string().required("*required"),
  });

  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  const dataKeys = data ? Object.keys(data) : [];

  //handling role change
  const handleRole = (e) => {
   
    const dropdownValues = e.map((item) => item);

    setCurrentUserRole(dropdownValues);
  };

  //handling template user change

  const handleTemplateUser = (e) => {
    setCurrentTemplateUser(e);
  };

  const handleIsActive=(e)=>{
    formik.setFieldValue('is_active',e.value)
    

  }
  const handleIsSuperUser=(e)=>{
    formik.setFieldValue('is_superuser',e.value)

  }


 
 
  if (!showModal) {
    return null;
  }

  

  return ReactDOM.createPortal(
    <>
      <div>
        <div className="overlay"></div>

        <div className="modelForm">
          <div className="dialog-header">
            <div className="dialog-title">Edit User</div>
            <div className="dialog-close bc-clickable">
              <img
                src={crossIcon}
                onClick={() => {
                  setShowModal(false);
                  formik.resetForm();
                }}
              />
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            className="dialog-form"
          >
            <div className="dialog-form-fields">
              {dataKeys.map((data) => {
                return (
                  <div className="form-fields">
                    <label htmlFor={data} className="input-label">
                      {data} :
                    </label>

                    {(() => {
                      switch (data) {
                        case "is_active":
                          return (
                            <>
                              <FormDropDown
                                value={{value:formik.values[data],label:formik.values[data]?'Yes':'No'}}
                                onChangeHandler={handleIsActive}
                                  
                                select_options={[
                                  { value: false, label: "No" },
                                  { value: true, label: "Yes" },
                                ]}
                                select_name="active-select"
                              />
                            </>
                          );

                        case "role":
                          return (
                            
                              <MultiSelectDropDown
                                name="role"
                                isMulti={true}
                                value={currentUserRole}
                                onChangeHandler={handleRole}
                                select_options={role_options}
                              />
                              
                            
                          );

                        case "template_user":
                          return (
                            <FormDropDown
                              value={currentTemplateUser}
                              onChangeHandler={handleTemplateUser}
                              select_options={template_user_options}
                              select_name="template-select"
                            />
                          );

                        case "is_superuser":
                          return (
                            <FormDropDown
                              value={{value:formik.values[data],label:formik.values[data]?'Yes':'No'}}
                              onChangeHandler={handleIsSuperUser}
                               
                              select_options={[
                                { value: false, label: "No" },
                                { value: true, label: "Yes" },
                              ]}
                              select_name="superuser-select"
                            />
                          );
                        default:
                          return (
                            <>
                              <div className="dialog-input-container">
                                <input
                                  type={
                                    data == "db_password" ? "password" : "text"
                                  }
                                  className="form-control my-1"
                                  id={data}
                                  name={data}
                                  value={formik.values[data]}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  placeholder={
                                    data === "password"
                                      ? "Set new password"
                                      : ""
                                  }
                                />
                                {formik.touched[data] &&
                                  formik.errors[data] && (
                                    <img
                                      className="input-error-icon"
                                      src={noti_info_error}
                                      width="15px"
                                      height="15px"
                                    />
                                  )}
                              </div>

                              <div>
                                {formik.touched[data] && formik.errors[data] ? (
                                  <div className="error">
                                    {formik.errors[data]}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                      }
                    })()}
                  </div>
                );
              })}
            </div>

            <div className="dialog-submit">
              <button type="submit" className="Btn btn-submit">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default UserUpdateDialog;
