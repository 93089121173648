import {AxiosRequest} from './rootApi'

//api for fetching the metadata of a table
const GetTableMetaData=(appID, allowedTableID)=>{
    return AxiosRequest({url:`api/v1/masterdata/${parseInt(appID)}/tables/${parseInt(allowedTableID)}`,method:'GET',timeout:10000})
}


const GetTableAllColumnsMetaData=(appID, allowedTableID)=>{
    return AxiosRequest({url:`api/v1/masterdata/${parseInt(appID)}/tables/${parseInt(allowedTableID)}/all-columns`,method:'GET',timeout:10000})
}


const GetTableRowsData=(appID, allowedTableID)=>{
    return AxiosRequest({url:`api/v1/masterdata/${parseInt(appID)}/tables/${parseInt(allowedTableID)}/rows`,method:'GET',timeout:20000})
}

const PostTableRowData=(appID, allowedTableID, requestBody)=>{
    return AxiosRequest({url:`api/v1/masterdata/${parseInt(appID)}/tables/${parseInt(allowedTableID)}/rows`,method:'POST', timeout:10000,params:requestBody})
}

const GetTableSingleRowData=(appID, allowedTableID, rowID)=>{
    
    return AxiosRequest({
        url:`api/v1/masterdata/${parseInt(appID)}/tables/${parseInt(allowedTableID)}/rows/${parseInt(rowID)}`,method:'GET',timeout:10000})
}

const UpdateTableRowData=(appID, allowedTableID, rowID, requestBody)=>{

    
    return AxiosRequest({url:`api/v1/masterdata/${parseInt(appID)}/tables/${parseInt(allowedTableID)}/rows/${parseInt(rowID)}`,method:'PUT', timeout:10000,params:requestBody})   
}

const DeleteTableRowData=(appID, allowedTableID, rowID)=>{
    return AxiosRequest(
        {
        url:`api/v1/masterdata/${parseInt(appID)}/tables/${parseInt(allowedTableID)}/rows/${parseInt(rowID)}`,method:'DELETE',timeout:10000})
}

export {GetTableMetaData,GetTableAllColumnsMetaData, GetTableRowsData, GetTableSingleRowData,
PostTableRowData, UpdateTableRowData, DeleteTableRowData};