import {GET_USERS_XENTRAL,GET_PRINTERS_XENTRAL,USER_LOADER,PRINTER_LOADER,LOAD_FAILED} from './xentralActionTypes'
import {GetUsersFromXentralRetry,GetPrintersFromXentralRetry} from '../../api/customTableApi/xentralApi'
import { errorHandler } from '../../api/errorHandler'


export const GetXentralUsersAction=()=>{
    return async function (dispatch){
        dispatch(UserLoaderAction(true))
        
        return GetUsersFromXentralRetry()
        .then((response)=>{
            dispatch({
                
                    type:GET_USERS_XENTRAL,
                    payload:response.data.user_names
                   
            })
        })
        .catch(error=>{
            dispatch(UserLoaderAction(false))
           
            errorHandler(error)
            dispatch({
                type:LOAD_FAILED
            })
            

        })
    }

}


export const GetXentralPrintersAction=()=>{
    
    return async function (dispatch){

        dispatch(PrinterLoaderAction(true))
        
        return GetPrintersFromXentralRetry()
        .then((response)=>{
           
            dispatch({
                
                    type:GET_PRINTERS_XENTRAL,
                    payload:response.data.printer_names
                   
            })
        })
        .catch(error=>{
            dispatch(PrinterLoaderAction(false))
            errorHandler(error)
            dispatch({
                type:LOAD_FAILED
            })
            

        })
    }

}

export const UserLoaderAction=(loaderState)=>{
    return {
        type:USER_LOADER,
        payload:loaderState
    }
}
export const PrinterLoaderAction=(loaderState)=>{
    return {
        type:PRINTER_LOADER,
        payload:loaderState
    }
}