import {GET_COLUMN_PERMISSION} from './actionTypes'
import combine from './combineMetAll'
import { GetAllColumn,GetMetadata,PostTablePerm,DeleteTablePerm} from '../../api/appTablePermissionApi'
import { SuccessAlert,ErrorAlert } from '../../components/notifications/notifications'
import { GetCurrentUserAction } from '../userProfile/userProfileActions'
import { errorHandler } from '../../api/errorHandler'



const GetColumnsPermAction=(appID,dbID,allowed_table_id)=>{
     
  

    return async function(dispatch){

        
            Promise.all(
                [GetMetadata(appID,allowed_table_id),GetAllColumn(dbID,allowed_table_id)]
            )
            .then(response=>{
               
              
               const col_combined=combine(response)

               dispatch({
                type:GET_COLUMN_PERMISSION,
                payload:col_combined
               })

               

            })
            .catch(error=>errorHandler(error))
            
        
    
    }

}

const SavePermissionAction=(appID,dbID,allowed_table_id,addList,delList)=>{
    
    return async function (dispatch){
        

        //adding permisson
        if(addList.length){
            Promise.all(addList.map(item=>{
                return PostTablePerm(appID,item)
            }))
            .then(
                response=>{SuccessAlert('Permissions added')
                dispatch(GetColumnsPermAction(appID,dbID,allowed_table_id))
                         }
            )
            .then(dispatch(GetCurrentUserAction()))
            .catch(error=>errorHandler(error))
        }
        
        //deleting permisson

        if(delList.length){
            Promise.all(delList.map(item=>{
                return DeleteTablePerm(appID,item)
            }))
            .then(
                
                response=>{SuccessAlert('Permissions deleted')
                dispatch(GetColumnsPermAction(appID,dbID,allowed_table_id))}
            )
            .then(dispatch(GetCurrentUserAction()))
            .catch(error=>errorHandler(error))
    

        }
        

      
    }
}

export {GetColumnsPermAction,SavePermissionAction}