import { useState,useContext } from 'react'
import {useNavigate} from 'react-router-dom'

import { UserContext } from '../../contexts/UserAuthContext'

const Auth=()=>{
    const [userLoggedIn,setUserLoggedIn]=useContext(UserContext)

    //function to get token from local storage
    const getToken=()=>{
        const stored_Token=localStorage.getItem('token')
        return stored_Token
        }
        
    //function to get user from local storage
    const getUser=()=>{
        const stored_user=localStorage.getItem('user')
        
        return stored_user
        }
    

        
   
    const [user,setUser]=useState(getUser())
   
    const [token,setToken]=useState(getToken())



    //function to save token,user from api call to session-storage

    const saveUserToken=(user,token)=>{
        localStorage.setItem('user',user)
        localStorage.setItem('token',token);
        

        setToken(token)
        setUser(user)

       
        setUserLoggedIn(localStorage.getItem('token'))
     

        
    }   
    const logOut = () => {
       
        localStorage.clear();
        
        setUserLoggedIn(null)
       
        }

      
    
    return {
        setToken:saveUserToken,
        setUser,
        token,
        user,
        getToken,    
        logOut

        
    }
}


export default Auth



