import {GET_COLUMN_PERMISSION} from './actionTypes'

const initialState={
    colpermissions:[],
    errors:[]
}


const TablePermReducer=(state=initialState,action)=>{
    switch(action.type){
        case GET_COLUMN_PERMISSION:
            const all_cols_perm=[...action.payload]
            const newFetchState={...state,colpermissions:all_cols_perm,errors:[]}
            return newFetchState
        default :
            return state
                

}
}

export {TablePermReducer}