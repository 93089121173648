import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { useDispatch, useSelector } from "react-redux";
import { UpdateTableRowDataAction } from "../../../../Redux/masterdata/masterdataActions";
import validationSchemaMapper from "../../MasterDataCrud/validationSchemaMapper";
import { useFormik } from "formik";

import {FormDropDown} from '../../crudTableComponents/FormSelect'



import { crossIcon } from "../../../../styling_files/newUiStyles/icons";

import { ClearDagState } from "../../../../Redux/loader/LoaderAction";



const LeitStandUpdateDialog = ({

  appID,
  allowedTableID,
  updateDialogueInputFields,
  setUpdateDialogueInputFields,
  selectedRow,
  setSelectedRow,
  gridRef
}) => {


  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);






  const xentral_users = useSelector(state => state.xentral.users)
  const xentral_printers = useSelector(state => state.xentral.printers)

  //options for select component

  const user_options = xentral_users?.map((value) => {
    return { value: value, label: value };
  });

  const printer_options = xentral_printers?.map((value) => {
    return { value: value, label: value };
  });



  //state for user and printer handle selection

  const [selectedUser, setSelectedUser] = useState()
  const [selectedPrinter, setSelectedPrinter] = useState()






  let inputFieldsValues = { ...updateDialogueInputFields.values };
  let inputFieldsTypes = { ...updateDialogueInputFields.types };
  let inputFieldIsNullable = { ...updateDialogueInputFields.nullable }

  const inputFieldHasDefault = { ...updateDialogueInputFields.has_default }

  //replace null values with ''

  const [formikData, setFormikData] = useState()


  useEffect(() => {

    const row_data = selectedRow.data
    // const formikValues=Object.keys(inputFieldsValues).reduce((acc, key) => {
    //   acc[key] = inputFieldsValues[key] === null ?
    //     '' 
    //     : 
    //     inputFieldsValues[key]
    //   return acc }, {})
    //   console.log('formikvalues',formikValues)

    const formikValues = {
      Username: row_data?.Username,
      Kommilauf: row_data?.Kommilauf,
      Druckerkennung: row_data?.Druckerkennung
    }

    setFormikData(formikValues)

  }, [selectedRow])





  //update action: pass from props

  const onSubmit = (values) => {

    formik.resetForm();
    dispatch(
      UpdateTableRowDataAction(appID, allowedTableID, selectedRow.id, values)
    );
    let update_info = { ...updateDialogueInputFields };
    update_info.show = false;
    setUpdateDialogueInputFields(update_info);

    gridRef.current.api.deselectAll()



  };


  //handling user selection
  const handleUserSelect = (e) => {
    setFormikData({ ...formikData, Username: e.value })


  }

  //handling printer selection

  const handlePrinterSelect = (e) => {


    setFormikData({ ...formikData, Druckerkennung: e.value })

  }
  const handleChangeGeneral = (e) => {
    console.log(e.value)
  }


  const formik = useFormik({

    initialValues: formikData,
    enableReinitialize: true,
    onSubmit,
    validationSchema: formikData ? validationSchemaMapper(inputFieldsTypes, inputFieldIsNullable, inputFieldHasDefault) : null
  });



  const inputFieldNames = inputFieldsValues
    ? Object.keys(inputFieldsValues)
    : [];



  //react-select styling
  const style = {
    control: (base) => ({
      ...base,
      border: '1px solid #ced4da;',
      // This line disable the blue border
      boxShadow: "none",
      height: "40px",
      minHeight: "20px",
      padding: 0,
      margin: 0,
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
        backgroundColor: isSelected ? "#2D81A1" : isFocused ? '#B9D9EB' : null,

      };
    }
  };






  if (!updateDialogueInputFields.show) {
    return null;
  }




  return ReactDOM.createPortal(
    <>
    <div className="overlay">

      
    
    <div className="modelForm">
          <div className="dialog-header">
            <div className="dialog-title">Update</div>
            <div className="dialog-close">
              <img
                src={crossIcon}
                onClick={() => {

                  let update_info = { ...updateDialogueInputFields };
                  update_info.show = false;
                  setUpdateDialogueInputFields(update_info);
                  formik.resetForm();
  
  
                }}
              />
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            className="dialog-form"
          >
            <div className="dialog-form-fields">
              {inputFieldNames.map((data) => {
                return (
                  <div className="form-fields">
                    <label htmlFor={data} className="input-label">
                      {data} :
                    </label>

                    {(() => {
                      switch (data) {

                        case 'Username':
                          return (

                            <FormDropDown
                              name="username"
                              isMulti={false}

                              onChangeHandler={value => {

                                formik.setFieldValue('Username', value.value)
                              }}
                              select_options={user_options}

                            />
                            
                          );
                          case 'Druckerkennung':
                          return (
                            <FormDropDown
                              name="Druckerkennung"
                              isMulti={false}

                              onChangeHandler={value => {

                                formik.setFieldValue("Druckerkennung", value.value)
                              }}
                              select_options={printer_options}

                            />
                            
                          );

                          case 'Export':
                          return (
                            <FormDropDown
                            name="Export"
                            isMulti={false}

                            onChangeHandler={value => {

                              formik.setFieldValue("Export", value.value)
                            }}
                            select_options={printer_options}

                          />
                           
                          );
                       
                        default:
                          return (
                            <>
                              <input
                                type={inputFieldsTypes[data]}
                                className="form-control my-1"
                                placeholder={`Enter ${data}`}
                                
                                id={data}
                                name={data}
                                value={formik.values[data]}
                                onBlur={formik.handleBlur}
                                onChange={e=>formik.setFieldValue(data,e.target.value)}
                                
                                
                              />
                              <div>
                                {formik.touched[data] && formik.errors[data] ? (
                                  <div className="error">
                                    {formik.errors[data]}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                      }
                    })()}
                  </div>
                );
              })}
            </div>

            <div className="dialog-submit">
            <button type="subit" className="Btn btn-submit">
              Save changes
            </button>
            </div>
          </form>
    </div>
    </div>
   
    </>,
    document.getElementById('portal')
  );
};

export default LeitStandUpdateDialog;
