import { Column } from "ag-grid-community"

export const CellEditPermission=(all_column_permissions,columns,cellName)=>{


    if(Object.keys(all_column_permissions).length && all_column_permissions.update){

        
        return all_column_permissions.update
    }
    else if(columns.length){
       

        const [cellData]=columns.filter(column=>column.column_name===cellName)
        const cellUpdatePermission=cellData?cellData.permissions.update:false
        return cellUpdatePermission
       

    }
    else{
        return false
    }

}

