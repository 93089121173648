export const mapDbToAllowedTable=(allTable)=>{
  
    const allAllowedTable=allTable.map(table=>table.data)
  

    const name_mapped=allAllowedTable.length?allAllowedTable.map(response=>{

        
  
        return {db_name:response[0]?.db_alias,
                      db_id:response[0]?.db_credential_id,
                      allowed_tables:response?.map(item=>{
                          return {
                              label:item.table_name,value:item.id
                          }
                      })}
        
      }):
      []
      
      return name_mapped

}


