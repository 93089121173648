import { AxiosRequest } from "../rootApi";


export const SendToAirFlowLeitstandfrüh=(table_name)=>{
    return AxiosRequest ({url:`/api/v1/custom/Leitstand früh/${table_name}/send_to_airflow`,method:'GET',timeout:9999999})

}

export const SendToAirFlowLeitstandspät=(table_name)=>{
    return AxiosRequest ({url:`/api/v1/custom/Leitstand spät/${table_name}/send_to_airflow`,method:'GET',timeout:9999999})

}

export const SendToAirFlowLeitstandnacht=(table_name)=>{
    return AxiosRequest ({url:`/api/v1/custom/Leitstand nacht/${table_name}/send_to_airflow`,method:'GET',timeout:9999999})

}