
import {SET_CURRENT_APP_TABLE,CLEAR_CURRENT_APP_TABLE} from './actionTypes'

const SetCurrentAppTableAction=(value)=>{
    
        return {
            type:SET_CURRENT_APP_TABLE,
            payload:value
        }

}

const ClearCurrentAppTable=()=>{
    return  {
        type:CLEAR_CURRENT_APP_TABLE
    }

}


export {SetCurrentAppTableAction,ClearCurrentAppTable}