import { SuccessAlert } from "../../../components/notifications/notifications";
import { errorHandler } from "../../../api/errorHandler";
import { HideLoaderAction,GetLastDagStateAction} from "../../loader/LoaderAction";


import { SendToAirFlow_auto_umlagerung } from "../../../api/customTableApi/auto_umlagerungApi";

export const SendToAirFlow_auto_umlagerungAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlow_auto_umlagerung(table_name)
          .then((response) => {

            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
           
          })
          .catch(error=>{
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            errorHandler(error)
          })
      };

  }