import {FETCH_ALLOWED_TABLES} from './actionTypes'
import { GetAllowedTable,DeleteAllowedTables,CreateAllowedTables } from '../../api/allowedTableApi'
import { SuccessAlert} from '../../components/notifications/notifications'
import { errorHandler } from '../../api/errorHandler'

const GetAllowedTableAction=(dbId)=>{
    return async function (dispatch){
        return GetAllowedTable(dbId).then((response)=>{

            
            
            dispatch({
                
                    type:FETCH_ALLOWED_TABLES,
                    payload:response.data,
                
            })
        })
        .catch(error=>errorHandler(error))
    }
    
}

const SaveAllowedTableAction=(dbId,PrevTables,CurrTables)=>{

    return async function (dispatch){

        const PrevTablesList=PrevTables.map(table=>table.table_name)
        const deleted_tables=PrevTablesList.filter(x=>!CurrTables.includes(x))
        const created_tables=CurrTables.filter(x=>!PrevTablesList.includes(x))
        
        const deleted_tables_ID=PrevTables.map(table=>{
            if(deleted_tables.includes(table.table_name)){
                return table.id
            }
        }).filter(id=>id!=undefined)
   
   

    
    //deleting allowed tables
    if(deleted_tables_ID.length){
        Promise.all(deleted_tables_ID.map(id=>{
            if(id){
             return(DeleteAllowedTables(dbId,id))
            }
           
        }))
        .then(response=>
            {   SuccessAlert('Removal from allowed table successful')
                 
            
                dispatch(GetAllowedTableAction(dbId))
            }
            
            
            )
        .catch(error=>errorHandler(error))
     }

    //creating allowed tables
    if(created_tables.length){

        Promise.all(created_tables.map(table=>{
          
            return CreateAllowedTables(dbId,{table_name:table})
        }))
        .then(response=>{
            const createdtables=response.map(item=>item.data.table_name)
            SuccessAlert('Add to allowed table successful')
            dispatch(GetAllowedTableAction(dbId))
        })
            
        .catch(error=>errorHandler(error))
        
    }


    

}

        
          
    
}



export {GetAllowedTableAction,SaveAllowedTableAction }