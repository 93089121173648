import {AxiosRequest} from './rootApi'



const GetAllTable=(dbId)=>{
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(dbId)}/all-tables`,method:'GET',timeout:20000})
}

const GetAllowedTable=(dbId)=>{
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(dbId)}/allowed-tables`,method:'GET',timeout:20000})

}


const CreateAllowedTables=(dbId,newTables)=>{
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(dbId)}/allowed-tables`,method:'POST',params:newTables})
}

const DeleteAllowedTables=(dbId,allowed_table_id)=>{
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(dbId)}/allowed-tables/${allowed_table_id}`,method:'DELETE'})

}





export {GetAllTable,GetAllowedTable,DeleteAllowedTables,CreateAllowedTables}