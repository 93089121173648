// import { SEND_TO_AIRFLOW } from "../masterdataActionTypes";
import { SuccessAlert } from "../../../components/notifications/notifications";

import { errorHandler } from "../../../api/errorHandler";

import { HideLoaderAction,GetLastDagStateAction } from "../../loader/LoaderAction";

import { SendToAirFlowKommilauf } from "../../../api/customTableApi/kommilaufApi";

export const SendToAirFlowKommilaufAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlowKommilauf(table_name)
          .then((response) => {
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
           
          })
          .catch(error=>{
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            errorHandler(error)
          })
      };

  }