import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { PostAppAction } from "../../../Redux/app/appActions";
import { useNavigate,useLocation } from "react-router-dom";
import { formik, useFormik } from "formik";

import * as Yup from "yup";

import LabelFormatter from "../crudTableComponents/labelFormatter";
import { FormDropDown } from "../crudTableComponents/FormSelect";
import { crossIcon,noti_info_error,plusIconBlack,left_arrow,right_arrow} from "../../../styling_files/newUiStyles/icons";
import { closeRole,addRoute,removeRoute,openAttach,RemoveTempAtachData} from "../../../Redux/modals/modalActions";


const AppCreateDialog = ({ appModalOpen,closeAppModal }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const modal_routes=useSelector(state=>state.modals.modal_routes)
  const temp_attach_data=useSelector(state=>state.modals.attachData)

  //previous-current-next routes

  const current_modal=useLocation().pathname
  const previous_modal=modal_routes[modal_routes.indexOf(current_modal)-1]
  const next_modal=modal_routes[modal_routes.indexOf(current_modal)+1]

  const data = {
    application_name: "",
    application_description: "",
    type:"",
    hide_primary_key:"",
    add_allowed_table:''
 
  };

  const initial_formik_values=JSON.parse(localStorage.getItem('app-create')) ?JSON.parse(localStorage.getItem('app-create')):data


  const [appType,setApptype]=useState({value:'crud',label:'CRUD'})
  const [hidePrimary,setHidePrimary]=useState({value:false,label:'No'})


  const handleApptype=(e)=>{
    setApptype(e)
  }

  const handleHidePrimary=(e)=>{
    setHidePrimary(e)
  }

  const handleClose=()=>{

    if(modal_routes.length>0){

      if(modal_routes.length===1 && modal_routes[0]===current_modal){
        closeAppModal()
      dispatch(removeRoute(current_modal))
      formik.setValues(data);
      localStorage.removeItem('app-create')
      dispatch(RemoveTempAtachData())


      }
      else{
        navigate(previous_modal?previous_modal:current_modal)
        dispatch(removeRoute(current_modal))
        closeAppModal()
    
      formik.setValues(data);
      localStorage.removeItem('app-create')
      dispatch(RemoveTempAtachData())
      }
      

    }
    else{
      closeAppModal()
      dispatch(removeRoute(current_modal))
      formik.setValues(data);
      localStorage.removeItem('app-create')
      dispatch(RemoveTempAtachData())

    }

  }

  const toAttachTable=()=>{
    navigate('/dashboard/settings/apps/attach/0')
    dispatch(addRoute("/dashboard/settings/apps/attach/0"))
    
    dispatch(openAttach())
  }

  const onSubmit = (data) => {

    if (temp_attach_data.length>0) {
      


      if(modal_routes.length===1 && modal_routes[0]===current_modal){

        const values = {
          ...data,
          application_type:appType?.value,
          hide_primary_key: hidePrimary?.value,
        };
  
        dispatch(PostAppAction({data:values,attachData:temp_attach_data}));
        closeAppModal();
        dispatch(removeRoute(current_modal))
        formik.setValues(data);
        localStorage.removeItem('app-create')
        dispatch(RemoveTempAtachData())

      }
      else{
        const values = {
          ...data,
          application_type:appType?.value,
          hide_primary_key: hidePrimary?.value,
        };
  
        dispatch(PostAppAction({data:values,attachData:temp_attach_data}));
        navigate(previous_modal)
        closeAppModal();
        dispatch(removeRoute(current_modal))
        formik.setValues(data);
        localStorage.removeItem('app-create')
        dispatch(RemoveTempAtachData())
        
        

      }
     

      
    } 
    else {
      const values = {
        ...data,
        application_type:appType?.value,
        hide_primary_key: hidePrimary?.value,
      };

      dispatch(PostAppAction({data:values}));
      closeAppModal();
      dispatch(removeRoute(current_modal))
      formik.setValues(data);
      localStorage.removeItem('app-create')
    }
  };

  const validationSchema = Yup.object({
    application_name: Yup.string().required("required"),
    application_description: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues:initial_formik_values,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {

    localStorage.setItem('app-create',JSON.stringify(formik.values))
    const storedValues = JSON.parse(localStorage.getItem('role-create'));  
   
  }, [formik.values]);
  const dataKeys = data ? Object.keys(data) : [];

 
 
  if (!appModalOpen) {
    return null;
  }


 
  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>
  
      <div className="modelForm">

      <div className="dialog-header">
          <div className='dialog-title'>
           Create New App
          </div>
  <div className='dialog-close bc-clickable'>
  <img src={crossIcon}

  onClick={handleClose}
  />
  </div>
  

</div>


  <form
    onSubmit={(e) => {
      e.preventDefault();
      formik.handleSubmit(e);
    }}
    className='dialog-form'
  >
    <div className="dialog-form-fields">

    {dataKeys.map((data) => {
      return (
        <div className="form-fields">
          <label htmlFor={data} className='input-label'>
            {LabelFormatter(data)} 
          </label>

          {(() => {
            switch (data) {
  
              case 'type':
                return (
                  <FormDropDown
                  value={appType}
                  onChangeHandler={handleApptype}
                  select_options={[{value:'crud',label:"CRUD"},{value:'airflow',label:'Airflow'},{value:'custom',label:'Custom'}]}
                  select_name='type-select'

                />)

              
              case "hide_primary_key":
                return (
                  <FormDropDown
                  value={hidePrimary}
                  onChangeHandler={handleHidePrimary}
                  select_options={[{value:false,label:"Yes"},{value:true,label:'No'}]}
                  select_name='hide_primary-select'

                />
                );
              case 'add_allowed_table':
                return (
                 <div className="button-container">
                <span className="button-label mx-2"></span>
                <button 
                  className="Btn attachBtn" 
                  onClick={toAttachTable}
                 

                >
                  <div>
                    <img
                      className="mx-1 mb-1"
                      src={plusIconBlack}
                      alt="new"
                      width="15px"
                      height="15px"
                      title="New"
                    />
                  </div>
                  <span className="mx-1 fs-6">{temp_attach_data.length>0?'Added':'ADD'}</span>
                </button>
                </div>
             
                )
              default:
                return (
                  <>
                    <div className="dialog-input-container">
                    <input
                      type={data == "db_password" ? "password" : "text"}
                      className="form-control my-1"
                      id={data}
                      name={data}
                      value={formik.values[data]}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder={`Enter ${data}`}
                    />
                     {
                              formik.touched[data] && formik.errors[data]
                              &&
                              <img
                              className="input-error-icon"
                              src={noti_info_error}
                              width='15px'
                              height='15px'
                            />

                            }

                    </div>
                    
                    <div>
                      {formik.touched[data] && formik.errors[data] ? (
                        <div className="error">
                          {formik.errors[data]}
                        </div>
                      ) : null}
                    </div>
                  </>
                );
            }
          })()}
        </div>
      );
    })}
    </div>

          <div className="dialog-submit">


            <button 
              type="button" 
              className={`Btn ${previous_modal?'btn-prevNext-active':'btn-prevNext'}`}
              onClick={()=>navigate(previous_modal)}
              disabled={!Boolean(previous_modal)}
            >
              <img src={left_arrow} width='15px' height='15px'  />
            </button>
            <button type="subit" className="Btn btn-submit ">
              Save changes
            </button>

            <button 
            type="button" 
            className={`Btn ${next_modal?'btn-prevNext-active':'btn-prevNext'}`}
            onClick={()=>navigate(next_modal)}
            disabled={!Boolean(next_modal)}
            >
              <img src={right_arrow} width='15px' height='15px'  />
            </button>
          </div>
  </form>
</div>
    
    </>,
    document.getElementById("portal")
  );
};

export default AppCreateDialog;
