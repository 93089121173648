import {GET_CURRENT_USER,UPDATE_CURRENT_USER,CLEAR_CURRENT_USER} from './userProfileActionTypes'

const initialState={
    CurrentUser:{}
}
const CurrentUserReducer=(state=initialState,action)=>{

    switch(action.type){
        case GET_CURRENT_USER:
            const newUserState={...state,CurrentUser:action.payload}
            
            return newUserState
        case UPDATE_CURRENT_USER:
            return {...state,CurrentUser:action.payload}

        case CLEAR_CURRENT_USER:
            return {}

        default :
            return state
    }

}


export default CurrentUserReducer