import {AxiosRequest} from './rootApi'



const GetMetadata=(appId,allowed_table_id)=>{
    return AxiosRequest({
        url:`api/v1/applications/${parseInt(appId)}/tables/${parseInt(allowed_table_id)}`,
        method:'GET'}) 
}

const GetAllColumn=(dbId,allowed_table_id)=>{
    return AxiosRequest({
        url:`/api/v1/db-credentials/${parseInt(dbId)}/allowed-tables/${parseInt(allowed_table_id)}/columns`,
        method:'GET'}) 

}

const PostTablePerm=(appId,postData)=>{

    return AxiosRequest(
        {url:`/api/v1/applications/${parseInt(appId)}/permissions`,
        method:'POST',
        params:postData}) 

}
const DeleteTablePerm=(appId,delData)=>{
    return AxiosRequest(
        {url:`/api/v1/applications/${parseInt(appId)}/permissions`,
        method:'DELETE',
        params:delData}) 

}
//combine getmetadata and GetAllColumn


export {GetAllColumn,GetMetadata,PostTablePerm,DeleteTablePerm}