import { GET_ROLE_FOR_USER,GET_ROLE_FOR_USER_ME,CLEAR_ROLE_FOR_USER} from "./RoleForUserActionTypes";
import { GetRoleForUser,CreateRoleForUser,DeleteRoleForUser } from "../../../api/roleCRUApi";
import RoleAlert from "../../../components/crud/roleCrud/roleAlert";


const GetRoleForUserAction = (userId) => {

  
  
  return async function (dispatch) {
    if (userId) {
      return GetRoleForUser(userId).then((response) => {
        dispatch({
          type: GET_ROLE_FOR_USER,
          payload: response.data,
        });
      });
    }
  };
};


const GetRoleForUserMeAction = (userId) => {

  
  
  return async function (dispatch) {
    if (userId) {
      return GetRoleForUser(userId).then((response) => {
      
        dispatch({
          type: GET_ROLE_FOR_USER_ME,
          payload: response.data,
        });
      });
    }
  };
};




const SetRoleForUserAction = (userid, prevRoles, currentRoles) => {
  
  return async function (dispatch) {
    let prev_roles = prevRoles.map((role) => role.value);
    let curr_roles = currentRoles.map((role) => role.value);

    const create_ids = curr_roles.filter((x) => !prev_roles.includes(x));
    const delete_ids = prev_roles.filter((x) => !curr_roles.includes(x));

    //create operatioons
    if (create_ids.length) {
      Promise.all(
        create_ids.map((roleid) => {
          return CreateRoleForUser(userid, {
            user_id: userid,
            role_id: roleid,
          });
        })
      )
        .then((response) => {
          RoleAlert(response, "assigned");
         dispatch(GetRoleForUserAction(userid))
        })
        .catch((error) => RoleAlert(error));
    }


    //delete operations

    if (delete_ids.length) {
      Promise.all(
        delete_ids.map((roleid) => {
          return DeleteRoleForUser(userid, roleid);
        })
      )
        .then(response => {
          RoleAlert(response, "deleted")
          dispatch(GetRoleForUserAction(userid))
          
        
        })
        .catch((error) => RoleAlert(error));
    }


  };
};


const ClearRoleForUserAction=()=>{
  return function(dispatch){
    dispatch({
      type:CLEAR_ROLE_FOR_USER
    })

  }
}

export { GetRoleForUserAction,GetRoleForUserMeAction,SetRoleForUserAction,ClearRoleForUserAction };
