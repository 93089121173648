import { SuccessAlert } from "../../../components/notifications/notifications";
import { errorHandler } from "../../../api/errorHandler";
import { HideLoaderAction,GetLastDagStateAction } from "../../loader/LoaderAction";


import { SendToAirFlowTest } from "../../../api/customTableApi/airflowTestApi";

export const SendToAirFlowTestAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlowTest(table_name)
          .then((response) => {
            
            dispatch(GetLastDagStateAction(appName,table_name)) 
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
           
          })
          .catch(error=>{
           
            dispatch(GetLastDagStateAction(appName,table_name))
            setTimeout(()=>{
              dispatch(HideLoaderAction())

            },2000)
            
            errorHandler(error)
          })
      };

  }