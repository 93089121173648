import { SearchButton } from "../Crud.styled";

import {searchIcon} from '../../../styling_files/newUiStyles/icons'

const SearchField=(
  {quickFilterText,
    setQuickFilterText,
    onFilterTextChange,
    gridApi,
    table
  })=>{

    return(
   <>
    
    <SearchButton>
        <img

        src={searchIcon}
        alt='search'
        width='15px'
        height='15px'
        className="ms-3"
        
        />
        <input
          type="search"
          placeholder="Search"
          
            onChange={
              (e)=>onFilterTextChange(e,setQuickFilterText,gridApi,table)
          } 
          className='mx-2 searchInput'
        value={quickFilterText}
        ></input>
    </SearchButton>
    </>
   

    )
   
}

export default SearchField