import * as Yup from "yup";

const yupTypeMapper=(type,key,nullable,has_default)=>{





    if(!nullable && !has_default){
        if(type==='text'){
            return Yup.string().required('required')
        }
        else if(type==='date' && key!='end_date'){
            return Yup.date().required('required')
    
        }
    
        else if(type==='date' && key==='end_date'){
            return Yup.date().min(Yup.ref('begin_date'),'end date can not be before begin date').required('*required')
    
        }
    
        else if(type==='number'){
            return Yup.number().required('required')
        }
    
        else if(type==='datetime-local'){
            return Yup.date().required('required')
        }
        else {
            return Yup.string().required('required')
        }

    }

    else{

        if(type==='text'){
            return Yup.string()
        }
        else if(type==='date' && key!='end_date'){
            return Yup.date()
    
        }
    
        else if(type==='date' && key==='end_date'){
            return Yup.date().min(Yup.ref('begin_date'),'end date can not be before begin date')
    
        }
    
        else if(type==='number'){
            return Yup.number()
        }
    
        else if(type==='datetime-local'){
            return Yup.date()
        }
        else {
            return Yup.string()
        }

    }
 

   
        
    
  }
  


  const validationSchemaMapper=(inputTypes,inputFieldIsNullable,inputFieldHasDefault)=>{


    if(inputTypes && inputFieldIsNullable && inputFieldHasDefault ){

    const objectSchema={...inputTypes}

    for (let key in objectSchema ){
        objectSchema[key]=yupTypeMapper(objectSchema[key],key,inputFieldIsNullable[key],inputFieldHasDefault[key])
    }
    return Yup.object(objectSchema)

    }
    
    
  }


export default validationSchemaMapper