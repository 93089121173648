import styled from 'styled-components'

export const CreateButton=styled.div`

height:2.5em;
width:92%;
margin-left:6rem;
margin-top:2rem;
margin-bottom:.5rem;
display: flex;
justify-content: space-between;
align-items:center

`

export const DownBtnGroup=styled.div`
height:2.5rem;
width:93%;
bottom:6rem;
margin-left:5.1rem;
padding:.15em;
position:absolute;

`

export const TableTitle=styled.div`

width:100%;
margin-left: 6.2rem;
margin-top: 1em;
font-weight: bold;
padding:.1em;
text-align: left;
font: normal normal bold 20px/25px Lato;
letter-spacing: 0px;
color: #515153;
text-transform: uppercase;


`

export const SearchButton=styled.div`
display:flex;
background-color: #FFFFFF;
align-items: center;
justify-content: flex-start;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;

width:100%;
height:50%;
max-height:3rem;


border-radius:8px;

font-family: 'inter';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height:1.5rem;





padding:0;
> input {
    border: none;
   
    
    &:focus{
        outline: none;
    }
}
`

