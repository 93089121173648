import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GetRoleForUserMeAction } from "../../../Redux/sidebar/roleForUser/RoleForUserActions";
import { GetAppForRolesAction } from "../../../Redux/sidebar/appForRole/appForRoleAction";
import { GetCurrentUserAction } from "../../../Redux/userProfile/userProfileActions";

import TableList from "./tableList";

const SidebarAppList = ({selectedType}) => {

   
  
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.CurrentUser.CurrentUser);


  //all  roles for a user
  const roleForUser = useSelector((state) => state.roleForUser.roleForUserMe);


  //all apps for selected user (all role)
  const appsForRoleAll = useSelector((state) => state.appsForRoles.appsForRoles);

  //filtering apps based on currenttly selected app type

  const appsForRole=appsForRoleAll?.filter(item=>{
    return item.application_type===selectedType

  })

  const [currentUserID, setCurrentUserId] = useState(null);

  //getting current user id
  useEffect(() => {
    if(!currentUser){
      dispatch(GetCurrentUserAction());
    }
   
  }, []);

  //checking if current user is superuser
  //getting current user id

  useEffect(() => {
    if(currentUser){
      
      setCurrentUserId(currentUser.id);

    }
   
  }, [currentUser]);

  //getting roles for the current user
  useEffect(() => {
    if(currentUserID && !roleForUser?.length){
      dispatch(GetRoleForUserMeAction(currentUserID));
    }
  
  }, [currentUserID]);


  //getting all apps for the user
  useEffect(() => {
    if(roleForUser && !appsForRoleAll?.length){
      dispatch(GetAppForRolesAction(roleForUser));
    }
   
     
    
  }, [roleForUser]);

 
  return (
    
      <>
           
       
        <div className="applist-dropdown-container">
          
          
           
              {appsForRole?.map((app) => {
                return (
                
                    <TableList
                      appName={app.application_name}
                      appId={app.id}
                      is_custom={app.application_type==='custom'?true:false}
                      key={Math.random()}
                     
                    />
                 
                );
              })
              
              }
         
    

        </div>
        
      </>
    
  );
};

export default SidebarAppList;
