import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { UpdateRoleAction } from "../../../Redux/roles/roleAction";
import { GetAppAction } from "../../../Redux/app/appActions";
import { GetAppForRole, SetAppForRole } from "../../../api/roleCRUApi";
import {
  GetAppForRoleAction,
  SetAppForRoleAction,
} from "../../../Redux/sidebar/appForRole/appForRoleAction";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MultiSelectDropDown } from "../crudTableComponents/FormSelect";

import { crossIcon,noti_info_error } from "../../../styling_files/newUiStyles/icons";

const RoleUpdateDialog = ({
  selectedID,
  setSelectedID,
  showModal,
  setShowModal,
  gridRef
}) => {
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.roles.roles);
  const cApps = useSelector((state) => state.appsForRoles.appsForRole);

  const selectedRole = roles
    ? roles.find((role) => role.id == selectedID)
    : null;
  const [formData, setFormData] = useState();

  //get all available apps
  const apps = useSelector((state) => state.apps.apps);

  const [currentRoleApps, setCurrentRoleApps] = useState();
  const [prevApps, setPrevApps] = useState();

  //create options for react-select

  const options = apps?.map((app) => {
    return { value: app.id, label: app.application_name };
  });

  const data = {
    name: "",
    description: "",
    app: "",
  };

  useEffect(() => {
    dispatch(GetAppAction());
  }, []);

  useEffect(() => {
    setFormData(selectedRole);
  }, [selectedRole]);

  //useState to get current application assigned to role

  useEffect(() => {
    if (selectedID) {
      dispatch(GetAppForRoleAction(selectedID));
    }
  }, [selectedID]);

  useEffect(() => {
    let fetched_apps = cApps?.map((app) => {
      return { value: app.id, label: app.application_name };
    });

    setCurrentRoleApps(fetched_apps);
    setPrevApps(fetched_apps);
  }, [cApps]);

  const onSubmit = (values) => {
    setShowModal(false);
    if (selectedID) {
      dispatch(UpdateRoleAction(values, selectedID));
      dispatch(SetAppForRoleAction(selectedID, prevApps, currentRoleApps));
    }

    formik.resetForm();
    setSelectedID(null)
    gridRef.current.api.deselectAll()
  
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("required"),
    description: Yup.string().required("required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData || data,

    validationSchema,
    onSubmit,
  });

  const dataKeys = data ? Object.keys(data) : [];

  //handling app change
  const handleApp = (e) => {
    const dropdownValues = e.map((item) => item);

    setCurrentRoleApps(dropdownValues);
  };

  //react-select styling
  const style = {
    control: (base) => ({
      ...base,
      outline: 0,
      margin: 0,
      // This line disable the blue border
      boxShadow: "none",
      height: "auto",
      padding: 0,
    }),
  };

  if (!showModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>

      <div className="modelForm">
        <div className="dialog-header">
          <div className="dialog-title">Edit Role</div>
          <div className="dialog-close">
            <img src={crossIcon}
              onClick={() => {
                setShowModal(false);

                formik.resetForm();
              }}
            />
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
          className="dialog-form"
        >
          <div className="dialog-form-fields">
            {dataKeys.map((data) => {
              return (
                <div className="form-fields">
                  <label htmlFor={data} className="input-label">
                    {data} :
                  </label>

                  {(() => {
                    switch (data) {
                     

                      case "app":
                        return (
                          <MultiSelectDropDown
                              
                              name="app"
                              isMulti={true}
                              value={currentRoleApps}
                              onChangeHandler={handleApp}
                              select_options={options}
                             
                            />
                          
                        );

                      
                      default:
                        return (
                          <>
                          <div className="dialog-input-container">
                          <input
                              type={data == "db_password" ? "password" : "text"}
                              className="form-control my-1"
                              id={data}
                              name={data}
                              value={formik.values[data]}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              placeholder={
                                data === "password" ? "Set new password" : ""
                              }
                            />
                            {
                              formik.touched[data] && formik.errors[data]
                              &&
                              <img
                              className="input-error-icon"
                              src={noti_info_error}
                              width='15px'
                              height='15px'
                            />

                            }

                          </div>
                           
                            <div>
                              {formik.touched[data] && formik.errors[data] ? (
                                <div className="error">
                                  {formik.errors[data]}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                    }
                  })()}
                </div>
              );
            })}
          </div>

          <div className="dialog-submit">
            <button type="submit" className="Btn btn-submit">
              
            Save changes
            </button>
          </div>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default RoleUpdateDialog;
