import {Routes,Route} from 'react-router-dom'
import UserContextProvider from '../contexts/UserAuthContext'

import PrivateHome from './PrivateHome'
import Main from '../MainView/Main.js'
import LoginForm from '../components/users_auth/LoginForm'

const AppRoutes=()=>{
    
    
    return(
    <UserContextProvider>
        
        <Routes>
            <Route path='/*' element={<PrivateHome>{<Main/>}</PrivateHome>}/>
            <Route path='/login' element={<LoginForm/>}/>
        </Routes>
        
    </UserContextProvider>
    
    )
    
    

}

export default AppRoutes