import React, { useState, useRef } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { BreadCrumbs } from "../crud/crudTableComponents/breadCrumbs";
import { CellEditorMultiDropdown} from "../crud/crudTableComponents/cellEditor";
import NewPagination from "../crud/crudTableComponents/newUIPagination";
function JobRunnerView() {

  const gridRef = useRef();

  //grid api states
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  let [selectedID, setSelectedID] = useState(null);

  const DropdownSelector=(selected_id)=>{
    if(selectedID){
      const setting=rowData.find(item=>item.id===selected_id).setting
      if(setting==='country'){
        return  ['DE','AT','NL']
      }
      else if (setting==='channels'){
        return  ['Amazon','Shopify','WooCommerce']
      }
      
      
      
    }

  }

  const rowData = [
    { id: "1", setting: "country", value: ["DE", ] },
    { id: "2", setting: "country", value: ["DE",'NL'] },
    {
      id: "3",
      setting: "channels",
      value: ["Amazon", "Shopify", "WooCommerce"],
    },
  ];

  const columnDefsData = [
    {
      headerName: "ID",
      field: "id",
      checkboxSelection: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Setting",
      field: "setting",
      editable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "value",
      field: "value",
      editable: true,
      filter: "agTextColumnFilter",
      cellEditor: CellEditorMultiDropdown,
      cellEditorParams: {
        values:DropdownSelector(selectedID),
      },
    },
  ];

  //dafault column def
  const defaultColDefs = {
    minWidth: 200,
    headerClass: "header-class",
    cellClass: "cell-class",
    flex: 1,
    sortable: true,
    resizable: true,

    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
      sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
    },
  };

  //handling selection of row

  const onSelectionChanged = (e) => {
    const row = e.api.getSelectedRows();
    const selectedid = row[0] ? row[0].id : null;
    DropdownSelector(selectedid)
    setSelectedID(selectedid);
  };

   //grid Ready
   const onGridReady = (params) => {

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
   
  };

  // This function will be called when the data has been rendered for the first time
  function handleFirstDataRendered() {
    // Automatically resize all columns to fit their content
    gridApi.autoSizeColumns();
    }

  return (
    <>
      <BreadCrumbs />

      <div className="title-layout">
        <div className="title-container">
          <div className="current-app-table">
            <p>Job runner Table</p>
          </div>
        </div>
      </div>
      <div className="table-layout-jobrunner">
        <div className="table-container">
          <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefsData}
              rowSelection="single"
              onSelectionChanged={onSelectionChanged}
              defaultColDef={defaultColDefs}
              onGridReady={onGridReady}
              onFirstDataRendered={handleFirstDataRendered}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
              // onCellValueChanged={onCellValueChanged}
              // onCellEditingStarted={onCellEditingStarted}
              // onCellEditingStopped={onCellEditingStopped}
              headerHeight={45}
              rowHeight={45}
              pagination={true}
              suppressPaginationPanel={true}
              suppressDragLeaveHidesColumns={true}
              undoRedoCellEditing={true}
            ></AgGridReact>
          </div>
        </div>
        <NewPagination/>
      </div>
    </>
  );
}

export default JobRunnerView;
