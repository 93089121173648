import React from "react";
import { jobrunnerIcon, customIcon, dataflowIcon, ediIcon } from '../../../styling_files/newUiStyles/icons'


const ApptypeTitle = ({ application_type }) => {

    switch (application_type) {
        case 'crud':
            return (
                <>
                    <div className='apptype-title'>
                        <img
                            src={dataflowIcon}
                            alt='webeditor'
                            width='27px'
                            height='27px'
                            className="mx-1"
                        />
                        <span className="mx-2">Webeditor</span>
                    </div>
                </>
            )
        case 'custom':
            return (
                <>
                    <div className='apptype-title'>
                        <img
                            src={customIcon}
                            alt='custom'
                            width='25px'
                            height='25px'
                            className="mx-1"
                        />
                        <span className="mx-2">Custom</span>
                    </div>
                </>
            )
        case 'airflow':
            return (
                <>
                    <div className='apptype-title'>
                        <img
                            src={jobrunnerIcon}
                            alt='job runner'
                            width='25px'
                            height='25px'
                            className="mx-1"
                        />
                        <span className="mx-2">Job Runner</span>
                    </div>
                </>
            )

        case 'edi':
            return (
                <>
                    <div className='apptype-title'>
                        <img
                            src={ediIcon}
                            alt='edi'
                            width='25px'
                            height='25px' 
                            className="mx-1"
                        />
                        <span className="mx-2">Edi</span>
                    </div>
                </>
            )
        default:
            return null


    }

}

export default ApptypeTitle