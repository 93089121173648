import React from "react";
import { Spinner } from "react-bootstrap";

const GenericLoader=()=>{
    return (
        <div className="dashboard-layout">
          <div className='generic-loader'>
            
          <Spinner
              as="div"
              variant="dark"
              size="xl"
              role="status"
              aria-hidden="true"
              animation="border"
              className="mx-2"
              style={{ width: "4rem", height: "4rem" }}
            />
    
          </div>
    
        </div>
      )
}
export default GenericLoader