import { ErrorAlert } from "../components/notifications/notifications"
export const errorHandler=(error)=>{

    // The request was made and the server responded with a status code
    if (error.response) {

       
        
       const status=error.response.status
       const response_data=error.response.data.detail

    
       ErrorAlert(`${status} : ${response_data}`)
        
      }

    else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser 
       
        ErrorAlert (error.message)
    }
    else{

        // Something happened in setting up the request that triggered an Error
       ErrorAlert (error.message)
    }

}