import axios from "axios";
import checkTokenValidity from "../utils/checkTokenValidity";

let API_BASE_URL = "";

switch (process.env.NODE_ENV) {
  case "production":
    API_BASE_URL = `https://${process.env.REACT_APP_API_BASE_URL_PROD}`;
    break;
  default:
    API_BASE_URL = `http://${process.env.REACT_APP_API_BASE_URL_DEV}`;
    break;
}

//creating axios instance
export const http = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
  },
});

// const checkTokenValidity = async (token) => {
//     try {
//       // Make a request to the API endpoint that checks token validity
//       const response = await http.post('/api/v1/login/test-token');

//       return response.status ==200 ?true:false;

//     } catch (error) {

//       return false;
//     }
//   };

//calling axios instance

export async function AxiosRequest({ url, method, timeout, params = {} }) {
  const token_valid = checkTokenValidity();

  if (token_valid) {
    return await http({
      url: url,
      method: method,
      data: params,
      timeout: timeout ? timeout : 5000,
    });
  } else {
    localStorage.clear();

    window.location.href = `/`;
  }
}
