import {AxiosRequest} from './rootApi'



//api for app table CRUD
const GetTableForApp=(appID)=>{

    return AxiosRequest({url:`api/v1/applications/${parseInt(appID)}/tables`,method:'GET',timeout:10000})
}

const PostTableForApp=(appID,Data)=>{

    return AxiosRequest({url:`api/v1/applications/${parseInt(appID)}/tables`,method:'POST',params:Data})
}

const GetTableForAppMasterdata=(appID)=>{
    return AxiosRequest({url:`api/v1/masterdata/${parseInt(appID)}/tables`,method:'GET'})
}




const DeleteTableForApp =(appID,allowedTableID)=>{
    return AxiosRequest({url:`api/v1/applications/${parseInt(appID)}/tables/${parseInt(allowedTableID)}`,method:'DELETE'})
}

const GetAppTableMetadata=(appID,allowedTableID)=>{
    return AxiosRequest({url:`api/v1/applications/${parseInt(appID)}/tables/${parseInt(allowedTableID)}`,method:'GET'})

}


//Api for DB allowed table

const GetAllowedTableForDb=(dbID)=>{
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(dbID)}/allowed-tables`})
}



export {GetTableForApp,GetTableForAppMasterdata,GetAppTableMetadata,GetAllowedTableForDb,PostTableForApp,DeleteTableForApp}