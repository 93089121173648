import { APP_FETCH,APP_CREATE,APP_DELETE,APP_UPDATE } from "./appActionTypes";
import {GetApp,PostApp,UpdateApp,DeleteApp} from '../../api/appCrudApi'
import { undoCellEdit } from "../../components/crud/tableState";
import { errorHandler } from "../../api/errorHandler";
import { SuccessAlert } from "../../components/notifications/notifications";

import { SaveTableForAppAction } from "../appPermission/appPermissionActions";

const GetAppAction=()=>{
   
    return async function (dispatch){
        return GetApp().then((response)=>{

            
            
            dispatch({
                
                    type:APP_FETCH,
                    payload:response.data,
                
            })
        })
        .catch(error=>errorHandler(error))
    }
    
}

const PostAppAction=({data,attachData})=>{

    return async function (dispatch){
       
        return PostApp(data).then((response)=>{
            
            dispatch({
                
                    type:APP_CREATE,
                    payload:response.data,
                
            })

            if(attachData?.length>0){
                dispatch(SaveTableForAppAction(response.data.id,[],attachData))
            }

          
            SuccessAlert('App created')
        })
        .catch(error=>errorHandler(error))
    }
    
}

const UpdateAppAction=(updateData,id,gridRef)=>{
  

    return async function (dispatch){
        
        return UpdateApp(updateData,id,gridRef)
        .then((response)=>{

          
           
            dispatch({
                
                    type:APP_UPDATE,
                    payload:{id:id,data:response.data}
                   
            })
            SuccessAlert('App updated')
        })
        .catch(error=>{
           
            undoCellEdit(gridRef)
           
            gridRef.current.api.refreshCells({force:true})
            errorHandler(error)

        })
    }
    
}

const DeleteAppAction=(id)=>{
  
    
    return async function (dispatch){
        
        return DeleteApp(id).then((response)=>{
            
            dispatch({
                
                    type:APP_DELETE,
                    payload:id
                   
            })
            SuccessAlert('App deleted' )
        })
        .catch(error=>errorHandler(error))
    }
    
}

export {GetAppAction,PostAppAction,UpdateAppAction,DeleteAppAction}