import {SET_CURRENT_APP_TABLE,CLEAR_CURRENT_APP_TABLE} from './actionTypes'


const initialState={
    value:{
        currentTable:'',
        currentApp:''
    }
}


const currentAppTableReducer=(state=initialState,action)=>{

    switch(action.type){
        case SET_CURRENT_APP_TABLE:
            return {
                ...state,value:action.payload
            }
        case CLEAR_CURRENT_APP_TABLE:
            return {
                ...initialState
            }
        default:
            return state
    }

}

export {currentAppTableReducer}