import {GET_CURRENT_USER,UPDATE_CURRENT_USER,CLEAR_CURRENT_USER} from './userProfileActionTypes'
import { GetCurrentUser,UpdateCurrentUser } from '../../api/userProfileApi'
import { SuccessAlert } from '../../components/notifications/notifications'
import { errorHandler } from '../../api/errorHandler'

const GetCurrentUserAction=()=>{

    return async function(dispatch){
        GetCurrentUser().then(response=>{
            
            dispatch({
                type:GET_CURRENT_USER,
                payload:response.data
            })
        })
        .catch(error=>errorHandler(error))

    }
}

const UpdateCurrentUserAction=(updateData)=>{

    return async function(dispatch){
        UpdateCurrentUser(updateData).then(
            response=>{
                dispatch({
                    type:UPDATE_CURRENT_USER,
                    payload:response.data
                })
                SuccessAlert('User updated successfully')
            }
        )
        .catch(error=>errorHandler(error))
    }

}

const ClearCurrentUserAction=()=>{
   
    return function(dispatch){
        dispatch({
            type:CLEAR_CURRENT_USER
        })
    }
    
}

export {GetCurrentUserAction,UpdateCurrentUserAction,ClearCurrentUserAction}