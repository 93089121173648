import {SHOW_LOADER,HIDE_LOADER,GET_LAST_DAG_STATE,REFRESHING,CLEAR_DAG_STATE} from './LoaderActionTypes'

const initialState={
    isLoading:false,
    message:'Loading.....',
    dag_state:{state:''},
    refreshing:false
}


const LoaderReducer=(state=initialState,action)=>{

    switch(action.type){

        case SHOW_LOADER:
            return {
                isLoading:true,
                message:action.payload,
                dag_state:{state:'running'}
            }
        case HIDE_LOADER:
            return {
                ...state,
                isLoading:false
            }
        case REFRESHING:
            return {
                ...state,
                refreshing:action.payload

            }
        case GET_LAST_DAG_STATE:
            return {
                ...state,
                dag_state:{state:action.payload},
                refreshing:false


            }
        
        case CLEAR_DAG_STATE:
            return {
                ...state,
                dag_state:{state:''},
                refreshing:false
            }
        default :
            return state
    }

}

export default LoaderReducer