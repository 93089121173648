import {useNavigate,useLocation} from 'react-router-dom'



export const BreadCrumbs=()=>{

    const location=useLocation()
    const navigate=useNavigate()

    const current_location=location.pathname.split('/').filter(path=>path !='').filter(path =>isNaN(path))

    
    const currentRouteMaker=(fullRoute,desiredRoute)=>{
 
        const index=fullRoute.indexOf(desiredRoute)

        if(isNaN(fullRoute[index+desiredRoute.length+1])){
            return fullRoute.substring(0,index+desiredRoute.length)

        }
        else{
            return fullRoute.substring(0,index+desiredRoute.length+2)
        }
       
        

    }

    const handleNavigation=(crumb_path)=>{
        navigate(currentRouteMaker(location.pathname,crumb_path))
    }
   
    
 
    return (

        <>
        <span className="breadcrumb-container">
       
            
        {
            current_location.map ((item,index,arr)=>(


                <>
                {
                    index===arr.length-1?
                    
                    <span className="breadcrumb breadcrumb-active px-2"> {item}</span>
                    :
                    <>
                    <span 
                    className="breadcrumb bc-clickable px-2" 
                    onClick={()=>{handleNavigation(item)}}

                    > 
                    {item}
                    </span>
                    <span className="breadcrumb">&gt;</span>
                    </>
                    

                }
                   
                    
                </>
            ))
        }
       
            
        </span>
        </>
    )
}


export const MasterdataBreadcrumbs=()=>{

    const location=useLocation()
  
    const navigate=useNavigate()

    const toDashboard=()=>{
        navigate('/dashboard')
    }
    
    const currentRouteMaker=(fullRoute,desiredRoute)=>{
        const index=fullRoute.indexOf(desiredRoute)
        if(index ===-1){
            return fullRoute
        }
        else{
            return fullRoute.substring(0,index+desiredRoute.length)
        }

    }

    const handleNavigation=(crumb_path)=>{
        navigate(currentRouteMaker(location.pathname,crumb_path))
    }
   
    const current_location=location.pathname.split('/')
    .filter((path,index)=>index===1 )
    

    
    return (

        <>
        <span className="breadcrumb-container">
    
                    <span 
                    className="breadcrumb bc-clickable px-2" 
                    onClick={toDashboard}
                    > 
                    Dashboard
                    </span>
                    {/* <span className="breadcrumb">&gt;</span> */}
                    
       
            
        {
            current_location.filter(item=>item!='customtable' && item!='generictable').map ((item,index,arr)=>(


                <>
                {
                    index===arr.length-1?
                    
                    <span className="breadcrumb breadcrumb-active px-2"> {item}</span>
                    :
                    <>
                    <span 
                    className="breadcrumb bc-clickable px-2" 

                    > 
                    {item}
                    </span>
                    <span className="breadcrumb">&gt;</span>
                    </>
                    

                }
                   
                    
                </>
            ))
        }
       
            
        </span>
        </>
    )
}

