import React from "react";
import ReactPaginate from "react-paginate";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const arrowStyle = { fontSize: "1.5rem", padding: "0" };

const NewPagination = () => {
  return (
    <div className="pagination-container">
      <div className="pagination-box">
        <div>
          <span id="pagination-info">Showing 1 to 10 from 100 entries</span>
        </div>

        <div className="rct-paginate">
          <ReactPaginate
            previousLabel={<AiOutlineArrowLeft style={arrowStyle} />}
            nextLabel={<AiOutlineArrowRight style={arrowStyle} />}
            breakLabel={"..."}
            pageCount={100}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active-page"}
          />
        </div>

        <div className="page-jump-to">
          <label htmlFor="jumpPage" className="mx-1">
            Jump to Page{" "}
          </label>
          <input
            type="number"
            value={3}
            name="quantity"
            min="1"
            size="2"
            id="jumpPage"
          />
        </div>
      </div>
    </div>
  );
};

export default NewPagination;
