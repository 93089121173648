const checkTokenValidity = () => {
  
  let token=localStorage.getItem('token')

  let token_expiry=localStorage.getItem('token_expiry')

  if (token && !token_expiry) {
    const payloadBase64 = token.split(".")[1];
    const decoded = JSON.parse(atob(payloadBase64));
    const expiry = decoded.exp;
    localStorage.setItem('token_expiry',expiry)
    const currentTime = Math.floor(Date.now() / 1000);
    return expiry>currentTime;
  }
  
  else if(token_expiry){

    const currentTime = Math.floor(Date.now() / 1000);
    return token_expiry>currentTime;

  }
  else{
    return false
  }
};

export default checkTokenValidity;
