import {AxiosRequest} from './rootApi'



const GetUser=()=>{

    return AxiosRequest({url:'/api/v1/users/',method:'GET'})
}

const PostUser=(data,template_user_id)=>{
  
    if(template_user_id){

        return AxiosRequest({url:`/api/v1/users/?template_user_id=${template_user_id}`,method:'POST',params:data})

    }
    else{

        return AxiosRequest({url:`/api/v1/users/`,method:'POST',params:data})

    }
}

const UpdateUser=(updateData,id,template_user_id)=>{

    if(template_user_id){
        return AxiosRequest({url:`/api/v1/users/${parseInt(id)}?template_user_id=${template_user_id}`,method:'PUT',params:updateData})     
    }
    else{
        return AxiosRequest({url:`/api/v1/users/${parseInt(id)}`,method:'PUT',params:updateData})     


    }
    
}

const DeleteUser=(id)=>{

    return AxiosRequest({url:`/api/v1/users/${parseInt(id)}`,method:'DELETE'})
}

export {GetUser,PostUser,UpdateUser,DeleteUser}