import React, { useState, useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetCurrentAppTableAction } from "../../../Redux/currentAppTable/actions";

import { BreadCrumbs } from "../../crud/crudTableComponents/breadCrumbs";

import * as TiIcons from 'react-icons/ti'


import {
  GetAllowedTableAction,
  SaveAllowedTableAction,
} from "../../../Redux/allowedTables/actions";

import { InfoAlert } from "../../notifications/notifications";

import { GetAllTable } from "../../../api/allowedTableApi";

import { CreateButton } from "../../crud/Crud.styled";
import TableList from "./tableList";

const AllowedTable = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()


  const response = useSelector((state) => state.allowedTables.allowedTables);

 
  const {selectedID} = useParams()



  const [allTables, setAllTables] = useState([]);
  const [allowedTables, setAllowedTables] = useState([]);
  const [prevAllowedTables, setPrevAllowedTables] = useState([]);
  const [intAlltoAllow, setIntAlltoAllow] = useState([]);
  const [intAllowtoAll, setIntAllowtoAll] = useState([]);

  const [migrated, setMigrated] = useState(false);


  useEffect(()=>{

    dispatch(SetCurrentAppTableAction({
      currentTable:'DB Credentials Table',
      currentApp:'Settings'
    }))

  },[])

  useEffect(() => {
    if (selectedID) {
      dispatch(GetAllowedTableAction(selectedID));

      GetAllTable(selectedID)
        .then((response) => setAllTables(response.data.tables))
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    const allowedTableList = response.map((table) => table.table_name || table);

    setAllowedTables(allowedTableList);
    setPrevAllowedTables(response);
  }, [response]);

  const onCheck = (tablename, dispatcher) => {
    switch (dispatcher) {
      case "all":
        //remove if exists
        if (intAlltoAllow.includes(tablename)) {
          const filteredTableList = intAlltoAllow.filter(
            (item) => item != tablename
          );

          setIntAlltoAllow(filteredTableList);
        }
        //add if doesn't exist
        else {
          setIntAlltoAllow([...intAlltoAllow, tablename]);
        }
        break;

      case "allowed":
        //remove if exists
        if (intAllowtoAll.includes(tablename)) {
          const filteredTableList = intAllowtoAll.filter(
            (item) => item != tablename
          );

          setIntAllowtoAll(filteredTableList);
        }
        //add if doesn't exist
        else {
          setIntAllowtoAll([...intAllowtoAll, tablename]);
        }
        break;
    }
  };

  //arrow button to send tablename from intermediate state to allowed table list

  const migrate = (dispatcher) => {
    switch (dispatcher) {
      case "alltoallow":
        setMigrated(true);
        const newAllowed = intAlltoAllow.filter(
          (table) => !allowedTables.includes(table)
        );
        setAllowedTables([...allowedTables, ...newAllowed]);
        const filteredAll = allTables.filter(
          (table) => !intAlltoAllow.includes(table)
        );
        setAllTables(filteredAll);
        setIntAlltoAllow([]);
        InfoAlert("Please Apply to Save Changes");

        break;

      case "allowtoall":
        setMigrated(true);
        const newAll = intAllowtoAll.filter(
          (table) => !allTables.includes(table)
        );
        setAllTables([...allTables, ...newAll]);
        const filteredAllowed = allowedTables.filter(
          (table) => !intAllowtoAll.includes(table)
        );
        setAllowedTables([...filteredAllowed]);
        setIntAllowtoAll([]);
        InfoAlert("Please Apply to Save Changes");

        break;
    }
  };

  const postAllowedTables = () => {
    
    if (migrated) {
      dispatch(
        SaveAllowedTableAction(selectedID, prevAllowedTables, allowedTables)
      );
      setMigrated(false);
    } else {
      InfoAlert("Please migrate table first ");
    }
  };


  const NavigateBack=()=>{
   navigate("/createdbcredentials")
  }

 

  return (
    <>
     <div className="dashboard-layout">
     <BreadCrumbs/>

      <CreateButton>
        <div>

        </div>
       
        <div className="btnDiv">
         
          <button
            className='Btn applyBtn mx-3' 
            type="button"
            onClick={postAllowedTables}
          >
            <TiIcons.TiTick className="ml-1" style={{fontSize: "1.4rem" }}/>
            Apply
          </button>

        
        </div>
        
      </CreateButton>
     

      <TableList
        allTables={allTables}
        allowedTables={allowedTables}
        intAlltoAllow={intAlltoAllow}
        intAllowtoAll={intAllowtoAll}
        onCheck={onCheck}
        migrate={migrate}
      />

      </div>
    
    </>
  );
};

export default AllowedTable;
