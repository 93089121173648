const combine=(response)=>{

  const [metadata, allColumn] = response;

  const columns = allColumn.data.map((item) => {
    return {
      column_name: item.column_name,
      is_primary_key: item.is_primary_key,
    };
  });

  const metaCols = metadata.data.columns;

  const [col_with_perm] = metaCols.length
    ? metaCols.map((meta) => {
        return columns.map((item) => {
          if (item.column_name == meta.column_name) {
            item.permissions = meta.permissions;
          }

          return item;
        });
      })
    : [columns.map((item) => {
        return {
          column_name: item.column_name,
          is_primary_key: item.is_primary_key,
          permissions: { read: false, create: false, update: false },
        };
      })];
  
  const col_with_perms = col_with_perm.map((item) => {
    if (item.permissions) {
      return {
        column_name: item.column_name,
        is_primary_key: item.is_primary_key,
        permissions: item.permissions,
      };
    } else {
      return {
        column_name: item.column_name,
        is_primary_key: item.is_primary_key,
        permissions: { read: false, create: false, update: false },
      };
    }
  });
  const allColPerm = {
    column_name: "*",
    is_primary_key: false,
    permissions: metadata.data.all_column_permissions,
  };

  const agGridData = [allColPerm, ...col_with_perms];

  const agGridData_flattened=agGridData.map(item=>{
    return {
      column_name:item.column_name,
      is_primary_key:item.is_primary_key,
      ...item.permissions
    }
  })
  return agGridData_flattened
}

export default combine



