import { InfoAlert } from "../../notifications/notifications";
import { ErrorAlert } from "../../notifications/notifications";
import { SuccessAlert } from "../../notifications/notifications";

const RoleAlert = (responses, operation = null) => {
  const InsertResult = (roleOrApp, statusCode, result, operation) => {
    switch (statusCode) {
      case 200:
        result[`${roleOrApp}`] = `${operation}`;
        break;
      case 500:
        ErrorAlert(`${statusCode} :Server Error`);
        break;
      case 502:
        ErrorAlert(`${statusCode} : Bad Gateway`);
        break;
      case 504:
        ErrorAlert(`${statusCode} :Gateway Timeout`);
        break;
      default:
        ErrorAlert(`${statusCode} :An error occured`);
        break;
    }
  };

  let result = {};
  responses?.map((response) => {
    InsertResult(
      response.data.name || response.data.application_name,
      response.status,
      result,
      operation
    );
  });

  let grouped = [];

  for (let key in result) {
    if (result[key] ===operation) {
      grouped.push(key);
    }
  }

  SuccessAlert(`${operation} : ${grouped}`);
};

export default RoleAlert;
