import { GET_APP_FOR_ROLE,GET_APP_FOR_ROLES,GET_TABLE_FOR_APPS,GET_TABLE_FOR_APP,CLEAR_APP_FOR_ROLE } from "./appForRoleActionTypes";
import { GetTableForApp,GetTableForAppMasterdata } from "../../../api/appPermissionAPi";
import { GetAppForRole,CreateAppForRole,DeleteAppForRole } from "../../../api/roleCRUApi";
import RoleAlert from "../../../components/crud/roleCrud/roleAlert";

import { errorHandler } from "../../../api/errorHandler";

//getting apps for multiple roles
const GetAppForRolesAction = (roles) => {

  
  return async function (dispatch) {
    const role_ids = roles ? roles.map((role) => role.id) : [];

   
    if (role_ids.length) {
      Promise.all(
        role_ids.map((id) => {
          return GetAppForRole(id);
        })
      )
        .then((resp) => {

          const res=resp.map(res=>res.data)

          

          const response=[]

          res.map(item=>item.map(app=>response.push(app)))

          let unique_ids=[]
          const unique_apps=response.filter(item=>{
            if(!unique_ids.includes(item.id)){
              unique_ids.push(item.id)
              return true
            }
            else{
              return false
            }
               
               
             }
          )

          const app_for_roles=unique_apps.map(item=>(
            {
             application_name:item.application_name,
             id:item.id,
             is_custom:item.is_custom,
             application_type:item.application_type
             }))


          dispatch({
            type: GET_APP_FOR_ROLES,
            payload: app_for_roles,
          });
        })

        .catch((error) =>errorHandler(error));
    }
  };
};

//getting apps for single role

const GetAppForRoleAction=(roleid)=>{
  
  return async function(dispatch){
    if(roleid){
      GetAppForRole(roleid).then(
        response=>{
          dispatch({
            type:GET_APP_FOR_ROLE,
            payload:response.data
  
          })
        }
      )
      .catch(error=>errorHandler(error))
    }
 
  }

}

const SetAppForRoleAction=(roleid, prevApps, currentApps)=>{

  return async function(dispatch){

  let prev_apps = prevApps.map((app) => app.value);
  let curr_apps = currentApps.map((app) => app.value);

  const create_ids = curr_apps.filter((x) => !prev_apps.includes(x));
  const delete_ids = prev_apps.filter((x) => !curr_apps.includes(x));

  //create operation

  if (create_ids.length) {
    Promise.all(
      create_ids.map((appid) => {
        return CreateAppForRole(roleid, {
          application_id: appid,
          role_id: roleid,
        });
      })
    )
      .then((response) => {
        RoleAlert(response, "assigned")
        dispatch(GetAppForRoleAction(roleid))
        
      })
      .catch((error) => errorHandler(error));
  }

  if (delete_ids.length) {
    Promise.all(
      delete_ids.map((appid) => {
        return DeleteAppForRole(roleid, appid);
      })
    )
      .then((response) => {
        RoleAlert(response, "deleted")
        dispatch(GetAppForRoleAction(roleid))
      })
      .catch((error) => RoleAlert(error));
  }

  }
}



const GetTableForAppsMasterdataAction=(apps)=>{


  return async function (dispatch) {

    const app_ids=apps?apps.map(item=>item.id):[]
   

    if (app_ids.length) {
      Promise.all(
        app_ids.map((id) => {
          return GetTableForAppMasterdata(id);
        })
      )
        .then((response) => {
          const tables = response.map((item) => item.data);
          
          const table_for_apps = tables.reduce(function (result, field, index) {
            result[app_ids[index]] = field;
            return result;
          }, {});

          
          
          dispatch({
            type: GET_TABLE_FOR_APPS,
            payload: table_for_apps,
          });
        })

        .catch((error) => console.log(error));
    }
  };
  

}

const GetTableForAppMasterdataAction=(appId)=>{
  return async function (dispatch){
    if(appId){
      GetTableForAppMasterdata(appId)
      .then(response=>{
        dispatch({
          type:GET_TABLE_FOR_APP,
          payload:response.data
        })
      })

      .catch(error=>console.log(error))
    }
    
  }

}

const ClearAppForRoleAction=()=>{

  return function(dispatch){
    dispatch({
        type:CLEAR_APP_FOR_ROLE
    })
}

} 
  


export { GetAppForRolesAction,GetTableForAppsMasterdataAction,
  GetAppForRoleAction,SetAppForRoleAction,GetTableForAppMasterdataAction ,ClearAppForRoleAction};
