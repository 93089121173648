import {AxiosRequest} from './rootApi'




const GetDbCredentials=()=>{
    
    return AxiosRequest({url:'/api/v1/db-credentials/',method:'GET'})
}

const PostDbCredentials=(data)=>{

    return AxiosRequest({url:'/api/v1/db-credentials/',method:'POST',params:data})
}

const UpdateDbCredentials=(updateData,id)=>{
  
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(id)}`,
    method:'PUT',
    params:updateData})
}

const DeleteDbCredentials=(id)=>{
    
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(id)}`,method:'DELETE'})
}

//patch api

const PatchDbCredetentials=(id,value)=>{
    return AxiosRequest({url:`/api/v1/db-credentials/${parseInt(id)}`,method:'PATCH',params:value})

}

export {GetDbCredentials,PostDbCredentials,UpdateDbCredentials,DeleteDbCredentials,PatchDbCredetentials}