import React,{useState} from 'react'

import Select,{components} from 'react-select'
import { down_arrow_black } from '../../../styling_files/newUiStyles/icons';


const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={down_arrow_black}  width='11px' height='11px'  />
      </components.DropdownIndicator>
    );
  };

export const FormDropDown = ({ value,select_name,select_options,onChangeHandler,isMulti,menuOpen,setMenuOpen}) => {


  const [currentSelected, setCurrentSelected] = useState();


  const handleMenuOpen=()=>{
    setMenuOpen(true)

  }

  
  //react-select styling
  const style = {
    control: (base,state) => ({
      ...base,
     
      height: "2.5rem",
      width: "100%",
      fontFamily:'inter',
      marginTop:'.4rem',
   
      minHeight: "none",
      paddingTop:'5px',
      paddingRight:'6px',
      paddingLeft:'-10px',
      display: "flex",
      alignItems: "center",

      background: "#FFFFFF !important",

      border:state.isFocused?'1px solid #5288ff':'1px solid #d0d5dd ',
      boxShadow:state.isFocused?'0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ccdcff':'none' ,
      borderRadius: "8px",
     
     
    }),
    valueContainer: (base) => ({
      height: "30px",
      width: "70%",
      fontFamily:'inter',
      margin: "0",
      display: "flex",
      alignItems: "center",
      padding: "0",
      marginLeft: "1rem",
      marginBottom: ".5rem",
      position: "relative",
    }),
    singleValue: (base) => ({
      height: "30px",
      width: "auto",
      display: "flex",
      justifyContent: "space-eavenly",
      alignItems: "center",
      padddingInline: "15px",
    }),
    multiValue:(base)=>{
      return {
        ...base,
        backgroundColor:'#f9fafb',
        borderRadius:'5px',
        opacity:'.9'
      }

    },

    indicatorsContainer: (base) => ({
      ...base,
      height: "30px",
      marginBottom: ".5rem",
    }),
    menu: (base) => ({
      ...base,
      background: "#FFFFFF",
      border: "1px solid #EAECF0",
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      borderRadius: "8px",
      fontFamily: "inter",
      height:'fit-content',
    
    }),
    
    indicatorSeparator: (state) => ({
      display: "none",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // backgroundColor: isSelected ? "#2D81A1" : isFocused? '#B9D9EB':null,
      };
    },
  };
  

  return (
    

    <Select
      closeMenuOnSelect={true}
     
      isMulti={isMulti}
     
      menuPlacement='bottom'
      menuPortalTarget={document.body} 
      name={select_name}
      value={value}
      onChange={onChangeHandler}
      options={select_options}
      components={{DropdownIndicator }}
      styles={style}
      menuShouldScrollIntoView={false}
      
    />
  );
};


export const MultiSelectDropDown = ({ value,select_name,select_options,onChangeHandler,isMulti,menuOpen,setMenuOpen}) => {


  const [currentSelected, setCurrentSelected] = useState();


  const handleMenuOpen=()=>{
    setMenuOpen(true)

  }

  
  //react-select styling
  const style = {
    control: (base,state) => ({
      ...base,
     
      height: "auto",
      width: "100%",
      fontFamily:'inter',
      marginTop:'.4rem',
   
      minHeight: "none",
      paddingTop:'5px',
      paddingRight:'6px',
      paddingLeft:'-10px',
      display: "flex",
      alignItems: "center",

      background: "#FFFFFF !important",

      border:state.isFocused?'1px solid #5288ff':'1px solid #d0d5dd ',
      boxShadow:state.isFocused?'0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ccdcff':'none' ,
      borderRadius: "8px",
     
     
     
    }),
    valueContainer: (base) => ({
      ...base,

      width: "70%",
      fontFamily:'inter',
      margin: "0",
      
      padding: "0",
      marginLeft: "1rem",
      marginBottom: ".5rem",
      position: "relative",
    }),
    singleValue: (base) => ({
      height: "30px",
      width: "auto",
      display: "flex",
      justifyContent: "space-eavenly",
      alignItems: "center",
      padddingInline: "15px",
    }),
    multiValue:(base)=>{
      return {
        ...base,
        backgroundColor:'#f9fafb',
        borderRadius:'5px',
        opacity:'.9'
      }

    },

    indicatorsContainer: (base) => ({
      ...base,
      height: "30px",
      marginBottom: ".5rem",
    }),
    menu: (base) => ({
      ...base,
      background: "#FFFFFF",
      border: "1px solid #EAECF0",
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      borderRadius: "8px",
      fontFamily: "inter",
      height:'fit-content',
    
    }),
    
    indicatorSeparator: (state) => ({
      display: "none",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // backgroundColor: isSelected ? "#2D81A1" : isFocused? '#B9D9EB':null,
      };
    },
  };
  

  return (
    

    <Select
      closeMenuOnSelect={false}
     
      isMulti={isMulti}
     
      menuPlacement='bottom'
      menuPortalTarget={document.body} 
      name={select_name}
      value={value}
      onChange={onChangeHandler}
      options={select_options}
      components={{DropdownIndicator }}
      styles={style}
      menuShouldScrollIntoView={false}
      
    />
  );
};
