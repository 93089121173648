import React,{useState} from 'react'
import Select,{components} from "react-select";


import {down_arrow_black} from '../../../styling_files/newUiStyles/icons' 

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={down_arrow_black}  width='12px' height='12px'  />
    </components.DropdownIndicator>
  );
};

export const CellEditorDropdown = ({node,column,values}) => {



 const [currentSelected,setCurrentSelected]=useState() 

 const field=column.colDef.field
 const current_field_value=node.data[field]

  //creating options for react select

  const select_options = values?.map((value) => {
    return { value: value, label: value };
  });





  //react-select styling
  const style = {
    control: (base) => ({
      ...base,
      height:'30px',
      width:'85%',
    
      minHeight:'none',
      position:'absolute',
      margin:'7px 0 5px 1.1rem',
      padding:'0',
      display:'flex',
      alignItems:'center',
      
      background: '#FFFFFF !important',
  

      border: '1px solid #5288FF !important',
      boxShadow:'0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCDCFF !important',

      borderRadius:'8px'

    
      
    }),
    valueContainer:(base)=>({
  
      height:'30px',
      width:'70%',
      margin:'0',
      display:'flex',
      alignItems:'center',
      padding:'0',
      marginLeft:'1rem',
      marginBottom:'.5rem',
      position:'relative',
  
    }),
    singleValue:(base)=>({

      height:'30px',
      width:'auto',
      display:'flex',
      justifyContent:'space-eavenly',
      alignItems:'center',
      padddingInline:'15px',
    }),
    indicatorsContainer:(base)=>({
      ...base,
      height:'30px',
      marginBottom:'.5rem',


    }),
    menu:(base)=>({
      ...base,
      background: '#FFFFFF',
      border:'1px solid #EAECF0',
      boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      borderRadius:'8px',
      fontFamily:'inter'

    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      
      return {
        ...styles,
        // backgroundColor: isSelected ? "#2D81A1" : isFocused? '#B9D9EB':null,
     
      };
    }
  };
  const handleSelection = (e) => {
    
    node.setDataValue(field,e.value)
    
    setCurrentSelected(e);
  };

 

 
  return (
   
      <Select
        closeMenuOnSelect={true}
        isMulti={false}
        name="celleditor"
        components={{DropdownIndicator }}
        defaultValue={{value:'',label:''}}
        onChange={handleSelection}
        options={select_options}
        styles={style}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
      />
    
  );
};


export const CellRendererForDW=(data)=>{
  

    return (
        <div className='cell-dropdown-renderer'>
           
            <span>{data.value}</span>
            <span><img  src={down_arrow_black} width='12px' height='12px'/></span>
        </div>
    )

}

export const CellRendererForBool=(data)=>{
  

  return (
      <div className='cell-dropdown-renderer'>
         
          <span>{data?.value?"Yes":"No"}</span>
          <span><img  src={down_arrow_black} width='12px' height='12px'/></span>
      </div>
  )

}

export const CellEditorMultiDropdown = ({node,column,values}) => {


  const DeopdowonDecider=(selected_id,data)=>{

  }

  const [currentSelected,setCurrentSelected]=useState() 
 
  const field=column.colDef.field
  const current_field_value=node.data[field]
 
   //creating options for react select
 
   const select_options = values?.map((value) => {
     return { value: value, label: value };
   });
   const defaultvalue=current_field_value?.map(value=>({value:value,label:value}))
 
   
 
 
 
 
   //react-select styling
   const style = {
     control: (base) => ({
       ...base,
       height:'30px',
       width:'85%',
     
       minHeight:'none',
       position:'absolute',
       margin:'7px 0 5px 1.1rem',
       padding:'0',
       display:'flex',
       alignItems:'center',
       
       background: '#FFFFFF !important',
   
 
       border: '1px solid #5288FF !important',
       boxShadow:'0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCDCFF !important',
 
       borderRadius:'8px'
 
     
       
     }),
     valueContainer:(base)=>({
   
       height:'30px',
       width:'70%',
       margin:'0',
       display:'flex',
       alignItems:'center',
       padding:'0',
       marginLeft:'1rem',
       marginBottom:'.5rem',
       position:'relative',
   
     }),
     multiValue:(base)=>({
 
       height:'30px',
       width:'auto',
       display:'flex',
       justifyContent:'space-eavenly',
       alignItems:'center',
       padddingInline:'15px',
     }),
     indicatorsContainer:(base)=>({
       ...base,
       height:'30px',
       marginBottom:'.5rem',
 
 
     }),
     menu:(base)=>({
       ...base,
       background: '#FFFFFF',
       border:'1px solid #EAECF0',
       boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
       borderRadius:'8px',
       fontFamily:'inter'
 
     }),
     indicatorSeparator: state => ({
       display: 'none',
     }),
 
     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
       
       return {
         ...styles,
         // backgroundColor: isSelected ? "#2D81A1" : isFocused? '#B9D9EB':null,
      
       };
     }
   };
   const handleSelection = (e) => {
     
     const selected_values=e.map(item=>item.value)
     node.setDataValue(field,selected_values)
     
     
     setCurrentSelected(e);
   };
 
  
 
  
   return (
    
       <Select
         closeMenuOnSelect={false}
         isMulti={true}
         name="celleditor"
         components={{DropdownIndicator }}
         value={defaultvalue}
         onChange={handleSelection}
         options={select_options}
         styles={style}
         menuPortalTarget={document.body}
         menuPosition={'fixed'}
       />
     
   );
 };
 