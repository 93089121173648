import React, { useState } from "react";
import { http } from "../../api/rootApi";
import { useNavigate, Link } from "react-router-dom";

import * as qs from "qs";

import Auth from "./auth";

import {
  ruhrlogo,
  showPass,
  hidePass,
  noti_info_error_white,
} from "../../styling_files/newUiStyles/icons";

function LoginForm() {
  const [showpassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { setToken, setUser } = Auth();
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });
  const [loginError, setLoginError] = useState(null);

  const error_notification_img = (
    <img src={noti_info_error_white} width="24px" height="24px" className="mx-1"/>
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    http
      .post(
        "api/v1/login/access-token",
        qs.stringify({
          username: userData.username,
          password: userData.password,
        })
      )
      .then((response) => {
        // setUser(sessionStorage.getItem('user'))

        const token = response.data["access_token"];

        setToken(userData.username, token);

        navigate("/");
      })
      .catch((error) => {
        setLoginError(error.response?.data.detail);
      });
  };
  const handlePassword = () => {
    setShowPassword(!showpassword);
  };

  return (
    <>
      <div className="overlay-login"></div>

      <div className="loginform-container">
        <div className="login-logo">
          <img src={ruhrlogo} alt="ruhr.agency"></img>
        </div>

        <div className="login-heading">
          <span className="login-title">Log in to your account</span>
          <span className="login-subtext">
            Welcome back! Please enter your details.
          </span>
        </div>

        <div className="loginForm">
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="inputEmail" className="login-fields">
                Email
              </label>
              <div>
                <input
                  type="email"
                  className="login-input"
                  id="username"
                  name="username"
                  value={userData.username}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
              </div>
            </div>
            <div>
              <label htmlFor="inputPassword" className="login-fields">
                Password
              </label>
              <div className="password-container">
                <input
                  type={showpassword ? "text" : "password"}
                  className="login-input password-input"
                  value={userData.password}
                  onChange={handleChange}
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                />
                <img
                  src={showpassword ? hidePass : showPass}
                  alt={showpassword ? "hide password" : "show password"}
                  width="16px"
                  height="16px"
                  onClick={handlePassword}
                />
              </div>
            </div>

            {
              loginError && 
              <p className="login-error">
                {loginError ? error_notification_img : ""} {loginError}
              </p>
            }

            <button type="submit" className="submit-login">
              Sign In
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
