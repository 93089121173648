import React from "react";
import UserProfileForm from "./useprofileForm";
import UserPassswordForm from "./userPasswordForm";

const UserProfileView = () => {
  const userdatakeys = ["first_name", "last_name", "e-mail"];
 
  return (
    <div className="dashboard-layout">
      <div className="userprofile-container">
        <div className="profile-header-container">
          <div className="row">
            <div className="col-sm-12 profile-heading">
              <span>User Profile</span>
              <span>Manage your user profile</span>
            </div>

            
          </div>
        </div>

        <div className="forms-container">
          <div className="form-container1">
                       <div></div>
                        <div className="form-title ">
                            <span>Profile</span>
                            <span>Update your profile detail and e-mail address</span>
                            
                        </div>
                        <UserProfileForm
                            dataKeys={userdatakeys}
                            type='Profile'
                        />
                        

                    </div>

          <div className="form-container2">
                          <div></div>
                        <div className=" form-title ">
                            <span>Password</span>
                            <span>Please enter your current password to change your password</span>
                            

                        </div>
                        
                         <UserPassswordForm/> 
                         

                    </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileView;
