import React from "react";

import Loader from "react-loader-advanced";

const LoaderView = ({ children, message, isLoading }) => {

  const spinner = (

    <div className="loader-view">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading</span>
      </div>
      <div>
        {message}
      </div> 
    </div>
  );
  return (
    <Loader show={isLoading} message={spinner}>
      {children}
    </Loader>
  );
};

export default LoaderView;
