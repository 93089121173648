
export const findColumnType=(typeStr)=>{
  console.log(typeStr)
    let jstype = "text";
    if (typeStr.includes("VARCHAR")) {
      jstype = "text";
    } else if (typeStr.includes("INTEGER") || typeStr.includes("TINYINT")) {
      jstype = "number";
    } else if (typeStr.includes("DATETIME") || typeStr.includes("TIMESTAMP")) {
      jstype = "datetime-local";
    } else if (typeStr.includes("DATE")) {
      jstype = "date";
    } else if (
      typeStr.includes("DECIMAL") ||
      typeStr.includes("decimal") ||
      typeStr.includes("FLOAT")
    ) {
      jstype = "number";
    } else {
      jstype = "text";
    }
    return jstype;
  }

