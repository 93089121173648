import { SuccessAlert } from "../../../components/notifications/notifications";
import { errorHandler } from "../../../api/errorHandler";
import { HideLoaderAction,GetLastDagStateAction } from "../../loader/LoaderAction";


import { SendToAirFlowAutoversand } from "../../../api/customTableApi/autoversandApi";

export const SendToAirFlowAutoversandAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlowAutoversand(table_name)
          .then((response) => {
            
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
           
          })
          .catch(error=>{
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            errorHandler(error)
          })
      };

  }