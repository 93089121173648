import React from "react";
import { noti_info_error } from "../../styling_files/newUiStyles/icons";
const NoCustomView = ({ appName, tableName }) => {
  return (
    <div className="dashboard-layout">
      <div className="no-custom-view">
        <span>
          <img src={noti_info_error} width="35px" height="35px"  className="mx-3"/>
          No Custom View
        </span>
      </div>
    </div>
  );
};

export default NoCustomView;
