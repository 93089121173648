//mapper function

const initialMapping=(data,allowed_table_id)=>{
  
  return data.map(item=>{
    let perm=[]
    
    if(item.read){
    perm=[...perm,'r']
    }
    if(item.delete){
    perm=[...perm,'d']
  }
    
   if(item.create){
    perm=[...perm,'c']
  }
    
   if(item.update){
    perm=[...perm,'u']
  }
    return {
      allowed_table_id:allowed_table_id,
      column_name:item.column_name,
      permission_code:perm
    }
     
  })
  }

const apiSchemaMapper=(initialData,currentData,allowed_table_id)=>{

 

  const initialSchema=initialMapping(initialData,allowed_table_id)
  const currentSchema=initialMapping(currentData,allowed_table_id)

  const addList=initialSchema.map(inititem=>{
    const [permData]=currentSchema.filter(currItem=>currItem.column_name==inititem.column_name)
    
  
    const addPerm=permData.permission_code.length?permData.permission_code.filter(x=>!inititem.permission_code.includes(x)):[]
    
  
    return {
      ...inititem,
      permission_code:addPerm
    }
    
  }).filter(item=>item.permission_code.length)

  const delList=initialSchema.map(inititem=>{
    const [permData]=currentSchema.filter(currItem=>currItem.column_name==inititem.column_name)
    
  
    const delPerm=inititem.permission_code.length ? inititem.permission_code.filter(x=>!permData.permission_code.includes(x)):[]
    
  
    return {
      ...inititem,
      permission_code:delPerm
    }
    
  }).filter(item=>item.permission_code.length)
  
  return {
    addList:addList,
    delList:delList
  }
  

}
export default apiSchemaMapper