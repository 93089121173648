import {FETCH_APP_TABLE,POST_APP_TABLE,DELETE_APP_TABLE,FETCH_TABLE_METADATA,FETCH_DB_ALLOWEDTABLE,DELETE_SINGLE_APP_TABLE} from './appPermissionActionTypes'


const initialState={
    appTables:[],
    erros:[]
}


const appTableReducer=(state=initialState,action)=>{
    switch(action.type){
        case FETCH_APP_TABLE:
            const all_tables=[...action.payload]
            const newFetchState={...state,appTables:all_tables,errors:[]}
            return newFetchState
        
        case POST_APP_TABLE:
            const new_tables=[...state.appTables,...action.payload]
            
            const newUpdateState={...state,appTables:new_tables,errors:[]}
          
            return newUpdateState

        case DELETE_APP_TABLE:
            
            const filtered_tables=state.appTables.filter(table=>!action.payload.includes(table.id))
           
            const filteredState={...state,appTables:filtered_tables,errors:[]}

            return filteredState

        case DELETE_SINGLE_APP_TABLE:
            const single_filtered_table=state.appTables.filter(table=>table.id!=action.payload)
            const single_filtered_state={...state,appTables:single_filtered_table,errors:[]}
            return single_filtered_state

        default:
            return state
    }
}

export default appTableReducer