import {AxiosRequest} from './rootApi'
import RoleAlert from "../components/crud/roleCrud/roleAlert";



const GetRole = () => {
  return AxiosRequest({url:"/api/v1/roles/", method:"GET"});
};

const PostRole = (data) => {
  
  return AxiosRequest({url:"/api/v1/roles/", method:"POST",params:data});
};

const UpdateRole = (updateData, id) => {
  return AxiosRequest({url:`/api/v1/roles/${parseInt(id)}`,method: "PUT", params:updateData});
};

const DeleteRole = (id) => {
  return AxiosRequest({url:`/api/v1/roles/${parseInt(id)}`, method:"DELETE"});
};

//role apis for a single user 
const GetRoleForUser = (userid) => {
  return AxiosRequest({url:`/api/v1/users/${parseInt(userid)}/roles`, method:"GET"});
};

const CreateRoleForUser = (userid, data) => {
  return AxiosRequest({url:`/api/v1/users/${userid}/roles`, method:"POST",params: data});
};

const DeleteRoleForUser = (userid, roleid) => {
  return AxiosRequest({url:`/api/v1/users/${userid}/roles/${roleid}`,method: "DELETE"});
};


//app apis for a role :no redux
const GetAppForRole = (roleid) => {
  return AxiosRequest({url:`/api/v1/roles/${parseInt(roleid)}/applications`,method: "GET"});
};
const CreateAppForRole = (roleid, data) => {
  return AxiosRequest(
    {url:`/api/v1/roles/${parseInt(roleid)}/applications`,
    method:"POST",
    params:data}
  );
};
const DeleteAppForRole = (roleid, appid) => {
  return AxiosRequest(
    {url:`/api/v1/roles/${parseInt(roleid)}/applications/${parseInt(appid)}`,
    method:"DELETE"}
  );
};

const SetAppForRole = (roleid, prevApps, currentApps) => {
  let prev_apps = prevApps.map((app) => app.value);
  let curr_apps = currentApps.map((app) => app.value);

  const create_ids = curr_apps.filter((x) => !prev_apps.includes(x));
  const delete_ids = prev_apps.filter((x) => !curr_apps.includes(x));

  if (create_ids.length) {
    Promise.all(
      create_ids.map((appid) => {
        return CreateAppForRole(roleid, {
          application_id: appid,
          role_id: roleid,
        });
      })
    )
      .then((response) => {
        RoleAlert(response, "assigned");
      })
      .catch((error) => RoleAlert(error));
  }
  if (delete_ids.length) {
    Promise.all(
      delete_ids.map((appid) => {
        return DeleteAppForRole(roleid, appid);
      })
    )
      .then((response) => RoleAlert(response, "deleted"))
      .catch((error) => RoleAlert(error));
  }
};

export {
  GetRole,
  PostRole,
  UpdateRole,
  DeleteRole,
  GetRoleForUser,
  DeleteRoleForUser,
  CreateRoleForUser,
  CreateAppForRole,
  DeleteAppForRole,

  SetAppForRole,
  GetAppForRole,
};
