import styled from 'styled-components'

export const AppGridTable=styled.div`
  position:fixed;
  margin-left:50px;
  padding:20px;
  width:97%;
  height:95%;
  overflow-x: scroll;
  overflow-y: scroll;
`
export const DbGridTable=styled.div`
  position: absolute;
  margin-left:90px;
  padding:5px;
  width:92%;
  height:88%;
  
`
