

export const EditPermission=(tableMetaData)=>{
  
    const {all_column_permissions,columns}=tableMetaData
    
    if(all_column_permissions.update){
      return true
    }
    
    else {
     const column_update=columns.map(column=>column.permissions.update)
     return column_update.includes(true)
      
    }
    
  }

  
export const CreatePermission=(tableMetaData)=>{
  
    const {all_column_permissions,columns}=tableMetaData
    
    if(all_column_permissions.create){
      return true
    }
    
    else {
     const column_update=columns.map(column=>column.permissions.create)
     return column_update.includes(true)
      
    }
    
  }


