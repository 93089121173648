import React from "react";
import { useSelector,useDispatch } from 'react-redux'
import { useEffect} from 'react';

// importing actions
import { GetCurrentUserAction } from "../../../Redux/userProfile/userProfileActions";
import { SetSelectedAppType } from "../../../Redux/sidebar/sidebarControl/sidebarControlActions";



const Avatar = (

    {
        selectedType,
        type,
        handleSidebar,

    }
) => {


    const dispatch=useDispatch()
    const currentUser = useSelector(state => state.CurrentUser?.CurrentUser?.full_name)


    useEffect(()=>{
        dispatch(GetCurrentUserAction())

    },[])

    const handleClick = () => {

        if (selectedType === type) {
            handleSidebar()

        }
        else {
            handleSidebar(true)
        }
        dispatch(SetSelectedAppType(type))


    }

    function firstWordInitials(fullName) {
   
        const nameArray = fullName.split(" ");
        const firstInitial = nameArray[0]?.charAt(0).toUpperCase();
        const lastInitial = nameArray[1]?.charAt(0).toUpperCase();

        return firstInitial + lastInitial;
    }




    return (
        <>
            <button 
                className="avatar-icon"
                onClick={handleClick}
            >
                 { 
                currentUser?
                firstWordInitials(currentUser)
                :
                'U'
                } 
           

            </button>
        </>
    )
}

export default Avatar