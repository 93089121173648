import {ROLE_FETCH,ROLE_CREATE,ROLE_UPDATE,ROLE_DELETE} from './roleActionTypes'

const initialState={
    roles:[],
    
}

const roleReducer=(state=initialState,action)=>{

    switch(action.type){
        case ROLE_FETCH:
            
            const newfetchState={roles:[...action.payload]}
          
            return newfetchState

        case ROLE_CREATE:
            const created_roles=[...state.roles,action.payload]
            const newCreateState={...state.roles,roles:created_roles}
            return newCreateState
            
        case ROLE_UPDATE:
           const updated_roles=state.roles.map((role)=>role.id==action.payload.id?{...role,...action.payload.data}:role)
           const updatedState={...state.roles,roles:updated_roles}
          
           return updatedState

        case ROLE_DELETE:
            const filtered_role=state.roles.filter((roles)=>roles.id!=action.payload)
            const filteredState={...state.roles,roles:filtered_role}
            
            return filteredState
        default:
            return state
    }

}

export default roleReducer