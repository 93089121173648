//internal imports

import {
  noti_info_error,
  noti_success_icon,
  infoIcon
} from "../../../styling_files/newUiStyles/icons";

const LastDagState = ({ dag_state }) => {


  let dag_status_class;
  let dag_status_icon;

  switch (dag_state) {
    case "active":
      dag_status_class = "status-active";
      dag_status_icon = noti_success_icon;

      break;
    case "failed":
      dag_status_class = "status-failed";
      dag_status_icon = noti_info_error;

      break;

    default:
      dag_status_class = "status-invalid";
      dag_status_icon = infoIcon;

      break;
  }

  return (
    <div className={`dag-status-container ${dag_status_class}`}>
      <span>Airflow Status</span>
      <span className="dag-status">
        <img
          src={dag_status_icon}
          width="15px"
          height="15px"
          className="mx-1"
        ></img>
        {dag_state || 'Invalid' }
      </span>
    </div>
  );
};

export default LastDagState;
