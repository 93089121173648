import {GET_APP_FOR_ROLE,GET_APP_FOR_ROLES,GET_TABLE_FOR_APPS,
    GET_TABLE_FOR_APP,CLEAR_APP_FOR_ROLE} from './appForRoleActionTypes'

const initialState={
    appsForRole:[],
    appsForRoles:[],
    tableForApps:{},
    tableForApp:[],
   
}

const AppForRoleReducer=(state=initialState,action)=>{

    switch(action.type){
        case GET_APP_FOR_ROLE:
            const newAppForRole={...state,appsForRole:action.payload}

            
            return newAppForRole
        case GET_APP_FOR_ROLES:
            const newFetchState={...state,appsForRoles:action.payload}
            
            
            return newFetchState
        case GET_TABLE_FOR_APPS:

            const newFetchTableState={...state,tableForApps:action.payload}
           
            
            return newFetchTableState

        case GET_TABLE_FOR_APP:
            const newtableForApp={...state,tableForApp:action.payload}
            return newtableForApp

        case CLEAR_APP_FOR_ROLE:
            return {...initialState}

        default:
            return state
    }

}

export {AppForRoleReducer}