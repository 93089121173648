import {GET_TABLE_METADATA_ACTION, 
    GET_TABLE_ALL_COLUMNS_METADATA_ACTION,
    GET_TABLE_ROWS_DATA_ACTION, 
    POST_TABLE_ROW_DATA_ACTION, 
    GET_TABLE_CREATED_SINGLE_ROW_DATA_ACTION,
    UPDATE_TABLE_ROW_DATA_ACTION, 
    GET_TABLE_UPDATED_ROW_DATA_ACTION, 
    DELETE_TABLE_ROW_DATA_ACTION,
    CLEAR_UPDATED_PK_ID,
    CLEAR_CREATED_PK_ID,
    CLEAR_MASTERDATA_STATE
    
   } from './masterdataActionTypes'

const initialState={
  tableMetaData:null,
  tableAllColumnsMetaData:null,
  tableRowsData:[],
  createdRowPkID:{},
  updatedRowPkID:{},
  errors:[] 
}

const masterdataReducer=(state=initialState,action)=>{

  switch(action.type){
      case GET_TABLE_METADATA_ACTION:
          const table_metadata=action.payload
          const tableMetaState={...state,tableMetaData:table_metadata,errors:[]}
          
          return tableMetaState

      case GET_TABLE_ALL_COLUMNS_METADATA_ACTION:
          const table_all_columns_metadata=action.payload
          const tableAllColumnsMetaState={...state,tableAllColumnsMetaData:table_all_columns_metadata,errors:[]}
          return tableAllColumnsMetaState

      case GET_TABLE_ROWS_DATA_ACTION:
          const table_rows_data=action.payload
          const tableRowsDataState={...state,tableRowsData:table_rows_data,errors:[]}
          return tableRowsDataState

      case POST_TABLE_ROW_DATA_ACTION:
          const created_row = action.payload
          const createdRowDataState = {...state, createdRowPkID:created_row, errors:[]}
          return createdRowDataState
   
      case GET_TABLE_CREATED_SINGLE_ROW_DATA_ACTION:
           const single_row_data=action.payload
           const singleRowState={...state, tableRowsData: [...state.tableRowsData, single_row_data], errors:[]}
          
           return singleRowState

      case UPDATE_TABLE_ROW_DATA_ACTION:
               const updated_row = action.payload
               const updatedRowIDState = {...state, updatedRowPkID: updated_row, errors:[]}
               return updatedRowIDState
   
      case GET_TABLE_UPDATED_ROW_DATA_ACTION:
               const updated_row_data=action.payload.row_data
               const pkey = action.payload.pkey
               const updated_rows=state.tableRowsData.map((r)=>r[pkey]==updated_row_data[pkey]?updated_row_data:r)
               const updatedRowState={...state, tableRowsData: [...updated_rows], errors:[]}
              
               return updatedRowState

      case DELETE_TABLE_ROW_DATA_ACTION:
               let pkey_value = action.payload.pkey_value
               let pkey_name = action.payload.pkey_name
               const rows_after_delete=state.tableRowsData.filter((r)=>r[pkey_name]!=pkey_value)
               const afterDeleteState = {...state, tableRowsData: [...rows_after_delete], errors:[]}
               return afterDeleteState
       
   


       case CLEAR_UPDATED_PK_ID:
           return {
               ...state,updatedRowPkID:''
           }
       
       case CLEAR_CREATED_PK_ID:
           return {
               ...state,createdRowPkID:''
           }
        case CLEAR_MASTERDATA_STATE:
            return {
                ...initialState
            }
       
       default:
           return state
   }

}

export default masterdataReducer