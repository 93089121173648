import {GET_USERS_XENTRAL,GET_PRINTERS_XENTRAL,USER_LOADER,PRINTER_LOADER,LOAD_FAILED} from './xentralActionTypes'

const initialState={
    users:[],
    printers:[],
    isLoading:{userLoading:true,printerLoading:true},
    loadFailed:{userFailed:false,printerFailed:false}
   
}

const XentralReducer=(state=initialState,action)=>{

    switch(action.type){
        case GET_USERS_XENTRAL:
            
            return {
                ...state,users:action.payload,isLoading:{...state.isLoading,userLoading:false},loadFailed:{...state.loadFailed,userFailed:false}
            }
        case GET_PRINTERS_XENTRAL:
            return {
                ...state,printers:action.payload,isLoading:{...state.isLoading,printerLoading:false},loadFailed:{...state.loadFailed,printerFailed:false}
            }
        case USER_LOADER:
            return {
                ...state,isLoading:{...state.isLoading,userLoading:action.payload},
            }
        case PRINTER_LOADER:
            
            const newState={
                ...state,isLoading:{...state.isLoading,printerLoading:action.payload},
            }
            
            return newState
        case LOAD_FAILED:
            return {
                ...state,loadFailed:{userFailed:true,printerFailed:true}
            }
        
        default :
            return state

    }

}

export default XentralReducer