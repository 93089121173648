import {DB_FETCH,DB_CREATE,DB_UPDATE,DB_DELETE} from './dbActionTypes'

const initialState={
    dbcredentials:[],
    errors:[]
}

const DbReducer=(state=initialState,action)=>{

    switch(action.type){
        case DB_FETCH:
           
            const all_dbcredentials=[...action.payload]
            const newfetchState={...state,dbcredentials:all_dbcredentials,errors:[]}
           
            return newfetchState
            break
                
            
        case DB_CREATE:
            
            const created_dbcredentials=[...state.dbcredentials,action.payload]
            const newCreateState={...state,dbcredentials:created_dbcredentials,errors:[]}
            
            return newCreateState
                
          
              
            
        case DB_UPDATE:
            
            const updated_dbcredentials=state.dbcredentials.map((dbcredential)=>dbcredential.id==action.payload.id?{...dbcredential,...action.payload.data}:dbcredential)
            const updatedState={...state,dbcredentials:updated_dbcredentials,errors:[]}
            return updatedState
            

        case DB_DELETE:
            
            const filtered_dbcredentials=state.dbcredentials.filter((dbcredential)=>dbcredential.id!=action.payload)
            const filteredState={...state,dbcredentials:filtered_dbcredentials,errors:[]}
           
            return filteredState
        
        default:
            return state
    }

}
export default DbReducer