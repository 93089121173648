import {AxiosRequest} from './rootApi'


const GetCurrentUser=()=>{
 
    return AxiosRequest({url:'/api/v1/users/me',method:'GET'})
}

const UpdateCurrentUser=(updateData)=>{
    return AxiosRequest({url:'/api/v1/users/me',method:'PUT',params:updateData})
}

export {GetCurrentUser,UpdateCurrentUser}