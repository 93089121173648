import {USER_FETCH,USER_CREATE,USER_UPDATE,USER_DELETE} from './userActionTypes'

const initialState={
    users:[],
    errors:[]
    
}

const userReducer=(state=initialState,action)=>{

    switch(action.type){
        case USER_FETCH:
           
            const all_users=[...action.payload]
            const newfetchState={...state,users:all_users,errors:[]}
           
            return newfetchState
                
            
        case USER_CREATE:
            
            const created_users=[...state.users,action.payload]
            const newCreateState={...state,users:created_users,errors:[]}
            
            return newCreateState
                
          
              
            
        case USER_UPDATE:
            
            
            const updated_users=state.users.map((user)=>user.id==action.payload.id?{...user,...action.payload.data}:user)
            const updatedState={...state,users:updated_users,errors:[]}
            return updatedState
            

        case USER_DELETE:
            
            const filtered_users=state.users.filter((users)=>users.id!=action.payload)
            const filteredState={...state,users:filtered_users,errors:[]}
           
            return filteredState

    
        
        default:
            
            return  state
    }

}

export default userReducer