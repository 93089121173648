import React,{useEffect, useState} from "react";
import {useSelector} from 'react-redux'
import { useDispatch } from "react-redux";
import { GetCurrentUserAction } from "../../../Redux/userProfile/userProfileActions";

import { useFormik } from "formik";
import * as Yup from "yup";
import { noti_info_error } from "../../../styling_files/newUiStyles/icons";

import ResetPassword from "../../../api/passwordResetApi";



const labelFormatter=(label)=>{
    const Label=label.split('_').join(' ')
    return Label.charAt(0).toUpperCase() + Label.slice(1);
  

}
const UserPassswordForm = () => {

    const dispatch=useDispatch()

    const currentUser=useSelector(state=>state.CurrentUser.CurrentUser)
    const {email:user_email}=currentUser

    
  const data = {
    current_password:'',
    new_password:'',
    confirm_new_password:''
  };

  

  const dataKeys = data ? Object.keys(data) : [];


    

    useEffect(()=>{
        dispatch(GetCurrentUserAction())

    },[dispatch])


    



    const onSubmit = (values) => {
   
        console.log('values to be submitted',values)
        formik.resetForm();
        ResetPassword({userEmail:user_email,currentPass:values.current_password,newPass:values.new_password})
        
      };
    

    const validationSchema = Yup.object({
        current_password:Yup.string().required(' required'),
        new_password: Yup.string().required(' required')
          .min(8, " Password is too short"),
        confirm_new_password:Yup.string().required(' required')
          .oneOf([Yup.ref('new_password'), null], ' Passwords must match')

      });
    


    const formik = useFormik({
        initialValues: data,
        onSubmit,
        validationSchema,
      });
    


  
   
   

    
    return (
        
            <form 
                className="user-profile-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit(e);
                  }}
                >
                <div >
                {dataKeys.map((data) => {
                    return (
                      <>
                        <div className={`form-field ${data}-formfield`}>

                          <label htmlFor={data} class="form-label">
                            {labelFormatter(data)}{" "}
                          </label>

                          <input
                            type="text"
                            id={data}
                            name={data}
                            value={formik.values[data]}
                            className="input-field"
                            placeholder={`${labelFormatter(data)}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          
                          <div>
                            {formik.touched[data] && formik.errors[data] ? (
                              <div className="error ">
                                <img
                                  src={noti_info_error}
                                  width="14px"
                                  height="14px"
                                  
                                />
                                {formik.errors[data]}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    );
                    })}
                </div>

                <div>
                    <button type="submit" className="submit-btn">   
                       Update Password
                    </button>
                </div>
            </form>
       
    )
}

export default UserPassswordForm