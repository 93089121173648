import {DB_FETCH,DB_CREATE,DB_UPDATE,DB_DELETE} from './dbActionTypes'
import {GetDbCredentials,PostDbCredentials,UpdateDbCredentials,DeleteDbCredentials} from '../../api/dbCredentialsAPi'
import { errorHandler } from '../../api/errorHandler'
import { SuccessAlert } from '../../components/notifications/notifications'
import { undoCellEdit } from '../../components/crud/tableState'

const GetDbAction=()=>{
     return async function (dispatch){
        return GetDbCredentials().then((response)=>{
          dispatch({
                
                    type:DB_FETCH,
                    payload:response.data,
                
            })
        })
        .catch(error=>errorHandler(error))
    }
    
}

const PostDbAction=(data)=>{

    return async function (dispatch){
        return PostDbCredentials(data).then((response)=>{
            
            dispatch({
                
                    type:DB_CREATE,
                    payload:response.data,
                
            })
            SuccessAlert('Db credentials created')
        })
        .catch(error=>errorHandler(error))
    }
    
}


const UpdateDbAction=(updateData,id,gridRef)=>{

    return async function (dispatch){
       
        return UpdateDbCredentials(updateData,id).then((response)=>{
           
            dispatch({
                
                    type:DB_UPDATE,
                    payload:{id:id,data:updateData}
                   
            })
            SuccessAlert('Db credentials updated')
        })
        .catch(error=>{

            undoCellEdit(gridRef)
            errorHandler(error)

        })
    }
    
}

const DeleteDbAction=(id)=>{
  
   
    return  function (dispatch){
       
       
        return DeleteDbCredentials(id).then((response)=>{
            
            
            dispatch({
                
                    type:DB_DELETE,
                    payload:id
                   
            })
            SuccessAlert('Db credentials deleted')
        })
        .catch(error=>errorHandler(error))
       
    }
    
}

export {GetDbAction,PostDbAction,UpdateDbAction,DeleteDbAction}