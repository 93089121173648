import {AxiosRequest} from './rootApi'
import { errorHandler } from './errorHandler'
import { SuccessAlert } from '../components/notifications/notifications'

const ResetPassword=({userEmail,currentPass,newPass})=>{

    //getting token

    const params=new URLSearchParams(
        {username:userEmail,password:currentPass}
        )

    AxiosRequest(
        {url:'/api/v1/login/access-token',
        method:'POST',
        params:params.toString()
    }
    )
    .then(response=>{
        
        console.log(response)
        const {access_token}=response.data

        //reseting password
        console.log('access token',access_token)

        AxiosRequest(
            {url:'/api/v1/reset-password',
            method:'POST',
            params:{token:access_token,new_password:newPass}
        }
        )
    })
    .then(response=>{
        console.log(response)
        console.log('password update successful')
        SuccessAlert("Password Update Successful")
    })
       
    .catch(error=>errorHandler(error))



}

export default ResetPassword