import {APP_FETCH,APP_CREATE,APP_DELETE,APP_UPDATE} from './appActionTypes'

const initialState={
    apps:[],
    errors:[]
    
}

const appReducer=(state=initialState,action)=>{

    switch(action.type){
        case APP_FETCH:
           
            const all_apps=[...action.payload]
            const newfetchState={...state,apps:all_apps,errors:[]}
           
            return newfetchState
                
            
        case APP_CREATE:
            
            const created_apps=[...state.apps,action.payload]
            const newCreateState={...state,apps:created_apps,errors:[]}
            
            return newCreateState
                
          
              
            
        case APP_UPDATE:
            
            const updated_apps=state.apps.map((app)=>app.id==action.payload.id?{...app,...action.payload.data}:app)
            const updatedState={...state,apps:updated_apps,errors:[]}
            return updatedState
            

        case APP_DELETE:
            
            const filtered_apps=state.apps.filter((app)=>app.id!=action.payload)
            const filteredState={...state,apps:filtered_apps,errors:[]}
           
            return filteredState
        
        default:
            return state
    }

}

export default appReducer