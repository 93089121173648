import {
  OPEN_APP,
  OPEN_DB,
  OPEN_ROLE,
  OPEN_USER,
  OPEN_ATTACH,
  CLOSE_APP,
  CLOSE_ROLE,
  CLOSE_USER,
  CLOSE_DB,
  CLOSE_ATTACH,
  ADD_ROUTE,
  REMOVE_ROUTE,
  ADD_TEMP_ATTACH_DATA,
  REMOVE_TEMP_ATTACH_DATA
  

} from "./actionTypes";



const initialState = {
  appModalOpen: false,
  userModalOpen: false,
  roleModalOpen: false,
  dbModalOpen: false,
  attachModalOpen:false,
  modal_routes:[],
  attachData:[]
  
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {


    case OPEN_APP:
      return { ...state, appModalOpen: true };

    case OPEN_DB:
      return { ...state, dbModalOpen: true };

    case OPEN_ROLE:
      return { ...state, roleModalOpen: true };

    case OPEN_USER:
      return { ...state, userModalOpen: true };

    case OPEN_ATTACH:
        return { ...state, attachModalOpen: true };




    case CLOSE_APP:
      return { ...state, appModalOpen: false };

    case CLOSE_DB:
      return { ...state, dbModalOpen: false };
    case CLOSE_ROLE:
      return { ...state, roleModalOpen: false };

    case CLOSE_USER:
      return { ...state, userModalOpen: false };

    case CLOSE_ATTACH:
        return { ...state, attachModalOpen: false };
    
    case ADD_ROUTE:

      if(state.modal_routes.indexOf(action.payload)!=-1){
        return {...state}
      }
      else{

      return {...state,modal_routes:[...state.modal_routes,action.payload]}
      }
    
    case REMOVE_ROUTE:
      
       //remove given route and subsequent all routes

       const new_arr=state.modal_routes.slice(0,state.modal_routes.indexOf(action.payload))
       
        return {...state,modal_routes:new_arr}

    case ADD_TEMP_ATTACH_DATA:
      return {...state,attachData:action.payload}
      
    case REMOVE_TEMP_ATTACH_DATA:
      return {...state,attachData:[]}



    default: return state;
  }
};


export default modalReducer