import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { calendarIcon } from "../../../styling_files/newUiStyles/icons";



const CustomInput=React.forwardRef(({value,onClick},ref)=>{



  return (
    <button
    className="datePicker"
    onClick={onClick}
    ref={ref}
    >
      {value}
      <img
      src={calendarIcon}
      />
    
    </button>
  

  )
  
})

export const  DatePickerEditor=({ node, column })=> {
  const field = column.colDef.field;
  const current_field_value = node.data[field];

  const handleDateChange = (e) => {
    node.setDataValue(field, moment(e).format("YYYY-MM-DD"));
   

    setStartDate(e);
  };

  const [startDate, setStartDate] = useState(new Date(current_field_value));

  return (
    <DatePicker
      wrapperClassName="datePicker-wrapper"
     
      portalId="root"
      selected={startDate}
      onChange={handleDateChange}
      todayButton="Today"
      
      dateFormat="yyyy-MM-dd"
     
      customInput={<CustomInput/>}
    />
  );
}

export const DateTimePickerEditor=({ node, column })=> {
  const field = column.colDef.field;
  const current_field_value = node.data[field];

  

  const handleDateChange = (e) => {
    console.log(e)
    node.setDataValue(field, moment(e).format("YYYY-MM-DDTh:mm:ss"));
    
   

    setStartDate(e);
  };

  const [startDate, setStartDate] = useState(new Date(current_field_value));
  
  return (
    <DatePicker
      wrapperClassName="datePicker-wrapper"
      showIcon
      portalId="root"
      selected={startDate}
      onChange={handleDateChange}
      todayButton="Today"
      showTimeSelect={true}
     
      dateFormat="yyyy-MM-dd h:mm:ss"
      
      customInput={<CustomInput handleDateChange={handleDateChange}/>}
    />
  );
}

export const DateTimeCellRenderer=({value})=>{
  return (
    
      moment(value).format("YYYY-MM-DD, h:mm:ss")
     

  )

}

