import {ROLE_FETCH,ROLE_CREATE,ROLE_UPDATE,ROLE_DELETE} from './roleActionTypes'
import {GetRole,PostRole,UpdateRole,DeleteRole} from '../../api/roleCRUApi'

import { SetAppForRoleAction } from '../sidebar/appForRole/appForRoleAction'

import { undoCellEdit } from '../../components/crud/tableState'
import { errorHandler } from '../../api/errorHandler'
import { SuccessAlert } from '../../components/notifications/notifications'

const GetRoleAction=()=>{
   
    return async function (dispatch){
        return GetRole().then((response)=>{
            dispatch({
                
                    type:ROLE_FETCH,
                    payload:response.data,
                
            })
         
        })
    }
    
}

const PostRoleAction=({data,apps})=>{


    return async function (dispatch){
       
        return PostRole(data)
        .then((response)=>{
            
            dispatch({
                
                    type:ROLE_CREATE,
                    payload:response.data,
                
            })
            dispatch(SetAppForRoleAction(response.data.id,[],apps))
            SuccessAlert("Role created")
        })
        .catch(error=>errorHandler(error))
    }
    
}

const UpdateRoleAction=(updateData,id,gridRef)=>{
  

    return async function (dispatch){
        
        return UpdateRole(updateData,id)
        .then((response)=>{
           
            dispatch({
                
                    type:ROLE_UPDATE,
                    payload:{id:id,data:response.data}
                   
            })
            SuccessAlert("Role updated")
        })
        .catch(error=>{
            undoCellEdit(gridRef)
            errorHandler(error)
        })
    }
    
}

const DeleteRoleAction=(id)=>{
  
    
    return async function (dispatch){
        
        return DeleteRole(id)
        .then((response)=>{
            
            dispatch({
                
                    type:ROLE_DELETE,
                    payload:id
                   
            })
            SuccessAlert('Role deleted')
        })
        .catch(error=>errorHandler(error))
    }
    
}

export {GetRoleAction,PostRoleAction,UpdateRoleAction,DeleteRoleAction}