import React, { useState, useEffect,useRef } from "react";
import ReactDOM from "react-dom";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PostUserAction } from "../../../Redux/users/userActions";

import handleScroll from "../../../utils/dropdownHandleScroll";
import { useFormik } from "formik";
import * as Yup from "yup";

import { openRole,addRoute,removeRoute } from "../../../Redux/modals/modalActions";
import { FormDropDown,MultiSelectDropDown} from "../crudTableComponents/FormSelect";
import { crossIcon,noti_info_error,plusIconBlack,right_arrow,left_arrow } from "../../../styling_files/newUiStyles/icons";






const labelFormatter=(label)=>{
  const Label=label.split('_').join(' ')
  return Label.charAt(0).toUpperCase() + Label.slice(1)
}


const UserCreateDialog = ({ userModalOpen,closeUserModal}) => {
  
  const dispatch = useDispatch();
  const navigate=useNavigate()




  const modal_routes=useSelector(state=>state.modals.modal_routes)

    //previous-current-next routes

    const current_modal=useLocation().pathname
    const previous_modal=modal_routes[modal_routes.indexOf(current_modal)-1]
    const next_modal=modal_routes[modal_routes.indexOf(current_modal)+1]

 
//template user
  
  const users = useSelector((state) => state.users.users);
  const roles = useSelector((state) => state.roles.roles);

  const [currentUserRole, setCurrentUserRole] = useState();
  const [currentTemplateUser,setCurrentTemplateUser]=useState();
 


  
  //creating options for react select

  const role_options = roles?.map((role) => {
    return { value: role.id, label: role.name };
  });


  const template_user_options=users?.map((user=>{
    return {value:user.id,label:user.email}
  }))




  const data = {
    email: "",
    full_name: "",
    password: "",
    template_user:"",
    is_active: "false",
    is_superuser: "false",
    role:'',
    create_role:'',
   
    
  };
  const initial_formik_values=JSON.parse(localStorage.getItem('user-create')) ?JSON.parse(localStorage.getItem('user-create')):data

  const handleTemplateUser=(e)=>{
    setCurrentTemplateUser(e)

  }

  const handleDropDown=(e,field)=>{
    
    formik.setFieldValue(`${field}`,e.value)
  }

  //handling role change
  const handleRole = (e) => {
   
    const dropdownValues = e.map((item) => item);

    setCurrentUserRole(dropdownValues);
  }


  const onSubmit = (values) => {

    dispatch(PostUserAction({data:values,
        role:currentUserRole,
        template_user_id:currentTemplateUser?.value}));

    closeUserModal()
    formik.setValues(data);
    localStorage.removeItem('user-create')
    dispatch(removeRoute(current_modal))
    setCurrentUserRole(null)
    setCurrentTemplateUser(null)
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("enter valid email format").required("required"),
    full_name: Yup.string().required("required"),
    password: Yup.string()
      .required("required.")
      .min(8, "Password is too short - should be 8 characters minimum."),
  });

  const formik = useFormik({
    initialValues:initial_formik_values,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {

    localStorage.setItem('user-create',JSON.stringify(formik.values))
  
  }, [formik.values]);
 

  const dataKeys = data ? Object.keys(data) : [];

   

  //react-select styling
  const style = {
    control: (base) => ({
      ...base,
      border: '1px solid #ced4da;',
      // This line disable the blue border
      boxShadow: "none",
      height: "auto",
      minHeight: "20px",
      padding: 0,
      margin: 0,
    }),
  };

  const navigateToRole=()=>{
    navigate('/dashboard/settings/roles')
    dispatch(addRoute('/dashboard/settings/roles'))
    dispatch(openRole())
    

  }

  const handleClose=()=>{
    closeUserModal()
    formik.setValues(data);
    localStorage.removeItem('user-create')
    dispatch(removeRoute(current_modal))

  }

  

  if (!userModalOpen) {
    return null;
  }

  
  
  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>

      <div className="modelForm">

        <div className="dialog-header bc-clickable">
          <div className='dialog-title'>
            Create New User 
          </div>
          <div className='dialog-close'>
          <img src={crossIcon}

          onClick={handleClose}
          />
          </div>
          
        
        </div>

     
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            className='dialog-form'
          >
            <div 
              className="dialog-form-fields" 
              
            >
            {dataKeys.map((data) => {
              return (
                <div className="form-fields">
                  <label htmlFor={data} className="input-label">
                    {labelFormatter(data)} 
                  </label>

                  {(() => {
                    switch (data) {
                      case "is_active":
                        return (
                          <>
                          <FormDropDown
                              value={{value:formik.values[data]?'Yes':'No',label:formik.values[data]?'Yes':'No'}}
                              onChangeHandler={(e)=>handleDropDown(e,data)}
                              select_options={[{value:false,label:"No"},{value:true,label:'Yes'}]}
                              select_name='active-select'

                            />
                           
                          </>
                        );

                      case "is_superuser":
                        return (
                          

                          <FormDropDown
                              value={{value:formik.values[data]?'Yes':'No',label:formik.values[data]?'Yes':'No'}}
                              onChangeHandler={(e)=>handleDropDown(e,data)}
                              select_options={[{value:false,label:"No"},{value:true,label:'Yes'}]}
                              select_name='superuser-select'

                            />
                        );
                      case "role":
                          return (
                            
                              <MultiSelectDropDown
                                name="role"
                                isMulti={true}
                                value={currentUserRole}
                                onChangeHandler={handleRole}
                                select_options={role_options}
                                menuPlacement='top'
                               
                                
                              />
                              
                               
                          );
                      
                      case 'create_role':
                        return (
                          <div className="button-container">
                         <span className="button-label mx-2"></span>
                         <button className="Btn attachBtn"  onClick={navigateToRole} type='button' >
                           <div>
                             <img
                               className="mx-1 mb-1"
                               src={plusIconBlack}
                               alt="new"
                               width="15px"
                               height="15px"
                               title="New"
                             />
                           </div>
                           <span className="mx-1 fs-6">Create Role</span>
                         </button>
                         </div>
                      
                         )
                      case "template_user":

                          return (
                            
                            <FormDropDown
                              value={currentTemplateUser}
                              onChangeHandler={handleTemplateUser}
                              select_options={template_user_options}
                              select_name='template-select'
                              menuPlacement='top'

                            />
                          );
                      default:
                        return (
                          <>
                          <div className="dialog-input-container">

                         
                            <input
                              type={data == "password" ? "password" : "text"}
                              className="form-control my-1"
                              id={data}
                              name={data}
                              value={formik.values[data]}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              placeholder={`Enter ${data}`}
                            />
                            {
                              formik.touched[data] && formik.errors[data]
                              &&
                              <img
                              className="input-error-icon"
                              src={noti_info_error}
                              width='15px'
                              height='15px'
                            />

                            }
                            
                            </div>
                            <div>
                              {formik.touched[data] && formik.errors[data] ? (
                                <div className="error">
                                  {formik.errors[data]}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                    }
                  })()}
                </div>
              );
            })}
            </div>

          <div className="dialog-submit">


            <button
              type="button"
              className={`Btn ${previous_modal?'btn-prevNext-active':'btn-prevNext'}`}
              onClick={() => navigate(previous_modal)}
              disabled={!Boolean(previous_modal)}
            >
              <img src={left_arrow} width='15px' height='15px' />
            </button>
            <button type="subit" className="Btn btn-submit ">
              Save changes
            </button>

            <button
              type="button"
              className={`Btn ${next_modal?'btn-prevNext-active':'btn-prevNext'}`}
              onClick={() => navigate(next_modal)}
              disabled={!Boolean(next_modal)}
            >
              <img src={right_arrow} width='15px' height='15px' />
            </button>
          </div>

          </form>
      </div>

        
     
    </>,
    document.getElementById("portal")
  );
};

export default UserCreateDialog;
