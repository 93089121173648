import { GET_ROLE_FOR_USER,GET_ROLE_FOR_USER_ME ,CLEAR_ROLE_FOR_USER} from "./RoleForUserActionTypes";

const initialState={
    roleForUser:[],
    roleForUserMe:[],
    error:[]
    
}


const roleForuserReducer=(state=initialState,action)=>{

    switch(action.type){

        case GET_ROLE_FOR_USER:
            const newFetchState={...state,roleForUser:action.payload,error:[]}
            
            return newFetchState

        case GET_ROLE_FOR_USER_ME:
            const newRoleForUserMe={...state,roleForUserMe:action.payload,error:[]}

            return newRoleForUserMe

        case CLEAR_ROLE_FOR_USER:
            return {...initialState}

        
        default:
            return state
    }

}

export default roleForuserReducer