

import React,{useState,useEffect,useRef} from 'react'

import DbCreateDialog from './DbCreateDialog'
import DbUpdateDialog from './DbUpdateDialog'

import {useNavigate} from 'react-router-dom'


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {useDispatch,useSelector} from 'react-redux'

import {GetDbAction,DeleteDbAction,UpdateDbAction}from '../../../Redux/dbcredentials/dbActions'

import { SetCurrentAppTableAction } from '../../../Redux/currentAppTable/actions'

import SearchField from "../crudTableComponents/searchField";
import PaginationInfo from '../crudTableComponents/pagintaiton';

import {downloadIcon,plusIcon,editIcon,deleteIcon,tableIcon} from '../../../styling_files/newUiStyles/icons'

import { openDb,closeDb } from '../../../Redux/modals/modalActions';



import { onFilterChange,
  onFilterTextChange,
  RenderState,
  handleReactPagination,
  onDragStopped} from '../tableState'

//importing crud components

//importing crud components
import AgGridPageSize from "../crudTableComponents/agGridPageSize";


import { BreadCrumbs } from '../crudTableComponents/breadCrumbs';
import { ClearDagState } from '../../../Redux/loader/LoaderAction';


const DbCrud=()=>{

    const table='db'

    const dbModalOpen=useSelector(state=>state.modals.dbModalOpen)
      
    const totalPageSaved=localStorage.getItem(`${table}_totalPage`)
    let [pageInfo,setPageInfo]=useState({currentPage:0,totalPage:totalPageSaved||1})

    let [quickFilterText,setQuickFilterText]=useState(null)
    let [pageSize,setPageSize]=useState(null)

    let [selectedID,setSelectedID]=useState(null)

    let [showUpdateModal,setShowUpdateModal]=useState(false)
 
    
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const gridRef=useRef()


    let responseData=useSelector(state=>state.dbcredentials.dbcredentials)

    useEffect(()=>{
        
        
        dispatch(GetDbAction())
       
       
    },[])


  useEffect(()=>{

    dispatch(SetCurrentAppTableAction({
      currentTable:'DB Credentials Table',
      currentApp:'Settings'
    }))
    dispatch(ClearDagState())

  },[])

    //grid api states
    const [gridApi,setGridApi]=useState(null)
    const [gridColumnApi,setGridColumnApi]=useState(null)

    useEffect(()=>{
      let column_state = JSON.parse(localStorage.getItem(`${table}_column_state`));
      gridColumnApi?.applyColumnState({state:column_state, applyOrder: true})
  
    },)
  

    const onFirstDataRendered=(params)=>{

        RenderState(params,table,setQuickFilterText,setPageSize)
     
    }

    // Define the initial grid options
  const [gridOptions, setGridOptions] = useState({
    suppressCellDeselection: false,
  });

  // Define the onCellEditingStarted and onCellEditingStopped event handlers
  const onCellEditingStarted = () => {
    setGridOptions({
      ...gridOptions,
      suppressCellDeselection: true,
    });
  };

  const onCellEditingStopped = () => {
    setGridOptions({
      ...gridOptions,
      suppressCellDeselection: false,
    });
  };



     //column definations 
   const columnDefsData = [
    { headerName:'ID',field:'id',checkboxSelection:true,filter:"agNumberColumnFilter"},
    { headerName:'Type Of DB',field: 'type_of_db' ,editable:true,filter:'agTextColumnFilter'},
    { headerName:'DB Username',field: 'db_username',editable:true,filter:'agTextColumnFilter'},
    { headerName:'DB Password',field:'db_password',valueFormatter: params=>{
           
            return '********'
         
     } },
    { headerName:'DB Host',field:'db_host',editable:true,filter:'agTextColumnFilter' },
    { headerName:'DB Port',field:'db_port',editable:true,filter:"agNumberColumnFilter" },
    { headerName:'DB Name',field:'db_name',editable:true,filter:'agTextColumnFilter'},
    { headerName:'DB Alias',field:'database_alias',editable:true,filter:'agTextColumnFilter' },

  
]

const defaultColDefs={
    minWidth:200,
    headerClass:'header-class',
    cellClass:'cell-class',
    flex:1,
    sortable:true,
    resizable:true,
    icons: {
        sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
        sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
      }
   
   }

  //grid Ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

  };





  //handling selection of row

  const onSelectionChanged=(e)=>{
    const [row]=e.api.getSelectedRows()
    
    const selectedid=row?row.id:null 
    
    setSelectedID(selectedid)
   
}
//delete user

const DeleteDbCredentials=()=>{
    if(selectedID){
        const confirm=window.confirm(`Do you want to delete the credential with id :${selectedID}?`)
        if (confirm){
           
           dispatch(DeleteDbAction(selectedID))
           
           
          }
    }
    else{
        alert('please Select a row')
    }

    
}

//handling modals

const handleDbmodalClose=()=>{
  dispatch(closeDb())
}

const handleDbmodalOpen=()=>{
  dispatch(openDb())
}

const tocredentialsUpdateForm=()=>{
    setShowUpdateModal(true)

}
//dynamic router
const toAllTables=()=>{
 
    navigate(`allowedtables/${selectedID}`)
    
}

//page size

const onPageSizeChange=(pgSize)=>{
    gridApi.paginationSetPageSize(Number(pgSize))
    setPageSize(pgSize)
    localStorage.setItem(`${table}_currentPageSize`, JSON.stringify(pgSize))

    //current page state change

    handleReactPagination(gridApi,pageInfo,setPageInfo,table)


   }
    
   //Exporting

   const onExportasCSV=()=>{
     gridApi.exportDataAsCsv()

   }

     //calling update api after cell value changed

     const onCellValueChanged = (params) => {
        const value = {};
        const field_name = params.colDef.field;
        const new_value = params.value;
        value[field_name] = new_value;
        gridRef.current.api.deselectAll()
      
        // PatchDbCredetentials(selectedID,value)

        dispatch(UpdateDbAction(value,params.data.id,gridRef))
       
      };
       
   


      return(
        <>
       <BreadCrumbs/>
    
      <div className="title-layout">
        <div className='title-container'>
        <div className='current-app-table'>
                <p>Database Table</p>
    
        </div>
        </div>
       
      </div>
    
      
    
      <div className='actions-layout'>
        <div className='actions-container-backdrop'>
          
          <div className='actions-container'>
          <div className="searchfield-container">
            <span className="button-label">Search Data</span>
            <SearchField
              quickFilterText={quickFilterText}
              setQuickFilterText={setQuickFilterText}
              onFilterTextChange={onFilterTextChange}
              gridApi={gridApi}
              table={table}
            />
            </div>
           
           
           <div className="pagesize-container" >
            <span className="button-label">Rows</span>
            
              <AgGridPageSize 
               pageSize={pageSize}
               onPageSizeChange={onPageSizeChange}
               
              />
            
           </div>
           <div className="line-seperator-container">
        
        <div className="line-seperator"></div>   
        </div>

        <div className="button-container">
             
             <span className="button-label"></span>
             {/* export button */}
             <button 
             className="Btn DownloadBtn "
             onClick={onExportasCSV}
             >   
             <div>
               <img 
               className="mx-1 mb-1"
               src={downloadIcon}
               alt='csv'
                width='17px' 
                height='17px'
                />
               <span className="mx-1">Export CSV</span>
               </div>
             </button>
           </div>
   
           <div className="line-seperator-container">
           
           <div className="line-seperator"></div>   
           </div>

           {
            selectedID?

            <>
            {/* table buttons */}
             <div className="button-container">
            <span className="button-label"></span>
            <button 
              className="Btn updateBtn "
              onClick={toAllTables}
            >

            <div>
            <img 
            className="mx-1 "
            src={tableIcon}
           
            alt='tables'
            width='13px' 
            height='13px'
            
             />

            <span className="mx-1">Tables</span>
            
            </div>
          </button>

            </div>
            <div className="button-container">
            <span className="button-label"></span>
            <button 
              className="Btn deleteBtn"
              onClick={DeleteDbCredentials}>
            <div>
            <img 
            className="mx-1 mb-1"
            src={deleteIcon} 
            alt='delete'
             width='15px' 
             height='15px'
             />
            <span className="mx-1">Delete</span>
            </div>
            
          </button>

            </div>
            
            <div className="button-container">
            <span className="button-label"></span>
            <button 
              className="Btn updateBtn "
              onClick={tocredentialsUpdateForm}
            >

            <div>
            <img 
            className="mx-1 mb-1"
            src={editIcon}
           
            alt='new'
            width='14px' 
            height='14px'
            
             />

            <span className="mx-1">Edit</span>
            
            </div>
          </button>

            </div>
         
          </>


          
          :

          <div className="button-container">
          <span className="button-label"></span>
          <button 
              className="Btn createBtn"
              onClick={handleDbmodalOpen}
            >

            <div>
            <img 
            className="mx-1 mb-1"
            src={plusIcon}
            alt='new'
            width='14px' 
            height='14px'
            title='New'
             />
            
            </div>
            <span className="mx-1">New</span>
           
          </button>

          </div>
      
          } 


            
           

         
           
             
    
              
    
          </div>
    
          
          
        </div>
    
    
      </div>
      <div >
        <DbCreateDialog
          id={selectedID}
          dbClose={handleDbmodalClose}
        />
    </div>

    <div>
      <DbUpdateDialog
       selectedID={selectedID}
       setSelectedID={setSelectedID}
       showModal={showUpdateModal}
       setShowModal={setShowUpdateModal}
       gridRef
      />
    </div>
      <div className="table-layout">
        <div className='table-container'>
    
        <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={responseData}
                columnDefs={columnDefsData}
                rowSelection="single"
                // rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                defaultColDef={defaultColDefs}
                onGridReady={onGridReady}
                alwaysShowHorizontalScroll={true}
                alwaysShowVerticalScroll={true}
                onCellValueChanged={onCellValueChanged}
                onCellEditingStarted={onCellEditingStarted}
                onCellEditingStopped={onCellEditingStopped}
                headerHeight={45}
                rowHeight={45}
                pagination={true}
                suppressPaginationPanel={true}
                paginationPageSize={pageSize}
                suppressDragLeaveHidesColumns={true}
    
                onFirstDataRendered={(params) => onFirstDataRendered(params)}
                onFilterChanged={(params) => onFilterChange(params, table)}
    
                onDragStopped={(params) => onDragStopped(params, table)}
                onSortChanged={(params) => onDragStopped(params, table)}
                undoRedoCellEditing={true}
              ></AgGridReact>
            </div>
        </div>
        
        
         
        <PaginationInfo
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        gridApi={gridApi}
        table={table}
      />
          
          
           
          
       
      </div>
    
        </>
    )


} 

export default DbCrud




