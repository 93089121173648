import React,{useEffect} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetCurrentAppTableAction } from "../../../../Redux/currentAppTable/actions";
import { ClearDagState } from "../../../../Redux/loader/LoaderAction";
import {AiOutlineWarning} from 'react-icons/ai'


const FailedDbConnection= ({appName,tableName,appId,tableId}) => {

  const appsForRole = useSelector((state) => state.appsForRoles.appsForRoles);


  const dispatch=useDispatch()
  const navigate=useNavigate()

  const reloadApp=()=>{
    
    const [current_app]=appsForRole.filter(item=>item.id==appId)
    navigate(`/masterdata/${current_app.is_custom}/${appName}/${appId}/${tableName}/${tableId}`)
   
  }

  useEffect(()=>{

    dispatch(SetCurrentAppTableAction({
      currentTable:tableName,
      currentApp:appName
    }))
    dispatch(ClearDagState())

  },[])
  
  return (
    <>
   
    <div className="dashboard-layout">
     
      <div className="no-custom-view">
        <div className="text-center text-warning ">
        <AiOutlineWarning size='4rem'/>
        </div>
     

      <h2 style={{'font':'bold 40px inter'}} className="mx-2"> Database Connection Failed</h2>
       <h5 className="text-center text-danger " style={{'font':'bold 10px inter'}}
       onClick={reloadApp}
       >
        
        {/* <button className='load-app-button '>Reload App</button> */}
        </h5>
      </div>
     
    </div>
    </>
  );
};


export default FailedDbConnection