import React,{useEffect, useState} from "react";
import {useSelector} from 'react-redux'
import { useDispatch } from "react-redux";
import { GetCurrentUserAction,UpdateCurrentUserAction } from "../../../Redux/userProfile/userProfileActions";



const labelFormatter=(label)=>{
    const Label=label.split('_').join(' ')
    return Label.charAt(0).toUpperCase() + Label.slice(1);
  

}
const UserProfileForm = ({ dataKeys,type}) => {

    const dispatch=useDispatch()

    const currentUser=useSelector(state=>state.CurrentUser.CurrentUser)
    const {email:user_email,full_name}=currentUser

    const [userData,setUserData]=useState({first_name:'',last_name:'',email:''})


    useEffect(()=>{
        dispatch(GetCurrentUserAction())

    },[dispatch])


    useEffect(()=>{
      
      const name_list=full_name?.split(' ')
      const firstName=name_list?.slice(0,name_list?.length-1).join(' ')
      const lastName=name_list && name_list[name_list?.length-1]

      setUserData({
        first_name:firstName,
        last_name:lastName,
        email:user_email
      })

    },[currentUser])



    const handleChange=(e)=>{
        setUserData({
            ...userData,
            [e.target.name]:e.target.value
        })

    }
    
    const handleSubmit=(e)=>{
        e.preventDefault()
        dispatch(UpdateCurrentUserAction({
            email:userData.email,
            full_name:userData.first_name+" "+userData.last_name
        }))
        
    }



  
   
   

    
    return (
        
            <form 
                className="user-profile-form"
                onSubmit={(e)=>handleSubmit(e)}>
                <div >
                {dataKeys.map((data) => {
                    return (
                        <>
                        
                        <div className={`form-field ${data}-formfield`}>

                            <label htmlFor={data} class='form-label' >{labelFormatter(data)} </label>
                            {(() => 
                            {
                            switch (data) {

                                case 'e-mail':
                                    return (
                                        <>
                                        <input
                                            type='email'
                                            id={data}
                                            name='email'
                                            value={userData.email}
                                            className='input-field'
                                            placeholder={`${labelFormatter(data) }`}
                                            onChange={(e)=>handleChange(e)}
                                        />
                                        </>
                                        );
                                

                                default:
                                return (
                                    <>
                                    <input
                                        type={data == "password" ? "password" : "text"}
                                        id={data}
                                        name={data}
                                        value={userData[`${data}`]}
                                        className='input-field'
                                        placeholder={`${labelFormatter(data) }`}
                                        onChange={(e)=>handleChange(e)}
                                    />
                                    </>
                                    );
                                }
                                })()}
                        </div>

                        
                        </>
                        );
                    })}
                </div>

                <div>
                    <button type="submit" className="submit-btn">
                        
                      Update Profile
                    </button>
                </div>
            </form>
       
    )
}

export default UserProfileForm