import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { MasterdataBreadcrumbs } from "../../crudTableComponents/breadCrumbs";

import Filter from "../agGridFilter";

import {
  EditPermission,
  CreatePermission,
} from "../Edit_delete_permission/edit_delete_permission";

import {
  downloadIcon,
  editIcon,
  plusIcon,
  deleteIcon,
  airflowIcon,
} from "../../../../styling_files/newUiStyles/icons";

import AgGridTable from "../../crudTableComponents/agGridTable";

import { findColumnType } from "../findColumnType";

import {
  GetTableMetaDataAction,
  GetTableAllColumnsMetaDataAction,
  GetTableRowsDataAction,
  GetTableSingleRowDataAction,
  GetTableUpdatedRowDataAction,
  DeleteTableRowDataAction,
  UpdateTableRowDataAction,
  PostTableRowDataAction,
} from "../../../../Redux/masterdata/masterdataActions";

import { SetCurrentAppTableAction } from "../../../../Redux/currentAppTable/actions";
import MasterDataCreateDialog from "../../crudTableComponents/CreateDialog";
import MasterDataUpdateDialog from "../../crudTableComponents/UpdateDialog";

import { CellEditPermission } from "../CellEditPermission";

import {
  onFilterChange,
  onFilterTextChange,
  RenderState,
  handleReactPagination,
  onDragStopped,
} from "../../tableState";

//importing crud components
import SearchField from "../../crudTableComponents/searchField.js";
import AgGridPageSize from "../../crudTableComponents/agGridPageSize";
import LastDagState from "../../crudTableComponents/dagState";

//importing loader action

import {
  ShowLoaderAction,
  GetLastDagStateAction,
  ClearDagState,
} from "../../../../Redux/loader/LoaderAction";
import { SendToAirFlowAutoversandAction } from "../../../../Redux/masterdata/customTableActions/autoVersandAction";

const AutoversandCrud = () => {
  let { appName } = useParams();
  let { appID } = useParams();
  let { allowedTableID } = useParams();
  let { tableName } = useParams();

  let table = `${appID}_${tableName}`;

  let [tableMetadataLoaded, setTableMetadataLoaded] = useState(false);

  const totalPageSaved = localStorage.getItem(`${table}_totalPage`);
  let [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPage: totalPageSaved || 1,
  });

  let [quickFilterText, setQuickFilterText] = useState(null);
  let [pageSize, setPageSize] = useState(null);

  const [primaryKeyName, setPrimaryKeyName] = useState(null);
  const [columnDefsData, setColumnDefsData] = useState([]);
  const [createDialogueInputFields, setCreateDialogueInputFields] = useState({
    values: {},
    types: {},
  });
  const [updateDialogueInputFields, setUpdateDialogueInputFields] = useState({
    values: {},
    types: {},
    show: false,
  });
  const [deletePermissionGiven, setDeletePermissionGiven] = useState(false);

  const [selectedRow, setSelectedRow] = useState({ id: null, data: null });
  const [showCreateModal, setShowCreateModal] = useState(false);

  const dispatch = useDispatch();
  const gridRef = useRef();

  const tableMetaData = useSelector((state) => state.masterdata.tableMetaData);

  const tableAllColumnsMetaData = useSelector(
    (state) => state.masterdata.tableAllColumnsMetaData
  );
  const tableRowsData = useSelector((state) => state.masterdata.tableRowsData);
  const createdRowPkID = useSelector(
    (state) => state.masterdata.createdRowPkID
  );
  const updatedRowPkID = useSelector(
    (state) => state.masterdata.updatedRowPkID
  );

  const dag_state =
    useSelector((state) => state.loader.dag_state.state) ;
  //grid api states
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    let column_state = JSON.parse(
      localStorage.getItem(`${table}_column_state`)
    );

    gridColumnApi?.applyColumnState({ state: column_state, applyOrder: true });
  });

  const onRowDataChanged = (params) => {
    RenderState(params, table, setQuickFilterText, setPageSize);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  //handling selection of row

  const onSelectionChanged = (e) => {
    const row = e.api.getSelectedRows();
    const selectedid = row[0] ? row[0][primaryKeyName] : null;
    const selctedData = row[0] ? row[0] : null;
    setSelectedRow({ id: selectedid, data: selctedData });
  };

  //current app and table

  useEffect(() => {
    dispatch(
      SetCurrentAppTableAction({
        currentTable: tableName,
        currentApp: appName,
      })
    );
    dispatch(ClearDagState());
    dispatch(GetLastDagStateAction(appName, tableName));
  }, []);

  useEffect(() => {
    dispatch(GetTableMetaDataAction(appID, allowedTableID));
    setTableMetadataLoaded(true);
  }, [appID, allowedTableID]);

  //all column action call

  useEffect(() => {
    if (
      tableMetadataLoaded &&
      (tableMetaData?.all_column_permissions.read ||
        tableMetaData?.all_column_permissions.create)
    ) {
      dispatch(GetTableAllColumnsMetaDataAction(appID, allowedTableID));
    }
  }, [appID, allowedTableID, tableMetaData]);

  // Get primary key from metadata

  useEffect(() => {
    if (tableMetaData) {
      let all_column_read_permission_given =
        tableMetaData.all_column_permissions.read;
      let primary_key_name = null;
      if (all_column_read_permission_given) {
        if (tableAllColumnsMetaData) {
          tableAllColumnsMetaData.forEach((c) => {
            if (c.is_primary_key) {
              primary_key_name = c.column_name;
            }
          });
        }
      } else {
        let columns = tableMetaData.columns;
        columns.forEach((c) => {
          if (c.is_primary_key) {
            primary_key_name = c.column_name;
          }
        });
      }
      setPrimaryKeyName(primary_key_name);
    }
  }, [tableMetaData, tableAllColumnsMetaData]);

  useEffect(() => {
    if (tableMetaData) {
      let all_column_read_permission_given =
        tableMetaData.all_column_permissions.read;

      let columnDefs = [];
      let colDef = {};

      if (all_column_read_permission_given) {
        if (tableAllColumnsMetaData) {
          tableAllColumnsMetaData.forEach((c) => {
            if (c.is_primary_key) {
              colDef = {
                headerName: c.column_name,
                field: c.column_name,
                hide: true,
                checkboxSelection: true,
                ...Filter(c.data_type),
              };
              columnDefs.push(colDef);
            } else {
              colDef = {
                headerName: c.column_name,
                field: c.column_name,
                editable: CellEditPermission(
                  tableMetaData.all_column_permissions,
                  tableMetaData.columns,
                  c.column_name
                ),
                ...Filter(c.data_type),
              };
              columnDefs.push(colDef);
            }
          });
        }
      } else {
        let columns = tableMetaData.columns;
        columns.forEach((c) => {
          if (c.permissions.read) {
            if (c.is_primary_key) {
              colDef = {
                headerName: c.column_name,
                field: c.column_name,

                hide: true,
                checkboxSelection: true,
                ...Filter(c.data_type),
              };
              columnDefs.push(colDef);
            } else {
              colDef = {
                headerName: c.column_name,
                field: c.column_name,
                editable: CellEditPermission(
                  tableMetaData.all_column_permissions,
                  tableMetaData.columns,
                  c.column_name
                ),
                ...Filter(c.data_type),
              };
              columnDefs.push(colDef);
            }
          }
        });
      }
      setColumnDefsData(columnDefs);
    }
  }, [tableMetaData, tableAllColumnsMetaData]);

  //dafault column def
  const defaultColDefs = {
    minWidth: 200,
    headerClass: "header-class",
    cellClass: "cell-class",
    flex: 1,
    sortable: true,
    resizable: true,
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
      sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
    },
  };

  // Get delete permission info from metadata
  useEffect(() => {
    if (tableMetaData) {
      let all_column_delete_permission_given =
        tableMetaData.all_column_permissions.delete;
      setDeletePermissionGiven(all_column_delete_permission_given);
    }
  }, [tableMetaData]);

  // Get the info for create modal creation
  useEffect(() => {
    if (tableMetaData) {
      let all_column_create_permission_given =
        tableMetaData.all_column_permissions.create;

      let createDialogueInputFieldsData = {
        values: {},
        types: {},
        nullable: {},
        has_default: {},
      };

      if (all_column_create_permission_given) {
        if (tableAllColumnsMetaData) {
          tableAllColumnsMetaData.forEach((c) => {
            // omit primary key column since primary key can not be created by user
            if (!c.is_primary_key) {
              createDialogueInputFieldsData.values[c.column_name] = null;
              createDialogueInputFieldsData.types[c.column_name] =
                findColumnType(c.data_type);

              createDialogueInputFieldsData.nullable[c.column_name] =
                c.nullable;
              createDialogueInputFieldsData.has_default[c.column_name] =
                c.has_default;
            }
          });
        }
      } else {
        let columns = tableMetaData.columns;
        columns.forEach((c) => {
          if (c.permissions.create) {
            createDialogueInputFieldsData.values[c.column_name] = null;
            createDialogueInputFieldsData.types[c.column_name] = findColumnType(
              c.data_type
            );
            createDialogueInputFieldsData.nullable[c.column_name] = c.nullable;
            createDialogueInputFieldsData.has_default[c.column_name] =
              c.has_default;
          }
        });
      }
      setCreateDialogueInputFields(createDialogueInputFieldsData);
    }
  }, [tableMetaData, tableAllColumnsMetaData]);

  // Get the info for update modal creation
  useEffect(() => {
    if (tableMetaData) {
      let all_column_update_permission_given =
        tableMetaData.all_column_permissions.update;
      let all_column_read_permission_given =
        tableMetaData.all_column_permissions.read;

      let updateDialogueInputFieldsData = {
        values: {},
        types: {},
        nullable: {},
        has_default: {},
      };

      if (all_column_update_permission_given) {
        if (tableAllColumnsMetaData) {
          tableAllColumnsMetaData.forEach((c) => {
            // omit primary key column since primary key can not be created by user
            if (!c.is_primary_key && all_column_read_permission_given) {
              updateDialogueInputFieldsData.values[c.column_name] =
                selectedRow.data ? selectedRow.data[c.column_name] : null;
              updateDialogueInputFieldsData.types[c.column_name] =
                findColumnType(c.data_type);
              updateDialogueInputFieldsData.nullable[c.column_name] =
                c.nullable;
              updateDialogueInputFieldsData.has_default[c.column_name] =
                c.has_default;
            }
          });
        }
      } else {
        let columns = tableMetaData.columns;
        columns.forEach((c) => {
          if (
            c.permissions.update &&
            (c.permissions.read || all_column_read_permission_given)
          ) {
            updateDialogueInputFieldsData.values[c.column_name] =
              selectedRow.data ? selectedRow.data[c.column_name] : null;
            updateDialogueInputFieldsData.types[c.column_name] = findColumnType(
              c.data_type
            );

            updateDialogueInputFieldsData.nullable[c.column_name] = c.nullable;
            updateDialogueInputFieldsData.has_default[c.column_name] =
              c.has_default;
          }
        });
      }

      setUpdateDialogueInputFields(updateDialogueInputFieldsData);
    }
  }, [tableMetaData, tableAllColumnsMetaData, selectedRow]);

  useEffect(() => {
    dispatch(GetTableRowsDataAction(appID, allowedTableID));
  }, []);

  useEffect(() => {
    const [pkey] = Object.keys(createdRowPkID);

    if (pkey) {
      dispatch(
        GetTableSingleRowDataAction(appID, allowedTableID, createdRowPkID[pkey])
      );
    }
  }, [createdRowPkID]);

  useEffect(() => {
    const [pkey] = Object.keys(updatedRowPkID);

    if (pkey) {
      dispatch(
        GetTableUpdatedRowDataAction(
          appID,
          allowedTableID,
          updatedRowPkID[pkey],
          pkey
        )
      );
    }
  }, [updatedRowPkID]);

  //navigations
  const toMasterDataCreateForm = () => {
    setShowCreateModal(true);
  };

  const toMasterDataUpdateForm = () => {
    let update_info = { ...updateDialogueInputFields };
    update_info.show = true;
    setUpdateDialogueInputFields(update_info);
  };

  const deleteRowData = () => {
    let deletableRowID = selectedRow.id ? selectedRow.id : null;
    if (deletableRowID) {
      const confirm = window.confirm(
        `Do you want to delete the row with id :${deletableRowID}?`
      );
      if (confirm) {
        setSelectedRow({ id: null, data: null });
        dispatch(
          DeleteTableRowDataAction(
            appID,
            allowedTableID,
            deletableRowID,
            primaryKeyName
          )
        );
      }
    } else {
      alert("Please Select a row!");
    }
  };

  //calling update api after cell value changed

  const onCellEditingStopped = (params) => {
    const value = {};
    const field_name = params.colDef.field;
    const new_value = params.value;
    value[field_name] = new_value ? new_value : "";
    gridRef.current.api.deselectAll();

    dispatch(
      UpdateTableRowDataAction(
        appID,
        allowedTableID,
        params.data.id,
        value,
        gridRef
      )
    );
  };

  const onCellEditingStarted = (params) => {};

  //page size change

  const onPageSizeChange = (pgSize) => {
    gridApi.paginationSetPageSize(Number(pgSize));
    setPageSize(pgSize);
    localStorage.setItem(`${table}_currentPageSize`, JSON.stringify(pgSize));

    //current page state change

    handleReactPagination(gridApi, pageInfo, setPageInfo, table);
  };
  //Exporting

  const onExportasCSV = () => {
    gridApi.exportDataAsCsv();
  };

  //send to airflow

  const onSendToAirFlow = () => {
    dispatch(SendToAirFlowAutoversandAction(appName, tableName));
    dispatch(ShowLoaderAction("Sending to Airflow"));
  };

  //create function
  const onCreateRow = (update_data) => {
    dispatch(PostTableRowDataAction(appID, allowedTableID, update_data));
  };

  return (
    <>
      <MasterdataBreadcrumbs />
      {/* table name */}
      <div className="title-layout">
        <div className="title-container">
          <div className="current-app-table">
            <span>{tableName}</span>

            {/* last dag state */}
            {dag_state && <LastDagState dag_state={dag_state} />}
          </div>
        </div>
      </div>

      {/* actions:search and buttons */}

      <div className="actions-layout">
        <div className="actions-container-backdrop">
          <div className="actions-container">
            <div className="searchfield-container">
              <span className="button-label">Search Data</span>
              <SearchField
                quickFilterText={quickFilterText}
                setQuickFilterText={setQuickFilterText}
                onFilterTextChange={onFilterTextChange}
                gridApi={gridApi}
                table={table}
              />
            </div>

            <div className="pagesize-container">
              <span className="button-label">Rows</span>

              <AgGridPageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </div>

            <div className="line-seperator-container">
              <div className="line-seperator"></div>
            </div>

            <div className="button-container">
              <span className="button-label"></span>
              {/* export button */}
              <button className="Btn DownloadBtn " onClick={onExportasCSV}>
                <div>
                  <img
                    className="mx-1 mb-1"
                    src={downloadIcon}
                    alt="csv"
                    width="17px"
                    height="17px"
                  />
                  <span className="mx-1">Export CSV</span>
                </div>
              </button>
            </div>

            <div className="line-seperator-container">
              <div className="line-seperator"></div>
            </div>

            {selectedRow.id ? (
              <>
                {deletePermissionGiven && (
                  <>
                    {/* delete button */}
                    <div className="button-container">
                      <span className="button-label"></span>
                      <button className="Btn deleteBtn" onClick={deleteRowData}>
                        <div>
                          <img
                            className="mx-1 mb-1"
                            src={deleteIcon}
                            alt="delete"
                            width="15px"
                            height="15px"
                          />
                          <span className="mx-1">Delete</span>
                        </div>
                      </button>
                    </div>
                  </>
                )}

                {tableMetaData && EditPermission(tableMetaData) && (
                  <>
                    {/* update button */}
                    <div className="button-container">
                      <span className="button-label"></span>
                      <button
                        className="Btn updateBtn "
                        onClick={toMasterDataUpdateForm}
                      >
                        <div>
                          <img
                            className="mx-1 mb-1"
                            src={editIcon}
                            alt="new"
                            width="14px"
                            height="14px"
                          />

                          <span className="mx-1">Edit</span>
                        </div>
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {/* refresh button */}

                {tableMetaData && CreatePermission(tableMetaData) && (
                  <>
                    {/* create button */}

                    <div className="button-container">
                      <span className="button-label"></span>
                      <button
                        className="Btn createBtn"
                        onClick={toMasterDataCreateForm}
                      >
                        <div>
                          <img
                            className="mx-1 mb-1"
                            src={plusIcon}
                            alt="new"
                            width="14px"
                            height="14px"
                            title="New"
                          />
                        </div>
                        <span className="mx-1">New</span>
                      </button>
                    </div>
                  </>
                )}

                <div className="button-container">
                  <span className="button-label"></span>
                  <button className="Btn airflowBtn" onClick={onSendToAirFlow}>
                    <div>
                      <img
                        className="mx-1 mb-1"
                        src={airflowIcon}
                        alt="new"
                        width="18px"
                        height="18px"
                        title="New"
                      />
                    </div>
                    <span className="mx-1">Send</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <MasterDataCreateDialog
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        appID={appID}
        allowedTableID={allowedTableID}
        createDiologueInputFields={createDialogueInputFields}
        onCreateRow={onCreateRow}
      />

      <MasterDataUpdateDialog
        appID={appID}
        allowedTableID={allowedTableID}
        updateDialogueInputFields={updateDialogueInputFields}
        setUpdateDialogueInputFields={setUpdateDialogueInputFields}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />

      <div className="table-layout">
        <div className="table-container">
          <AgGridTable
            gridRef={gridRef}
            tableRowsData={tableRowsData}
            columnDefsData={columnDefsData}
            onSelectionChanged={onSelectionChanged}
            defaultColDefs={defaultColDefs}
            onGridReady={onGridReady}
            onCellEditingStopped={onCellEditingStopped}
            onRowDataChanged={onRowDataChanged}
            onFirstDataRendered={onRowDataChanged}
            onFilterChange={onFilterChange}
            onDragStopped={onDragStopped}
            pageSize={pageSize}
            table={table}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            gridApi={gridApi}
          />
        </div>
      </div>
    </>
  );
};

export default AutoversandCrud;
