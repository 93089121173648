import {FETCH_APP_TABLE,POST_APP_TABLE,DELETE_APP_TABLE,FETCH_TABLE_METADATA,FETCH_DB_ALLOWEDTABLE,DELETE_SINGLE_APP_TABLE} from './appPermissionActionTypes'
import {SuccessAlert,ErrorAlert} from '../../components/notifications/notifications'

import { GetTableForApp,PostTableForApp,DeleteTableForApp,GetAppTableMetadata } from '../../api/appPermissionAPi' 
import { GetCurrentUserAction } from '../userProfile/userProfileActions'
import { errorHandler } from '../../api/errorHandler'



const GetTableForAppAction=(appID)=>{
    return async function (dispatch){
        return GetTableForApp(appID).then((response)=>{

           
            
            dispatch({
                
                    type:FETCH_APP_TABLE,
                    payload:response.data,
                
            })
        })
        .catch(error=>errorHandler(error))
    
}
}

//action to update the redux state without api call (api calls are made in SaveTableForAppAction)

const PostAppTableAction=(dispatch,data)=>{
   
    dispatch({
        type:POST_APP_TABLE,
        payload:data
    })

}

const DeleteSingleAppTableAction=(appID,tableID)=>{
    return async function(dispatch){
        return DeleteTableForApp(appID,tableID).then(response=>{
            dispatch({
                type:DELETE_SINGLE_APP_TABLE,
                payload:tableID
            })
        })
    }

}

const DeleteAppTableAction=(dispatch,deleteIds)=>{
    
    dispatch({
        type:DELETE_APP_TABLE,
        payload:deleteIds
    })

}


const SaveTableForAppAction=(appId,PrevTables,CurrTables)=>{


    console.log('current attach table',CurrTables)

    
    
    
    return async function (dispatch){

        

        const PrevTableID=PrevTables?.map(item=>item.value)
        const CurrTableID=CurrTables?.map(item=>item.value)
        
        const deleted_table_ID=PrevTableID.filter(x=>!CurrTableID.includes(x))
        const created_table_ID=CurrTableID.filter(x=>!PrevTableID.includes(x))

      

        
        //deleting allowed tables

    if(deleted_table_ID.length){
        Promise.all(deleted_table_ID.map(id=>{
            if(id){
             return(DeleteTableForApp(appId,id))
            }
           
        }))
        .then(response=>
            {   SuccessAlert('Removing allowed table successful')
                const deleteids=response.map(item=>item.data).map(item=>item.id)
              
                DeleteAppTableAction(dispatch,deleteids)
               
                
            }
            
            
            )
        .then(dispatch(GetCurrentUserAction()))
        .catch(error=>errorHandler(error))
     }
      

    //creating allowed tables
    if(created_table_ID.length){

        Promise.all(created_table_ID.map(id=>{
          
            return PostTableForApp(appId,{application_id:appId,allowed_table_id:id})
        }))
        .then(response=>{

            SuccessAlert('Add to allowed table successful')
            const success_response=response.map(item=>item.data)
            

            PostAppTableAction(dispatch,success_response)
            
           
            
        })
            
        .catch(error=>errorHandler(error))
        
    }

       
       
    }}
    


const GetAppTableMetadataAction=()=>{
   
    return async function (dispatch){
        return GetAppTableMetadata().then((response)=>{

            
            
            dispatch({
                
                    type:FETCH_TABLE_METADATA,
                    payload:response.data,
                
            })
        })
    }
    
}








export {GetTableForAppAction,SaveTableForAppAction,GetAppTableMetadata,DeleteSingleAppTableAction}

