import {TOGGLE_SIDEBAR,SET_SELECTED_TYPE} from './sidebarControlActionTypes'


const initialState={
    show_sidebar:false,
    selected_type:''
}


const SidebarControlReducer=(state=initialState,action)=>{

    switch(action.type){

        case TOGGLE_SIDEBAR:

        if(action.payload==='show'){
            return {...state,show_sidebar:true}

        }
        else if(action.payload==='hide'){
            return {...state,show_sidebar:false}


        }
        else{
            return {...state,show_sidebar:!state.show_sidebar}

        }
        case SET_SELECTED_TYPE:
            return {
                ...state,
                selected_type:action.payload
            }
        default:
            return state
    }

}

export default SidebarControlReducer