export const onFilterChange = (params, table) => {
  const filterModel = params.api.getFilterModel();

  localStorage.setItem(`${table}_filter`, JSON.stringify(filterModel));
};

//search filter
export const onFilterTextChange = (e, setQuickFilterText, gridApi, table) => {
  setQuickFilterText(e.target.value);
  gridApi.setQuickFilter(e.target.value);
  localStorage.setItem(`${table}_quickFilterText`, e.target.value);
};

export const onPaginationChanged = (params, pageInfo, setPageInfo) => {
//   let currentPage = params.api.paginationGetCurrentPage()
//   setPageInfo({...pageInfo,currentPage:currentPage+2})
//   console.log('current page ====>',currentPage)
};

export const handleReactPagination = (gridApi, pageInfo, setPageInfo,table) => {
      let currentPage = gridApi.paginationGetPageSize()
      let totalPage=gridApi.paginationGetTotalPages()

      localStorage.setItem(`${table}_totalPage`,totalPage);
      setPageInfo({...pageInfo,currentPage:currentPage+1,totalPage:totalPage})

    };



export const onDragStopped = (params, table) => {
  const columnState = params.columnApi.getColumnState();
  // console.log('column state on drag stopped',columnState)

 
  const json_column_state = JSON.stringify(columnState);
  localStorage.setItem(`${table}_column_state`, json_column_state);
};

export const RenderState = (params, table, setQuickFilterText, setPageSize) => {


  let filterModel = JSON.parse(localStorage.getItem(`${table}_filter`));


  let quickFilterText = localStorage.getItem(`${table}_quickFilterText`);

  let no_of_entity = JSON.parse(
    localStorage.getItem(`${table}_currentPageSize`)
  );

  
  let column_state = JSON.parse(localStorage.getItem(`${table}_column_state`));


  setPageSize(no_of_entity);
  setQuickFilterText(quickFilterText);

  params.api.setFilterModel(filterModel);
  
  params.api.setQuickFilter(quickFilterText);


 
};


//undo cell edit
export const undoCellEdit=(gridRef)=>{

  gridRef.current.api.undoCellEditing()

}

