import { RiDatabaseFill } from "react-icons/ri";
import {
  CLOSE_APP,
  CLOSE_ROLE,
  CLOSE_DB,
  CLOSE_USER,
  OPEN_APP,
  OPEN_DB,
  OPEN_ROLE,
  OPEN_USER,
  OPEN_ATTACH,
  CLOSE_ATTACH,
  ADD_ROUTE,
  REMOVE_ROUTE,
  ADD_TEMP_ATTACH_DATA,
  REMOVE_TEMP_ATTACH_DATA
  
} from "./actionTypes";

export const openApp = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN_APP,
    });
  };
};

export const openDb = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN_DB,
    });
  };
};

export const openUser = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN_USER,
    });
  };
};

export const openRole = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN_ROLE,
    });
  };
};

export const closeApp = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_APP,
    });
  };
};

export const closeDb = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_DB,
    });
  };
};

export const closeUser = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_USER,
    });
  };
};

export const closeRole = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ROLE,
    });
  };
};



export const addRoute=(route)=>{
    return (dispatch) => {
        dispatch({
          type:ADD_ROUTE ,
          payload:route
        });
      };

}

export const removeRoute=(route)=>{
    return (dispatch) => {
        dispatch({
          type:REMOVE_ROUTE ,
          payload:route
        });
      };

}

export const openAttach=()=>{

  return (dispatch) => {
    dispatch({
      type:OPEN_ATTACH,
    });
  };

}

export const closeAttach=()=>{

  return (dispatch) => {
    dispatch({
      type:CLOSE_ATTACH,
    });
  };

}

export const AddTempAtachData=(data)=>{
  return (dispatch) => {
    dispatch({
      type:ADD_TEMP_ATTACH_DATA,
      payload:data
    });
  };

}

export const RemoveTempAtachData=()=>{
  return (dispatch) => {
    dispatch({
      type:REMOVE_TEMP_ATTACH_DATA,
      
    });
  };

}