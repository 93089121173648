import styled from "styled-components";

export const ListBox=styled.div`
border: 1px solid #B6B6B7;
background:white;

height:60vh;

margin-top:.5rem;
overflow:scroll;
-ms-overflow-style: none; 
scrollbar-width: none; 
border-radius:10px;
padding:10px;

&::-webkit-scrollbar {
    display: none;
  }



`

export const ListItem=styled.div`

    display:flex;
    align-items: center;
    justify-content:flex-start;
    padding:.1em;
    
    font: normal normal  18px/17px Lato

`

export const Input=styled.input`
font-size: 20px;
width:18px;
height: 18px;

`


export const Title=styled.h3`
display: flex;
font-size: 25px;
align-items: center;
justify-content: flex-start;
font-family:'inter';
font-size:1.4rem;

`