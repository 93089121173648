export const GET_TABLE_METADATA_ACTION='GET_TABLE_METADATA_ACTION'
export const GET_TABLE_ALL_COLUMNS_METADATA_ACTION='GET_TABLE_ALL_COLUMNS_METADATA_ACTION'
export const GET_TABLE_ROWS_DATA_ACTION='GET_TABLE_ROWS_DATA_ACTION'
export const POST_TABLE_ROW_DATA_ACTION='POST_TABLE_ROW_DATA_ACTION'
export const GET_TABLE_CREATED_SINGLE_ROW_DATA_ACTION='GET_TABLE_CREATED_SINGLE_ROW_DATA_ACTION'
export const UPDATE_TABLE_ROW_DATA_ACTION='UPDATE_TABLE_ROW_DATA_ACTION'
export const GET_TABLE_UPDATED_ROW_DATA_ACTION='GET_TABLE_UPDATED_ROW_DATA_ACTION'
export const DELETE_TABLE_ROW_DATA_ACTION='DELETE_TABLE_ROW_DATA_ACTION'


export const CLEAR_UPDATED_PK_ID='CLEAR_UPDATED_PK_ID'
export const CLEAR_CREATED_PK_ID='CLEAR_CREATED_PK_ID'

export const CLEAR_MASTERDATA_STATE='CLEAR_MASTERDATA_STATE'


//custom actions types

export const SEND_TO_AIRFLOW='SEND_TO_AIRFLOW'

export const SEND_TO_AIRFLOW_AUTOVERSAND='SEND_TO_AIRFLOW_AUTOVERSAND'