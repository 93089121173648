import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetTableForAppMasterdata } from "../../../api/appPermissionAPi";
import { errorHandler } from "../../../api/errorHandler";

import { down_arrow,right_arrow} from '../../../styling_files/newUiStyles/icons'


const TableList = ({ appName, appId,is_custom }) => {

  const [tables, setTables] = useState([]);
  const [showTables, setShowTables] = useState(false);

  useEffect(() => {
    GetTableForAppMasterdata(appId)
      .then((response) => setTables(response.data))
      .catch((error) => errorHandler(error));
  },[]);



  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toTableGrid = (tableId, tableName) => {

    navigate(`/masterdata/${is_custom}/${appName}/${appId}/${tableName}/${tableId}`);
  };

  const handleAppClick = () => {
    setShowTables(!showTables);
  };

  if(tables.length){
    return (
      
    <div className="dropdown-container">

      {/* app name */}
        <div  className="app-name" onClick={handleAppClick} >
          <span key={appId}>
            {appName.length>24?appName.substring(0,23)+'...':appName}
          </span>
          <span>
            {
              showTables?
              <img src={down_arrow} alt='close' width='13px' height='13px'/>
              :
              <img src={right_arrow} alt='open'  width='13px' height='13px'/>
            }
            
          </span>
        </div>
            
        {/* dropdown-content */}
        <div className="dropdown-content">
  
          {tables.length && appId  && showTables && 

          (
              tables.map((item) => {
  
                  return (
                  
                    <span
                     
                      key={item.id}
                    >
                    <p onClick={()=>toTableGrid(item.id,item.table_name)}> {item.table_name.length>20?item.table_name.substring(0,20)+'...':item.table_name}</p>

                    
                   
                    </span>
                    
                  );
              
              })   
          )
          } 
        </div>
    </div>
      
    );
  }
  else{
    return null
  }
 
};

export default TableList;
