import { AxiosRequest } from "../rootApi";

const max_retry=3
const wait_time=300

//get users from xentral
const GetUsersFromXentral = () => {

    return AxiosRequest({ url: `api/v1/custom/xentral/user/names`, method: 'GET' })

}
export const GetUsersFromXentralRetry = () => {
    return new Promise((resolve, reject) => {

        let retries = 0
        const UserFetcher = () => {
            GetUsersFromXentral()
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                setTimeout(()=>{
                    if (retries < max_retry) {
                        
                        retries++
                        UserFetcher()

                    }
                    else {
                        reject(error)
                    }

                },wait_time)
                   
                })
        }
        retries=1
        UserFetcher()
    })
}


const GetPrintersFromXentral=()=>{

    return AxiosRequest ({url:`api/v1/custom/xentral/drucker/names`,method:'GET'})

}


export const GetPrintersFromXentralRetry = () => {
    return new Promise((resolve, reject) => {

        let retries = 0
        const PrinterFetcher = () => {
            GetPrintersFromXentral()
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                    setTimeout(() => {
                        if (retries < max_retry) {
                        
                            retries++
                            PrinterFetcher()
    
                        }
                        else {
                            reject(error)
                        }
                        
                    },wait_time);
                    
                })
        }
        retries=1
        PrinterFetcher()
    })
}


