import React from "react";
import { useParams } from "react-router-dom";

import KommilaufCrud from "../../components/crud/MasterDataCrud/specialTableCrud/kommilaufCrud";
import LeitstandFruh from "../../components/crud/MasterDataCrud/specialTableCrud/LeitstandFruhCrud";
import LeitstandNacht from "../../components/crud/MasterDataCrud/specialTableCrud/LeitstandNacht";
import LeitstandSpät from "../../components/crud/MasterDataCrud/specialTableCrud/LeitstandSpät";

import AutoversandCrud from "../../components/crud/MasterDataCrud/specialTableCrud/AutoversandCrud";
import Auto_umlagerungCrud from "../../components/crud/MasterDataCrud/specialTableCrud/Auto_umlagerungCrud";
import ProduktionLieferscheinCrud from "../../components/crud/MasterDataCrud/specialTableCrud/ProduktionLieferscheinCrud";

import AirflowTest from "../../components/crud/MasterDataCrud/specialTableCrud/airflowTestCrud";
import NoCustomView from "./noCustomView";

const CustomTableRouteMapping = () => {
  let { tableName } = useParams();
  let { appName } = useParams();

  switch (tableName) {

    case 'Kommilauf':
      return <KommilaufCrud/>

    case 'Produktion Lieferschein':
      return <ProduktionLieferscheinCrud/>

    case 'Autoversand':
      return (<AutoversandCrud/>)

   case 'Leitstand früh':
      return (<LeitstandFruh/>)

    case 'Leitstand nacht':
        return (<LeitstandNacht/>)

    case 'Leitstand spät':
          return (<LeitstandSpät/>)

   
    case 'Auto_umlagerung':
           return (
            <Auto_umlagerungCrud/>
           )

    case 'auto_umlagerung':
      return (
        <Auto_umlagerungCrud/>
       )
    case 'AirflowTest':
        return (<AirflowTest/>)

    default:
      return (
       
          <NoCustomView appName={appName} tableName={tableName} />
       
      );
  }
};
export default CustomTableRouteMapping;
