import {TOGGLE_SIDEBAR,SET_SELECTED_TYPE} from './sidebarControlActionTypes'



export const OpenCloseSidebar=(value)=>{

    return {
        type:TOGGLE_SIDEBAR,
        payload:value
    }

}

export const SetSelectedAppType=(app_type)=>{
    return {
        type:SET_SELECTED_TYPE,
        payload:app_type
    }
}