import React from 'react'

function AgGridPageSize({onPageSizeChange,pageSize}) {
  return (
  
        <input 
            className='pageSize-input'
            type="number" 
            value={pageSize} 
            onChange={(e)=>onPageSizeChange(e.target.value)} 
            id="pageSize" 
            name="quantity"
            min='1'
            
          
        />
        
   
  )
}

export default AgGridPageSize