import React,{useState} from 'react'
import Select from "react-select";


const XentralUsrePrinterEditor = ({node,values,column,gridRef}) => {



 const [currentSelected,setCurrentSelected]=useState() 
 const field=column.colDef.field
 const current_field_value=node.data[field]

  //creating options for react select

  const select_options = values?.map((value) => {
    return { value: value, label: value };
  });





  //react-select styling
  const style = {
    control: (base) => ({
      ...base,
      height:'30px',
      width:'85%',
    
      minHeight:'none',
      position:'absolute',
      margin:'7px 0 5px 1.1rem',
      padding:'0',
      display:'flex',
      alignItems:'center',
      
      background: '#FFFFFF !important',
  

      border: '1px solid #5288FF !important',
      boxShadow:'0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCDCFF !important',

      borderRadius:'8px'

    
      
    }),
    valueContainer:(base)=>({
  
      height:'30px',
      width:'70%',
      margin:'0',
      display:'flex',
      alignItems:'center',
      padding:'0',
      marginLeft:'1rem',
      marginBottom:'.5rem',
      position:'relative',
  
    }),
    singleValue:(base)=>({

      height:'30px',
      width:'auto',
      display:'flex',
      justifyContent:'space-eavenly',
      alignItems:'center',
      padddingInline:'15px',
    }),
    indicatorsContainer:(base)=>({
      ...base,
      height:'30px',
      marginBottom:'.5rem',


    }),
    menu:(base)=>({
      ...base,
      background: '#FFFFFF',
      border:'1px solid #EAECF0',
      boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      borderRadius:'8px',
      fontFamily:'inter'

    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      
      return {
        ...styles,
        // backgroundColor: isSelected ? "#2D81A1" : isFocused? '#B9D9EB':null,
     
      };
    }
  };
  const handleSelection = (e) => {
    
    node.setDataValue(field,e.value)
    gridRef.current.api.stopEditing()
    setCurrentSelected(e);
  };

 

 
  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={false}
      name="user_printer_editor"
      defaultValue={{value:'',label:''}}
      onChange={handleSelection}
      options={select_options}
      styles={style}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
    />
  );
};


export default XentralUsrePrinterEditor