import React,{useState} from 'react'


const CheckBox=({node,column,currentData})=>{
    
    
    
    const previous=JSON.parse(sessionStorage.getItem('colperms'))
    const is_primary=node.data.is_primary_key
    const field=column.colDef.field
    const row_name=node.data.column_name
    
    
    

    let primary_disabled=false
    let all_disabled=false

    if(is_primary && field!='read'){
        primary_disabled=true
    }
    if(field=='delete' && row_name!='*'){
        all_disabled=true
    }

    
   
    
 if(primary_disabled){
        return (
            <input
            type='checkbox'
            name={field}
            className='checkbox-renderer'
            disabled
            />
            
        )
    }
if(all_disabled){
        return (
            <input
            type='checkbox'
            className='checkbox-renderer'
            name={field}
            disabled
            />
            
        )

    }

   //changing cell data with checkbox tick
    const checkHandler=(e)=>{
   
        node.setDataValue(field,e.target.checked)
        sessionStorage.setItem('current',JSON.stringify(currentData))
    
       }
   
    return (
            <input
            type={'checkbox'}
            checked={node.data[field]}
            className='checkbox-renderer'
            name={field}
            onClick={checkHandler}
            />
            
        )

    

    
}

export default CheckBox