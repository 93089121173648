import React,{useState} from "react";
import { useDispatch } from "react-redux";
import CardBox from "../Dashboard/DashboardCard";

import {
  createApp,
  createRole,
  createUser,
  dbCred,
} from "../../styling_files/newUiStyles/icons";
import { BreadCrumbs } from "../../components/crud/crudTableComponents/breadCrumbs";
import DbCreateDialog from "../../components/crud/dbCrud/DbCreateDialog";
import { openDb,closeDb } from "../../Redux/modals/modalActions";

const SettingsView = () => {


  const dispatch=useDispatch()


  const handleDbCreate=()=>{
    dispatch(openDb())
   }

   const handleDbmodalClose=()=>{
    dispatch(closeDb())
  }
  
  return (
    <div className="dashboard-layout">
      <BreadCrumbs />

      <div class="dashboard-container">
        <div className="row">
          <div className="col-sm-12 my-1 welcome">
            <span>Settings</span>
            <span></span>
          </div>
          <div>
            <div className="dashboard-divider"></div>
          </div>
        </div>

        <DbCreateDialog 
        dbClose={handleDbmodalClose}
        />

        <div className="card-container">
          <div className="row">
            <div className="col-sm-6">
              <CardBox
                icon={createUser}
                link="Create User"
                type="settings"
                to="/dashboard/settings/users"
              />
            </div>

            <div className="col-sm-6">
              <CardBox
                icon={createRole}
                link="Create Role"
                type="settings"
                to="/dashboard/settings/roles"
              />
            </div>

            <div className="col-sm-6">
              <CardBox
                icon={dbCred}
                type="settings"
                link="View Db Credentials"
                link2="Create Db Credentials"
                handle={handleDbCreate}
                to="/dashboard/settings/dbcredentials"
              />
            </div>

            <div className="col-sm-6">
              <CardBox
                icon={createApp}
                type="settings"
                link="Create App"
                to="/dashboard/settings/apps"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsView;
