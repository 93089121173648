import React,{useEffect} from "react";
import LoginForm from "../components/users_auth/LoginForm";
import { useContext } from "react";
import { UserContext } from "../contexts/UserAuthContext";
import { http } from "../api/rootApi";


const PrivateHome=({children})=>{

    const [userLoggedIn,setUserLoggedIn]=useContext(UserContext)
    
    http.defaults.headers.common = {'Authorization': `Bearer ${userLoggedIn}`}

   
    return(

    <>
    {
      userLoggedIn ?
      children
      :
      <LoginForm/> 
    }
       
    </>
        

    )

}

export default PrivateHome