export const CLOSE_USER='modals/close_user'
export const OPEN_USER='modals/open_user'

export const OPEN_APP='modals/open_app'
export const CLOSE_APP='modals/close_app'


export const OPEN_ROLE='modals/open_role'
export const CLOSE_ROLE='modals/close_role'

export const OPEN_DB='modals/open_db'
export const CLOSE_DB='modals/close_db'

export const OPEN_ATTACH='modals/open_attach'
export const CLOSE_ATTACH='modals/close_attach'

export const ADD_ROUTE='modals/add_route'
export const REMOVE_ROUTE='modals/remove_route'

export const ADD_TEMP_ATTACH_DATA='modals/add_attach_data'

export const REMOVE_TEMP_ATTACH_DATA='modals/remove_attach_data'