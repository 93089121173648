import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateDbAction } from "../../../Redux/dbcredentials/dbActions";

import { useFormik } from "formik";
import * as Yup from "yup";


import { crossIcon,noti_info_error } from "../../../styling_files/newUiStyles/icons";
import { FormDropDown } from "../crudTableComponents/FormSelect";


const DbUpdateDialog = ({
  selectedID,
  setSelectedID,
  showModal,
  setShowModal,
}) => {
  const dispatch = useDispatch();

  const credential = useSelector((state) => state.dbcredentials.dbcredentials);
  const selectedCredential = credential
    ? credential.find((credential) => credential.id == selectedID)
    : null;

  const [formData, setFormData] = useState();

  useEffect(() => {
    setFormData(selectedCredential);
  }, [selectedCredential]);

  const data = {
    type_of_db: "",
    db_username: "",
    db_password: "",
    db_host: "",
    db_port: "",
    db_name: "",
    database_alias: "",
  };
  const validationSchema = Yup.object({
    type_of_db: Yup.string().required("required"),
    db_username: Yup.string().required("required"),
    db_password: Yup.string()
      .min(4, "Password is too short - should be 4 characters minimum."),
    db_host: Yup.string().required("required"),
    db_port: Yup.number()
      .typeError("Db port must ba a number")
      .required("required"),
    db_name: Yup.string().required("required"),
    database_alias: Yup.string().required("required"),
  });

  const onSubmit = (values) => {
   if(selectedID){
    dispatch(UpdateDbAction(values, selectedID));
   }
    
    setShowModal(false);
    
    formik.resetForm();
    setSelectedID(null)
  };

  const dataKeys = data ? Object.keys(data) : [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData || data,
    validationSchema,
    onSubmit,
  });

  if (!showModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
     <div className="overlay"></div>
      
     <div className="modelForm">
          <div className="dialog-header">
            <div className="dialog-title">Edit Database</div>
            <div className="dialog-close">
              <img src={crossIcon}
                onClick={() => {
                  
                  setShowModal(false);

                  formik.resetForm();
                }}
              />
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            className="dialog-form"
          >
            <div className="dialog-form-fields">
              {dataKeys.map((data) => {
                return (
                  <div className="form-fields">
                    <label htmlFor={data} className="input-label">
                      {data} :
                    </label>

                    {(() => {
                      switch (data) {
              
                        default:
                          return (
                            <>
                            <div className="dialog-input-container">
                            <input
                                type={
                                  data == "db_password" ? "password" : "text"
                                }
                                className="form-control my-1"
                                id={data}
                                name={data}
                                value={formik.values[data]}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={
                                  data === "db_password" ? "Set new password" : ""
                                }
                              />
                              {formik.touched[data] && formik.errors[data] && (
                                <img
                                  className="input-error-icon"
                                  src={noti_info_error}
                                  width="15px"
                                  height="15px"
                                />
                              )}

                            </div>
                              
                              <div>
                                {formik.touched[data] && formik.errors[data] ? (
                                  <div className="error">
                                    {formik.errors[data]}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                      }
                    })()}
                  </div>
                );
              })}
            </div>

            <div className="dialog-submit">
              <button type="submit" className="Btn btn-submit ">
                
              Save changes
              </button>
            </div>
          </form>
        </div>
      
    </>,
    document.getElementById("portal")
  );
};

export default DbUpdateDialog;
