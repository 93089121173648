import React, { useState } from "react";
import { ListBox, ListItem, Title, Input } from "./list.styled";
import { SearchButton } from "../../crud/Crud.styled";
import { searchIcon } from "../../../styling_files/newUiStyles/icons";

import * as BsIcons from "react-icons/bs";

const TableList = ({
  allTables,
  allowedTables,
  onCheck,
  intAlltoAllow,
  intAllowtoAll,
  migrate,
}) => {
  const [searchTermAll, setSearchTermAll] = useState("");
  const [searchTermAllowed, setSearchTermAllowed] = useState("");

 

  return (
    <div className="allowedTable-container">
      <div className="row">
        <div className="col-sm-5">
          <Title>All Tables</Title>
          <SearchButton>
            <img
              src={searchIcon}
              alt="search"
              width="15px"
              height="15px"
              className="ms-3"
            />
            <input
              type="search"
              placeholder="Search"
              onChange={(e) => setSearchTermAll(e.target.value)}
              className="mx-2 searchInput"
            ></input>
          </SearchButton>
          <ListBox>
            {allTables
              ?.sort()
              .filter((table) => !allowedTables.includes(table))
              .filter((val) => {
                if (searchTermAll == "") {
                  return val;
                } else if (
                  val.toLowerCase().includes(searchTermAll.toLowerCase())
                ) {
                  return val;
                }
              })
              .map((item) => (
                <ListItem key={Math.random()}>
                  <Input
                    type="checkbox"
                    onClick={() => onCheck(item, "all")}
                    checked={intAlltoAllow.includes(item)}
                  />
                  <span className="mx-2 font-inter">{item}</span>
                </ListItem>
              ))}
          </ListBox>
        </div>

        <div className="col-sm-2 migration">
          <div className=" migration-btn">
            <h6 className="mx-3" onClick={() => migrate("alltoallow")}>
              <BsIcons.BsArrowRight className="right_left_button" />
            </h6>
            <h6 className="mx-3" onClick={() => migrate("allowtoall")}>
              <BsIcons.BsArrowLeft className="right_left_button" />
            </h6>
          </div>
        </div>

        <div className="col-sm-5">
          <Title>Allowed Tables</Title>
          <SearchButton>
          <img
              src={searchIcon}
              alt="search"
              width="15px"
              height="15px"
              className="ms-3"
            />
            <input
              type="search"
              placeholder="Search"
              onChange={(e) => setSearchTermAllowed(e.target.value)}
              className="mx-2 searchInput"
            ></input>
          </SearchButton>
          <ListBox>
            {allowedTables
              ?.sort()
              .filter((val) => {
                if (searchTermAllowed == "") {
                  return val;
                } else if (
                  val.toLowerCase().includes(searchTermAllowed.toLowerCase())
                ) {
                  return val;
                }
              })
              .map((item) => (
                <ListItem key={Math.random()}>
                  <Input
                    type="checkbox"
                    onClick={() => onCheck(item, "allowed")}
                    checked={intAllowtoAll.includes(item)}
                  />
                  <span className="mx-2 font-inter">{item}</span>
                </ListItem>
              ))}
          </ListBox>
        </div>
      </div>
    </div>
  );
};

export default TableList;
