import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Nav = styled.div`
  background:#FFFFFF;
  height: 50px;
  display: flex;
  position:relative;
  justify-content:space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  opacity:1;
  z-index:10
 

`;
export const NavIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #515153;
 
  font-size: 1.3em;
  padding-left:20px;
  height: 50px;
  width:3rem;




 
`;

export const SidebarNav = styled.nav`
  background: #515153;
  width: 240px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 200ms;
  z-index: 100;
  overflow-y: hidden;
`;
export const SidebarWrap = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const RoleDropDown = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  height: 60px;
  font-size: 18px;
  color: #e1e9fc;
`;

export const SidebarLink = styled(NavLink)`
  display: flex;
  justify-content:space-between;
  align-items: center;
  list-style: none;
  height:2rem;
  text-decoration: none ;
  overflow-y: hidden;
  font: normal normal bold 18px/17px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding:0;
  
 
  transition: 100ms transform;
  width:194px;
  margin-left:1.8rem;

  &:hover{
    background:var(--hover_background);

    color:var(--hover-color);
    cursor: pointer;
  
  }

`;

export const SidebarLabel = styled.span`
  margin-left: 16px;
`;

export const DropDownLink = styled(NavLink)`
  height: 30px;
  width:auto;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 14px;
  

  &:hover{
    color: #ffffff;
  }

`;

export const DropDownContainer = styled.div`
  height: ${({ length }) => (length && length > 5 ? "300px" : "Auto")};
  margin-bottom: 1rem;
  width:15rem;
  
  padding: 5px;
  

  overflow-y: ${({ length }) => (length && length > 5 ? "scroll" : "hidden")};
  display: ${({ subNav }) => (subNav ? "block" : "none")};
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;



    
 
`;
export const UserName = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: wheat;
  
`;
export const LogOutDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right:17px;
  width:25%;
  height: 50px;
`;
export const LogOutButton = styled.button`
  color:red;
  font-size: 1em;
  margin: 0.5em;
  padding: 0.25em 1em;

  border-radius: 3px;
`;
