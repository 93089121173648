import React,{useState,useEffect} from 'react'
import SearchField from '../../crud/crudTableComponents/searchField';

import { useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import AttachDialog from './dialog/attachDialog';
import { DeleteSingleAppTableAction } from '../../../Redux/appPermission/appPermissionActions';



import { SetCurrentAppTableAction } from '../../../Redux/currentAppTable/actions';

//importing crud components

import AgGridPageSize from '../../crud/crudTableComponents/agGridPageSize';
import ExportAsCsv from '../../crud/crudTableComponents/exportData';
import PaginationInfo from '../../crud/crudTableComponents/pagintaiton';
import { BreadCrumbs } from '../../crud/crudTableComponents/breadCrumbs';

import { onFilterChange,
    onFilterTextChange,
    RenderState,
    handleReactPagination,
    onDragStopped } from '../../crud/tableState';
  

import { downloadIcon,tableIcon,deleteIcon,plusIcon } from '../../../styling_files/newUiStyles/icons';
import { openAttach } from '../../../Redux/modals/modalActions';

const AppTableList=(
    {AppTables,appID,appName,dbList,selectDbOptions,currentdbTableOptions,currentSelectedTable,setCurrentSelectedTable,selectDbSelected
})=>{

    const navigate=useNavigate()
    const dispatch=useDispatch()

    useEffect(() => {
        dispatch(
          SetCurrentAppTableAction({
            currentTable:'Tables',
            currentApp: appName,
          })
        );
      }, []);

    useEffect(()=>{
        let column_state = JSON.parse(localStorage.getItem(`${table}_column_state`));
        gridColumnApi?.applyColumnState({state:column_state, applyOrder: true});
    
      },)

    const table=`${appName}_TableList`

    const totalPageSaved = localStorage.getItem(`${table}_totalPage`);
    let [pageInfo,setPageInfo]=useState({currentPage:0,totalPage:totalPageSaved||1})

    let [quickFilterText, setQuickFilterText] = useState(null);

    let [pageSize,setPageSize]=useState(null)



    let [selectedID,setSelectedID]=useState({tableID:'',dbID:'',table_name:''})
    let [dbId,setDbId]=useState(null)
    


    //states for modal
    const [showAttach,setShowAttach]=useState(true)



    //grid api states
    const [gridApi,setGridApi]=useState(null)
    const [gridColumnApi,setGridColumnApi]=useState(null)

    

    //on first time data rendering
    const onFirstDataRendered = (params) => {
     
        RenderState(params, table, setQuickFilterText, setPageSize);
      };
    


    //grid Ready
    const onGridReady=(params)=>{

        RenderState(params, table, setQuickFilterText, setPageSize);
        
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
    }


    
    
    

    //handling selection of row

    const onSelectionChanged=(e)=>{
        const [row]=e.api.getSelectedRows()
    
        const dbid=row?.db_credential_id
        
        const selectedid=row?.id


        const table_name=row?.table_name
        
        
        
        setSelectedID({tableID:selectedid,dbID:dbid,table_name:table_name})
        
    
    }

        

    const columnDefsData = [
        { headerName:'Table ID',field: 'id' ,checkboxSelection:true,filter:"agNumberColumnFilter"},
        { headerName:'Db Alias',field:'db_alias',filter:'agTextColumnFilter'},
        { headerName:'Table Name',field: 'table_name',filter:'agTextColumnFilter'},
        { headerName:'Db Credentials ID',field:'db_credential_id',filter:"agNumberColumnFilter" }
    ];


    const defaultColDefs={
        minWidth:200,
        headerClass:'header-class',
        cellClass:'cell-class',
        flex:1,
        sortable:true,
        resizable:true,
        icons: {
            sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
            sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
        }
    
    }

    //functions to show modals

    const toggleshowAttach=()=>{
       dispatch(openAttach())



    }
    const toTablePermissionGrid=()=>{
    navigate(`${selectedID.dbID}/${selectedID.table_name}/${selectedID.tableID}`)
    }

    const handleDeleteTable=()=>{
        dispatch(DeleteSingleAppTableAction(appID,selectedID.tableID))
        setSelectedID({})
    }

    //page size

    const onPageSizeChange=(pgSize)=>{
        

        gridApi.paginationSetPageSize(Number(pgSize));
        setPageSize(pgSize);
        localStorage.setItem(`${table}_currentPageSize`, JSON.stringify(pgSize));
    
        //current page state change
    
        handleReactPagination(gridApi, pageInfo, setPageInfo, table);

    }
        
    //Exporting

    const onExportasCSV=()=>{
        gridApi.exportDataAsCsv()

    }

    

 

    return (
        <>
         <BreadCrumbs />

         <div className="title-layout">
        <div className="title-container">
          <div className="current-app-table">
            <p>Attatched Table</p>
          </div>
        </div>


        </div>
        <div className='actions-layout'>

        <div className="actions-container-backdrop">
        <div className="actions-container">
        <div className="searchfield-container">
        <span className="button-label">Search Data</span>
        <SearchField
                quickFilterText={quickFilterText}
                setQuickFilterText={setQuickFilterText}
                onFilterTextChange={onFilterTextChange}
                gridApi={gridApi}
                table={table}
              />

        </div>
        <div className="pagesize-container">
              <span className="button-label">Rows</span>

              <AgGridPageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
        </div>
        <div className="line-seperator-container">
              <div className="line-seperator"></div>
        </div>

        <div className="button-container">
        <span className="button-label"></span>

        {/* export button */}
        <button className="Btn DownloadBtn " onClick={onExportasCSV}>
                <div>
                  <img
                    className="mx-1 mb-1"
                    src={downloadIcon}
                    alt="csv"
                    width="17px"
                    height="17px"
                  />
                  <span className="mx-1">Export CSV</span>
                </div>
        </button>

        </div>
        <div className="line-seperator-container">
              <div className="line-seperator"></div>
        </div>
        {
           selectedID.tableID?
            <>
            {/* table permissions */}
            <div className="button-container">
                  <span className="button-label"></span>
                  <button className="Btn updateBtn "  onClick={toTablePermissionGrid}>
                    <div>
                      <img
                        className="mx-1 mb-1"
                        src={tableIcon}
                        alt="attach-tables"
                        width="14px"
                        height="14px"
                      />

                      <span className="mx-1">Permission</span>
                    </div>
                  </button>
            </div>

            {/* delete */}
            <div className="button-container">
                  <span className="button-label"></span>
                  <button className="Btn deleteBtn" onClick={handleDeleteTable}>
                    <div>
                      <img
                        className="mx-1 mb-1"
                        src={deleteIcon}
                        alt="delete"
                        width="15px"
                        height="15px"
                      />
                      <span className="mx-1">Delete</span>
                    </div>
                  </button>
                </div>
            </>
            :
            <div className="button-container">
                <span className="button-label"></span>
                <button className="Btn createBtn" onClick={toggleshowAttach}>
                  <div>
                    <img
                      className="mx-1 mb-1"
                      src={plusIcon}
                      alt="new"
                      width="14px"
                      height="14px"
                      title="New"
                    />
                  </div>
                  <span className="mx-1">Attach</span>
                </button>
              </div>
        }

        </div>

        </div>

        </div>

        <div>
                <AttachDialog 

                    showAttach={showAttach}
                    setShowAttach={setShowAttach}
                    dbList={dbList}
                    selectDbOptions={selectDbOptions}
                    currentdbTableOptions={currentdbTableOptions}
                    currentSelectedTable={currentSelectedTable}
                    setCurrentSelectedTable={setCurrentSelectedTable}
                    selectDbSelected={selectDbSelected}
                    appID={appID}
                />

            </div>

        <div className="table-layout">
        <div className="table-container">
        <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%" }}
          >
               <AgGridReact
                            
                            rowData={AppTables}
                            columnDefs={columnDefsData}
                            rowSelection='single'
                            onSelectionChanged={onSelectionChanged}
                            defaultColDef={defaultColDefs}
                            onGridReady={onGridReady}

                            alwaysShowHorizontalScroll={true}
                            alwaysShowVerticalScroll={true}

                            headerHeight={30}
                            rowHeight={40}
                            pagination={true}
                            suppressPaginationPanel={true}
                            paginationPageSize={pageSize}
                            suppressDragLeaveHidesColumns={true}

                            onFilterChanged={(params) => onFilterChange(params, table)}
                            onFirstDataRendered={(params)=>onFirstDataRendered(params)}

                            onDragStopped={(params) => onDragStopped(params, table)}
                            onSortChanged={(params) => onDragStopped(params, table)}
                            >
                        </AgGridReact>
        </div>

        </div>
        <PaginationInfo
                pageInfo={pageInfo}
                setPageInfo={setPageInfo}
                gridApi={gridApi}
             />

        </div>
        
        </>
    )
}


export default AppTableList