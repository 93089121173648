import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch,useSelector} from "react-redux";
import { PostDbAction } from "../../../Redux/dbcredentials/dbActions";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import LabelFormatter from "../crudTableComponents/labelFormatter";
import {
  crossIcon,
  noti_info_error,
} from "../../../styling_files/newUiStyles/icons";

const DbCreateDialog = ({dbClose}) => {
  
  const dispatch = useDispatch();
  const dbModalOpen=useSelector(state=>state.modals.dbModalOpen)


  const data = {
    type_of_db: "",
    db_username: "",
    db_password: "",
    db_host: "",
    db_port: "",
    db_name: "",
    database_alias: "",
  };

  const onSubmit = (values) => {
   
    dispatch(PostDbAction(values));
    dbClose()
    formik.resetForm();
  };

  const validationSchema = Yup.object({
    type_of_db: Yup.string().required("required"),
    db_username: Yup.string().required("required"),
    db_password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 characters minimum."),
    db_host: Yup.string().required("required"),
    db_port: Yup.number()
      .typeError("Db port must ba a number")
      .required("required"),
    db_name: Yup.string().required("required"),
    database_alias: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: data,
    onSubmit,
    validationSchema,
  });

  const dataKeys = data ? Object.keys(data) : [];

  if (!dbModalOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>

      <div className="modelForm">
        <div className="dialog-header">
          <div className="dialog-title">Create Db Credentials</div>
          <div className="dialog-close bc-clickable">
            <img
              src={crossIcon}
              onClick={() => {
                dbClose()
                formik.resetForm();
              }}
            />
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
          className="dialog-form"
          autoComplete="off"
        >
          <div className="dialog-form-fields">
            {dataKeys.map((data) => {
              return (
                <div className="form-fields">
                  <label htmlFor={data} className="input-label">
                    {LabelFormatter(data)}
                  </label>

                  {(() => {
                    switch (data) {
                      default:
                        return (
                          <>
                          
                            <div className="dialog-input-container">
                              <input
                                type={
                                  data == "db_password" ? "password" : "text"
                                }
                                autoComplete="new-password"
                                className="form-control my-1"
                                id={data}
                                name={data}
                                value={formik.values[data]}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={`Enter ${data}`}
                              />
                              {formik.touched[data] && formik.errors[data] && (
                                <img
                                  className="input-error-icon"
                                  src={noti_info_error}
                                  width="15px"
                                  height="15px"
                                />
                              )}
                            </div>

                            <div>
                              {formik.touched[data] && formik.errors[data] ? (
                                <div className="error">
                                  {formik.errors[data]}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                    }
                  })()}
                </div>
              );
            })}
          </div>

          <div className="dialog-submit">
            <button type="submit" className="Btn btn-submit">
              Save changes
            </button>
          </div>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default DbCreateDialog;
