import React from "react";
import { noti_success_icon,crossIconSuccess,corssIconError,noti_info_error,infoIcon,crossIconNotification } from "../../styling_files/newUiStyles/icons";


export const SuccessAlertToast=({message,closeToast})=>{
    return (
        <div className="custom-toast-container success-toast">
            <span className="noti-item">
                <img  
                    src={noti_success_icon}
                    width='18px'
                    height='18px'
                />
            </span>
            <span className="noti-msg noti-item">
                {message} 
            </span>

            <span className="bc-clickable">
                <img  
                    src={crossIconSuccess}
                    onClick={closeToast}
                    width='11px'
                    height='11px'
                />
            </span>

        </div>
    )
}

export const InfoAlertToast=({message,closeToast})=>{

    return (
        <div className="custom-toast-container info-toast">
            <span>
                <img  
                    src={infoIcon}
                    width='18px'
                    height='18px'
                />
            </span>
            <span className="noti-msg">
                {message} 
            </span>

            <span className="bc-clickable">
                <img  
                    src={crossIconNotification}
                    onClick={closeToast}
                    width='11px'
                    height='11px'
                />
            </span>

        </div>
    )

    
}

export const ErrorAlertToast=({message,closeToast})=>{

    return (
        <div className="custom-toast-container error-toast">
            <span >
                <img  
                    src={noti_info_error}
                    width='18px'
                    height='18px'
                />
            </span>
            <span className="noti-msg">
                {message} 
               
            </span>

            <span className="bc-clickable">
                <img  
                    src={corssIconError}
                    onClick={closeToast}
                    width='11px'
                    height='11px'
                />
            </span>

        </div>
    )

}