import React,{useState,useEffect} from "react";
import ReactDOM from 'react-dom'


import validationSchemaMapper from "../../MasterDataCrud/validationSchemaMapper";
import {  useFormik } from "formik";

import { useDispatch,useSelector} from "react-redux";
import {FormDropDown} from '../../crudTableComponents/FormSelect'

import { crossIcon } from "../../../../styling_files/newUiStyles/icons";




const LeitStandCreateDialog = ({showModal,setShowModal,
    createDiologueInputFields,onCreateRow}) => {
 

  const inputFieldsValues = {...createDiologueInputFields.values};
  const inputFieldsTypes = {...createDiologueInputFields.types};
  const inputFieldIsNullable={...createDiologueInputFields.nullable}

  const inputFieldHasDefault={...createDiologueInputFields.has_default}

  //replace null values with ''

  const formikValues=Object.keys(inputFieldsValues).reduce((acc, key) => {
    acc[key] = inputFieldsValues[key] === null ?
      '' 
      : 
      inputFieldsValues[key]
    return acc }, {})

  
    const xentral_users=useSelector(state=>state.xentral.users)
    const xentral_printers=useSelector(state=>state.xentral.printers)

     //options for select component

  const user_options= xentral_users?.map((value) => {
    return { value: value, label: value };
  });

  const printer_options=xentral_printers?.map((value) => {
    return { value: value, label: value };
  });


 

  const [formikData,setFormikData]=useState(formikValues)




//create api: pass from props
  const onSubmit = (values) => {

    formik.resetForm()
    onCreateRow(values)
    
    setShowModal(false)
   
    
  };


 

  const formik = useFormik({
    
    initialValues:formikData,
    enableReinitialize:true,
    onSubmit,
    validationSchema:formikValues?validationSchemaMapper(inputFieldsTypes,inputFieldIsNullable,inputFieldHasDefault):null
  
   
  });


  const inputFieldNames = inputFieldsValues ? Object.keys(inputFieldsValues) : [];
  


 
  if(!showModal){
    return null
  }

  
  return ReactDOM.createPortal(
    <>
    <div className="overlay">

      
    
    <div className="modelForm">
          <div className="dialog-header">
            <div className="dialog-title">Create</div>
            <div className="dialog-close">
              <img
                src={crossIcon}
                onClick={() => {
                  
                  setShowModal(false);

                  formik.resetForm();
                }}
              />
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            className="dialog-form"
          >
            <div className="dialog-form-fields">
              {inputFieldNames.map((data) => {
                return (
                  <div className="form-fields">
                    <label htmlFor={data} className="input-label">
                      {data} :
                    </label>

                    {(() => {
                      switch (data) {

                        case 'Username':
                          return (

                            <FormDropDown
                              name="username"
                              isMulti={false}

                              onChangeHandler={value => {

                                formik.setFieldValue('Username', value.value)
                              }}
                              select_options={user_options}

                            />
                            
                          );
                          case 'Druckerkennung':
                          return (
                            <FormDropDown
                              name="Druckerkennung"
                              isMulti={false}

                              onChangeHandler={value => {

                                formik.setFieldValue("Druckerkennung", value.value)
                              }}
                              select_options={printer_options}

                            />
                            
                          );

                          case 'Export':
                          return (
                            <FormDropDown
                            name="Export"
                            isMulti={false}

                            onChangeHandler={value => {

                              formik.setFieldValue("Export", value.value)
                            }}
                            select_options={printer_options}

                          />
                           
                          );
                       
                        default:
                          return (
                            <>
                            <div className="dialog-input-container">
                              <input
                                type={inputFieldsTypes[data]}
                                className="form-control my-1"
                                placeholder={`Enter ${data}`}
                                
                                id={data}
                                name={data}
                                value={formik.values[data]}
                                onBlur={formik.handleBlur}
                                onChange={e=>formik.setFieldValue(data,e.target.value)}
                                
                                
                              />
                              <div>
                                {formik.touched[data] && formik.errors[data] ? (
                                  <div className="error">
                                    {formik.errors[data]}
                                  </div>
                                ) : null}
                              </div>
                              </div>
                            </>
                          );
                      }
                    })()}
                  </div>
                );
              })}
            </div>

            <div className="dialog-submit">
            <button type="subit" className="Btn btn-submit">
              Save changes
            </button>
            </div>
          </form>
    </div>
    </div>
   
    </>,
    document.getElementById('portal')
  );
};

export default LeitStandCreateDialog;
