import React, { useState,useEffect,useRef } from "react";
import * as RiIcons from "react-icons/ri";



function ExportAsCsv({ onExportChange }) {

  const buttonRef=useRef()
  const [showDropdown, setShowDropDown] = useState(false);

  const handleOptionClick = () => {
    onExportChange();
    setShowDropDown(false);
  };


  return (
    <div className="dropdown-container mx-2" >
      <div
        className="dropdown-header"
        onClick={() => setShowDropDown(!showDropdown)}
      >
        <div className="dropdown-title">Export</div>
        <div>
          {showDropdown ? (
            <RiIcons.RiArrowUpSFill className="arrowIcon" />
          ) : (
            <RiIcons.RiArrowDownSFill className="arrowIcon" />
          )}
        </div>
      </div>
      {showDropdown && (
        <div className="dropdown-list ">
          <button className="dropdown-item " onClick={handleOptionClick}>
            <span className="option">CSV</span>
          </button>
          {/* <button className="dropdown-item" o nClick={handleOptionClick}>
            <span className="option">PDF</span>
          </button> */}
        </div>
      )}
    </div>
  );
}

export default ExportAsCsv;
