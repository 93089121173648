import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import validationSchemaMapper from "../MasterDataCrud/validationSchemaMapper";
import { useFormik } from "formik";

import { crossIcon,noti_info_error } from "../../../styling_files/newUiStyles/icons";

const MasterDataCreateDialog = ({
  showModal,
  setShowModal,
  createDiologueInputFields,
  onCreateRow,
}) => {
  const inputFieldsValues = { ...createDiologueInputFields.values };
  const inputFieldsTypes = { ...createDiologueInputFields.types };
  const inputFieldIsNullable = { ...createDiologueInputFields.nullable };

  const inputFieldHasDefault = { ...createDiologueInputFields.has_default };

  //replace null values with ''

  const formikValues = Object.keys(inputFieldsValues).reduce((acc, key) => {
    acc[key] = inputFieldsValues[key] === null ? "" : inputFieldsValues[key];
    return acc;
  }, {});


  //handling date time values
  const handleDateTimeChange=(e)=>{
    const dt_with_Seconds=e.target.value+':00'

    

      formik.handleChange(e)
      formik.setFieldValue(e.target.name,dt_with_Seconds);

    
   

  }
  //create api: pass from props
  const onSubmit = (values) => {
    //adding seconds to the date time value

    onCreateRow(values);

    setShowModal(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: formikValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema: formikValues
      ? validationSchemaMapper(
          inputFieldsTypes,
          inputFieldIsNullable,
          inputFieldHasDefault
        )
      : null,
  });

  const inputFieldNames = inputFieldsValues
    ? Object.keys(inputFieldsValues)
    : [];

  if (!showModal) {
    return null;
  }

 

  return ReactDOM.createPortal(
    <>
      <div className="overlay">
        <div className="modelForm">
          <div className="dialog-header ">
            <div className="dialog-title">Create</div>
            <div className="dialog-close">
              <img
                src={crossIcon}
                onClick={() => {
                  setShowModal(false);

                  formik.resetForm();
                }}
              />
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            className="dialog-form"
          >
            <div className="dialog-form-fields">
              {inputFieldNames.map((data) => {
                return (
                  <div className="form-fields">
                    <label htmlFor={data} className="input-label">
                      {data} :
                    </label>

                    {(() => {
                      switch (data) {
                        default:
                          return (
                            <>
                              <div className="dialog-input-container">
                                <input
                                  type={inputFieldsTypes[data]}
                                  className="form-control my-1"
                                  placeholder={`Enter ${data}`}
                                  id={data}
                                  name={data}
                                  value={formik.values[data]}
                                  onBlur={formik.handleBlur}
                                  onChange={inputFieldsTypes[data]==='datetime-local'?handleDateTimeChange:formik.handleChange}
                                />
                                {formik.touched[data] &&
                                  formik.errors[data] && (
                                    <img
                                      className="input-error-icon"
                                      src={noti_info_error}
                                      width="15px"
                                      height="15px"
                                    />
                                  )}
                              </div>
                              <div>
                                {formik.touched[data] && formik.errors[data] ? (
                                  <div className="error">
                                    {formik.errors[data]}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          );
                      }
                    })()}
                  </div>
                );
              })}
            </div>

            <div className="dialog-submit">
              <button type="submit" className="Btn btn-submit">
                
              Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default MasterDataCreateDialog;
