import React from "react";
import {NavLink,Link} from 'react-router-dom'

import { rightArrowLink } from '../../styling_files/newUiStyles/icons'


const arrowStyle={color: '#0035A6',size:'50px',fontWeight:'600'}
const linkStyle= {textDecoration: 'none',color:'#0035A6',fontWeight:'600',fontSize:'1rem'}


const CardBox=({icon,title,app_type,handleSidebar,description,link,link2,handle,type,to})=>{


    
   
    return (
        <div class={type==='settings'?'settings-card':'Card'}>
            
            <span>
            <div className='icon'><img src={icon} alt='icon' width='18px' height='18px'/></div>

            {
                title ?
                <span>
                <span className='title'>{title?title:''}</span>
                </span>
                :
                null
            }
            
            </span>

           

          
           
            
            <span>

            {
            description ?
            <span>
            <span className="description">{description?description:''}</span>
            </span>
            :null


           }
          
            <Link  
                style={linkStyle} to={to}
                onClick={app_type?()=>{handleSidebar(app_type)}:''}
            >
                <span class='link-label'>{link}</span>
                <img src={rightArrowLink} alt='link' className="mx-2" width='14px' height='14px' />
                
            </Link>

            {
                link2 ?
                <Link  style={linkStyle} className='link2' to={to} onClick={handle}>
                <span className='link-label'  >{link2}</span>
                <img src={rightArrowLink} alt='link' className="mx-2" width='14px' height='14px' />
                
               </Link>
                :
                null
            }
          
          </span>
            
            
           

        </div>
    )
}

export default CardBox