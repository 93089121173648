import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetRoleForUserMeAction } from "../../../Redux/sidebar/roleForUser/RoleForUserActions";
import { GetAppForRolesAction } from "../../../Redux/sidebar/appForRole/appForRoleAction";
import { GetCurrentUserAction } from "../../../Redux/userProfile/userProfileActions";

import {
  company_logo,
  homeIcon,
  jobrunnerIcon,
  customIcon,
  dataflowIcon,
  settingsIcon,
  userIcon,
} from "../../../styling_files/newUiStyles/icons";

import { SidebarIcon, SettingsIcon } from "./sidebarIconComponent";
import Avatar from "./userAvatar";
import SidebarAppList from "./sidebarApplist";
import UserInfo from "./userInfo";
import ApptypeTitle from "./apptypeComponent";

// importing actions
import { OpenCloseSidebar,SetSelectedAppType } from "../../../Redux/sidebar/sidebarControl/sidebarControlActions";

const SidebarView = () => {
    
  const dispatch = useDispatch();

  const show_sidebar=useSelector(state=>state.sidebarControl.show_sidebar)
  const selected_type=useSelector(state=>state.sidebarControl.selected_type)

  const currentUser = useSelector((state) => state.CurrentUser.CurrentUser);

  const roleForUser = useSelector((state) => state.roleForUser.roleForUserMe);

  const apps_for_user = useSelector((state) => state.appsForRoles.appsForRoles);

  const app_types = apps_for_user.map((item) => item.application_type);


  //getting current user:

  useEffect(() => {
    if(!currentUser){
      dispatch(GetCurrentUserAction());
    }
   
  }, []);

  //getting roles for current user

  useEffect(() => {
    if (currentUser) {
      dispatch(GetRoleForUserMeAction(currentUser.id));
    }
  }, [currentUser]);

  //getting all apps for the user
  useEffect(() => {
    if (roleForUser) {
      dispatch(GetAppForRolesAction(roleForUser));
    }
  }, [roleForUser]);



  const handleSidebar = (value=null) => {
    dispatch(OpenCloseSidebar(value))
  };

  return (
    <>
      <div className="sidebar-icons">
        <div className="sidebar-icons-container">
          <div className="upper-icons-container">
            {/* company logo */}

            <div className="nav-logo">
              <img src={company_logo} alt="company logo" />
            </div>

            {/* icons */}

            <SidebarIcon
              icon_name={homeIcon}
             
              selectedType={selected_type}
              handleSidebar={handleSidebar}
              navigateTo={"/"}
              type="home"
            />
            {app_types.includes("crud") && (
              <SidebarIcon
                icon_name={dataflowIcon}
                selectedType={selected_type}
                handleSidebar={handleSidebar}
                type="crud"
              />
            )}

            {app_types.includes("airflow") && (
              <SidebarIcon
                icon_name={jobrunnerIcon}
                selectedType={selected_type}
                handleSidebar={handleSidebar}
                type="airflow"
              />
            )}

            {app_types.includes("custom") && (
              <SidebarIcon
                icon_name={customIcon}
                selectedType={selected_type}
                handleSidebar={handleSidebar}
                type="custom"
              />
            )}
          </div>

         

          <div className="lower-icons-container">
            <div className="settings-icon-container">
              <SettingsIcon
                icon_name={userIcon}
                selectedType={selected_type}
                handleSidebar={handleSidebar}
                navigateTo={"/profile"}
                type="user"
              />

              {/* only available to admin user */}
              {
                currentUser?.is_superuser &&
                <SettingsIcon
                icon_name={settingsIcon}
                selectedType={selected_type}
                handleSidebar={handleSidebar}
                navigateTo={"/dashboard/settings"}
                type="settings"
              />
              }
              

              <Avatar
              selectedType={selected_type}
              handleSidebar={handleSidebar}
                type="avatar"
              />
            </div>
          </div>
        </div>
      </div>

      {/* sidebar */}
      <div className={show_sidebar ? "sidebar-layout" : "sidebar-hidden"}>
        <div className="sidebar-container">
          {/* app title container */}

          {show_sidebar && (
            <div className="apptype-title-container">
              <ApptypeTitle application_type={selected_type} />
            </div>
          )}

          {/* applist and child table list here */}
          {show_sidebar && <SidebarAppList selectedType={selected_type} />}
          {show_sidebar && <UserInfo />}
        </div>
      </div>
    </>
  );
};

export default SidebarView;
