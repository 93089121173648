import { GET_TABLE_METADATA_ACTION } from "./masterdataActionTypes";
import { GET_TABLE_ALL_COLUMNS_METADATA_ACTION } from "./masterdataActionTypes";
import { GET_TABLE_ROWS_DATA_ACTION } from "./masterdataActionTypes";
import { POST_TABLE_ROW_DATA_ACTION } from "./masterdataActionTypes";
import { GET_TABLE_CREATED_SINGLE_ROW_DATA_ACTION } from "./masterdataActionTypes";
import { UPDATE_TABLE_ROW_DATA_ACTION } from "./masterdataActionTypes";
import { GET_TABLE_UPDATED_ROW_DATA_ACTION } from "./masterdataActionTypes";
import { DELETE_TABLE_ROW_DATA_ACTION,CLEAR_UPDATED_PK_ID,CLEAR_CREATED_PK_ID,CLEAR_MASTERDATA_STATE} from "./masterdataActionTypes";



import { GetTableMetaData } from "../../api/masterdataCrudApi";
import { GetTableAllColumnsMetaData } from "../../api/masterdataCrudApi";
import { GetTableRowsData } from "../../api/masterdataCrudApi";
import { PostTableRowData } from "../../api/masterdataCrudApi";
import { GetTableSingleRowData } from "../../api/masterdataCrudApi";
import { UpdateTableRowData } from "../../api/masterdataCrudApi";
import { DeleteTableRowData } from "../../api/masterdataCrudApi";
import { errorHandler } from "../../api/errorHandler";
import { SuccessAlert } from "../../components/notifications/notifications";
import { undoCellEdit } from "../../components/crud/tableState";

const GetTableMetaDataAction = (appID, allowedTableID) => {
  return async function (dispatch) {
    return GetTableMetaData(appID, allowedTableID).then((response) => {
      dispatch({
        type: GET_TABLE_METADATA_ACTION,
        payload: response.data,
      });
    })
    .catch(error=>errorHandler(error))
  };
};

const GetTableAllColumnsMetaDataAction = (appID, allowedTableID) => {
  return async function (dispatch) {
    return GetTableAllColumnsMetaData(appID, allowedTableID)
      .then((response) => {
        dispatch({
          type: GET_TABLE_ALL_COLUMNS_METADATA_ACTION,
          payload: response.data,
        });
      })
      .catch(error=>errorHandler(error))
      
  };
};

const GetTableRowsDataAction = (appID, allowedTableID) => {
  return async function (dispatch) {
    return GetTableRowsData(appID, allowedTableID)
      .then((response) => {
        dispatch({
          type: GET_TABLE_ROWS_DATA_ACTION,
          payload: response.data,
        });
      })
      .catch(error=>errorHandler(error))
  };
};

const GetTableSingleRowDataAction = (appID, allowedTableID, rowID) => {

  
 
  return async function (dispatch) {
    return GetTableSingleRowData(appID, allowedTableID, rowID)
      .then((response) => {
       
        dispatch({
          type: GET_TABLE_CREATED_SINGLE_ROW_DATA_ACTION,
          payload: response.data,
        });

        dispatch(ClearCreatedPkId())
      })
      .catch(error=>{
        errorHandler(error)
        })
  };
};

const PostTableRowDataAction = (appID, allowedTableID, requestBody) => {
  return async function (dispatch) {
    return PostTableRowData(appID, allowedTableID, requestBody)
    .then(
      (response) => {
        dispatch({
          type: POST_TABLE_ROW_DATA_ACTION,
          payload: response.data,
        });
        SuccessAlert('Row created')
      }
    )
    .catch(error=>{
        errorHandler(error)
        })
  };
};

const UpdateTableRowDataAction = (
  appID,
  allowedTableID,
  rowID,
  requestBody,
  gridRef
) => {

  
 
  return async function (dispatch) {
    return UpdateTableRowData(appID, allowedTableID, rowID, requestBody)
    .then(
      (response) => {
        
        dispatch({
          type: UPDATE_TABLE_ROW_DATA_ACTION,
          payload: response.data,
        });
        SuccessAlert('Updated')
      }
    )
    .catch(error=>{
        errorHandler(error)
        undoCellEdit(gridRef)
        
      })
  };
};

const GetTableUpdatedRowDataAction = (
  appID,
  allowedTableID,
  rowID,
  primary_key_name
) => {
  return async function (dispatch) {
    return GetTableSingleRowData(appID, allowedTableID, rowID)
      .then((response) => {
        dispatch({
          type: GET_TABLE_UPDATED_ROW_DATA_ACTION,
          payload: { row_data: response.data, pkey: primary_key_name },
        });
        dispatch(ClearUpdatedRowPkId())
       
        
      })
      .catch(error=>{
        errorHandler(error)
       
      })
  };
};

const DeleteTableRowDataAction = (
  appID,
  allowedTableID,
  rowID,
  primary_key_name
) => {
  return async function (dispatch) {
    return DeleteTableRowData(appID, allowedTableID, rowID).then((response) => {
      dispatch({
        type: DELETE_TABLE_ROW_DATA_ACTION,
        payload: { pkey_value: rowID, pkey_name: primary_key_name },
      });
    })
    .catch(error=>{
        errorHandler(error)
        
      })
  };
};

const ClearUpdatedRowPkId=()=>{

  return {
    type:CLEAR_UPDATED_PK_ID
  }
  
}

const ClearCreatedPkId=()=>{
  return {
    type:CLEAR_CREATED_PK_ID
  }
}

const ClearMasterdata=()=>{
  return {
    type:CLEAR_MASTERDATA_STATE
  }
}

export {
  GetTableMetaDataAction,
  GetTableAllColumnsMetaDataAction,
  GetTableRowsDataAction,
  PostTableRowDataAction,
  GetTableSingleRowDataAction,
  UpdateTableRowDataAction,
  GetTableUpdatedRowDataAction,
  DeleteTableRowDataAction,
  ClearMasterdata
};
