import { findColumnType } from "./findColumnType"

const Filter=(data_type)=>{

const column_type=findColumnType(data_type)



switch(column_type){
    case 'text':
        return {filter:'agTextColumnFilter',  filterParams:{}}
    case 'number':
        return {filter:"agNumberColumnFilter",  filterParams:{}}
     
    case 'date':
        return {
            filter: "agDateColumnFilter",
            filterParams: {
                comparator: function(filterLocalDateAtMidnight, cellValue) {
                   if (cellValue === null) return -1;
                   let cellDate = new Date(cellValue);
                   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                     return 0;
                   }
                  if (cellDate < filterLocalDateAtMidnight) {
                      return -1;
                  }
                 if (cellDate > filterLocalDateAtMidnight) {
                     return 1;
                 }
              },
               browserDatePicker: true
            }
           
        }
    case 'datetime-local':
        return {
            filter: "agDateColumnFilter",
            filterParams: {
                comparator: function(filterLocalDateAtMidnight, cellValue) {
                   if (cellValue === null) return -1;
                   let cellDate = new Date(cellValue);
                   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                     return 0;
                   }
                  if (cellDate < filterLocalDateAtMidnight) {
                      return -1;
                  }
                 if (cellDate > filterLocalDateAtMidnight) {
                     return 1;
                 }
              },
               browserDatePicker: true
            }
           
        }

    default:
        return {filter:'agTextColumnFilter',  filterParams:{}}

}
}

export default Filter