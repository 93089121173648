import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";

import ReactDOM from "react-dom";
import { SaveTableForAppAction } from "../../../../Redux/appPermission/appPermissionActions";
import { FormDropDown } from "../../../crud/crudTableComponents/FormSelect";
import {closeAttach,removeRoute,AddTempAtachData,addRoute} from '../../../../Redux/modals/modalActions'
import { left_arrow,right_arrow } from "../../../../styling_files/newUiStyles/icons";


import * as AiIcons from "react-icons/ai";

const AttachDialog = ({
 
  dbList,
  selectDbOptions,
  currentdbTableOptions,
  appID,

  currentSelectedTable,
  setCurrentSelectedTable,
  selectDbSelected,
}) => {

  const dispatch=useDispatch()
  const navigate=useNavigate()
  

  const attachModalOpen=useSelector(state=>state.modals.attachModalOpen)
  const modal_routes=useSelector(state=>state.modals.modal_routes)

  //previous-current-next routes

  const current_modal=useLocation().pathname
  const previous_modal=modal_routes[modal_routes.indexOf(current_modal)-1]
  const next_modal=modal_routes[modal_routes.indexOf(current_modal)+1]
  


  const data = {
    database: "",
    tables: "",
  };

  const dbList_options=dbList.map(db=>({value:db,label:db}))

  let dropdownValues=[]

  const dataKeys = data ? Object.keys(data) : [];

  const [currentSelected,setCurrentSelected]=useState()
  const [currentDB,setCurrentDb]=useState()

  useEffect(()=>{

    if(modal_routes.length===0){
      setCurrentSelected(currentSelectedTable)

    }
    

  },[currentSelectedTable])


  //handling db change

  const handleDbchange=(e)=>{

    selectDbOptions(e.value);
    selectDbSelected(e.value);
    setCurrentDb(e);

  }

  //handling dialog close

  const handleClose=()=>{

    if(modal_routes.length>0){

      if(modal_routes.length===1 && modal_routes[0]===current_modal){
        dispatch(closeAttach());
        dispatch(removeRoute(current_modal))
        setCurrentSelected([])
        setCurrentDb(null)

      }
      else{
        navigate(previous_modal?previous_modal:current_modal)
        dispatch(removeRoute(current_modal))
        dispatch(closeAttach());
        setCurrentSelected([])
        setCurrentDb(null)
      }
     
    }
    else{
      dispatch(closeAttach());
      dispatch(removeRoute(current_modal))
      setCurrentSelected([])
      setCurrentDb(null)

    }

  }

  //handling submission

  const handleSubmit = (e) => {

    e.preventDefault()
    
    if(modal_routes.length>0){

      if(modal_routes.length===1 && modal_routes[0]===current_modal){
        dispatch(SaveTableForAppAction(appID,currentSelectedTable,currentSelected))
        setCurrentSelectedTable(dropdownValues)
        setCurrentDb(null)
        dispatch(closeAttach())
        dispatch(removeRoute(current_modal))

      }
      else{
        navigate(previous_modal)
        dispatch(removeRoute(current_modal))
  
        //during creation: no app id,store in redux store initially 
  
        dispatch(AddTempAtachData(currentSelected))
      
        setCurrentSelected([])
        setCurrentDb(null)
        dispatch(closeAttach())
        
        
      }
   

    }
    else{
      //send to api with app id
      

      dispatch(SaveTableForAppAction(appID,currentSelectedTable,currentSelected))
      setCurrentSelectedTable(dropdownValues)
      setCurrentDb(null)
      dispatch(closeAttach())
      dispatch(removeRoute(current_modal))

    }
    
   
  };
  //handling select options

  const handleSelect = (e) => {
  
    dropdownValues = e.map((item) => item);  
    setCurrentSelected(dropdownValues);   
  };

  if (!attachModalOpen) {
    return null;
  }





  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>
     
      <div className="modelForm">
          <div className="dialog-header">
            <div className="dialog-title">Attach Table To App</div>
            <div className="dialog-close">
              <AiIcons.AiOutlineClose
                style={{ color: "#333", fontSize: "18px" }}
               
                onClick={handleClose}
              />
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e)
              
            }}
            className="dialog-form"
          >
            <div className="dialog-form-fields">
              {dataKeys.map((data) => {
                return (
                  <div className="form-fields">
                    <label htmlFor={data} className="input-label">
                      {data} :
                    </label>

                    {(() => {
                      switch (data) {

                        case "tables":
                          return (
                            <FormDropDown
                              
                            isMulti={true}
                            value={currentSelected}
                            onChangeHandler={handleSelect}
                            select_options={currentDB?currentdbTableOptions[0]:''}
                            select_name="tables"
                            menuPlacement='top'
                            portalTarget={document.body}
                            
                           
                            
                          
                            />
                          );

                        case "database":
                            return (
                              <FormDropDown
                              isMulti={false}
                              value={currentDB}
                              onChangeHandler={handleDbchange}
                              select_options={dbList_options}
                              select_name="database-select"
                              
                            />
                            );
                        
                        default:
                          return null
                      }
                    })()}
                  </div>
                );
              })}
            </div>

          <div className="dialog-submit">


            <button
              type="button"
              className={`Btn ${previous_modal?'btn-prevNext-active':'btn-prevNext'}`}
              onClick={() => navigate(previous_modal)}
              disabled={!Boolean(previous_modal)}
            >
              <img src={left_arrow} width='15px' height='15px' />
            </button>
            <button type="subit" className="Btn btn-submit ">
              Save changes
            </button>

            <button
              type="button"
              className={`Btn ${next_modal?'btn-prevNext-active':'btn-prevNext'}`}
              onClick={() => navigate(next_modal)}
              disabled={!Boolean(next_modal)}
            >
              <img src={right_arrow} width='15px' height='15px' />
            </button>
          </div>

          </form>
        </div>
      
    </>,
    document.getElementById("portal")
  );
};

export default AttachDialog;
