import {USER_FETCH,USER_CREATE,USER_UPDATE,USER_DELETE} from './userActionTypes'
import {GetUser,PostUser,UpdateUser,DeleteUser} from '../../api/userCRUDApi'
import { SetRoleForUserAction } from '../sidebar/roleForUser/RoleForUserActions'

import { SuccessAlert } from '../../components/notifications/notifications'
import { errorHandler } from '../../api/errorHandler'

import { undoCellEdit } from '../../components/crud/tableState'

const GetUserAction=()=>{
   
    
    return async function (dispatch){
        return GetUser()
        .then((response)=>{
    
            
            
            dispatch({
                
                    type:USER_FETCH,
                    payload:response.data,
                
            })
        })
        .catch(error=>errorHandler(error))
    }
    
}


const PostUserAction=({data,role,template_user_id})=>{

    

    return async function (dispatch){

      
       
        return PostUser(data,template_user_id)
        
            .then((response)=>{
            
                dispatch({
                    
                        type:USER_CREATE,
                        payload:response.data,
                    
                })

                dispatch(SetRoleForUserAction(response.data.id,[],role))
               
                SuccessAlert('User created')
            })
            .catch(error=>errorHandler(error))
           
    }
    
}


const UpdateUserAction=({updateData,id,gridRef,template_user_id})=>{
  



    return async function (dispatch){
       
        return UpdateUser(updateData,id,template_user_id)
        .then((response)=>{
           
            dispatch({
                
                    type:USER_UPDATE,
                    payload:{id:id,data:updateData}
                   
            })
            SuccessAlert('User Updated')
           
        })
        .catch((error)=>{
            console.log(error)
            errorHandler(error)
            if(gridRef){
                undoCellEdit(gridRef)
            }
            


        })
    }
    
}


const DeleteUserAction=(id)=>{
  
   
    return  function (dispatch){
       
       
        return DeleteUser(id).then((response)=>{
            
            
            dispatch({
                
                    type:USER_DELETE,
                    payload:id
                   
            })
            SuccessAlert('User deleted')
        })
       
    }
    
}

export {GetUserAction,PostUserAction,UpdateUserAction,DeleteUserAction}