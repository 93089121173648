

import React,{useState,useEffect}from 'react'
import ReactPaginate from 'react-paginate'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";


const arrowStyle = { fontSize: "1.5rem", padding: "0" };

const PaginationInfo=({pageInfo,setPageInfo,gridApi,table})=>{

    

    const totalPage=gridApi?.paginationGetTotalPages()
    const totalRows=gridApi?.getDisplayedRowCount()


    const [jumpPage,setJumpPage]=useState(null)
    
     
    const crrPage=parseInt(gridApi?.paginationGetCurrentPage()+1)

    // const rows_per_page=parseInt(gridApi?.paginationGetPageSize())

    const no_of_entity=JSON.parse(
        localStorage.getItem(`${table}_currentPageSize`)
      );

    const rows_per_page=no_of_entity?no_of_entity:gridApi?.paginationGetPageSize()

    
    
    
    const RowRange=()=>{
        if(totalRows && crrPage && rows_per_page) {

            let start=crrPage+(crrPage-1)*(rows_per_page-1)
            let end=crrPage*rows_per_page


            if(start <= end && end<=totalRows){
                return `${start}-${end}`

            }

            else if(start>end){
                start=end
                return `${start}-${end}`
            }
            else if(end>totalRows){
                end=totalRows
                return `${start}-${end}`
            }
            
        }

        else{
            return null
        }
    }

   



    const handlePageClick=(page)=>{

        const selected_page=page.selected+1
        gridApi.paginationGoToPage(page.selected)
    
        setPageInfo({...pageInfo,currentPage:selected_page})
        setJumpPage(selected_page)

    }

    const handlePageJump=(e)=>{

        const page_input=e.target.value
       
       
        if(page_input<=totalPage){
            gridApi.paginationGoToPage(page_input-1)
            setJumpPage(page_input)
            
            
        }
        
        

   

    }

    
  
    return (
        <div className='pagination-container'>
        <div className='pagination-box'>

            <span id='pagination-info'>
               
                { RowRange() && totalPage?<span>{`Showing ${RowRange()} of ${totalRows}`} Entries</span>:''}

                
               
            </span>
            
           

            <div className='rct-paginate'>
                <ReactPaginate
                    previousLabel={<AiOutlineArrowLeft style={arrowStyle} />}
                    nextLabel={<AiOutlineArrowRight style={arrowStyle} />}
                    breakLabel={'...'}
                 
                    pageCount={parseInt(totalPage)}
                    onPageChange={handlePageClick}

                    currentPage={crrPage}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}

                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}

                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}

                    activeClassName={'active-page'}


                
                />
            </div>

            <div className='page-jump-to'>
                <label html="jumpPage" className='mx-1'>Jump to Page{" "}</label>
                <input 
                    type="number" 
                    value={jumpPage} 
                    onChange={handlePageJump}
                    name="quantity"
                    min='1'
                    size='2'
                    className='mx-1'
                    id="jumpPage"
                />
                
            </div>
        </div>
        </div>

    )
}

export default PaginationInfo