// import { SEND_TO_AIRFLOW } from "../masterdataActionTypes";
import { SuccessAlert } from "../../../components/notifications/notifications";

import { errorHandler } from "../../../api/errorHandler";

import { HideLoaderAction,GetLastDagStateAction } from "../../loader/LoaderAction";
import { SendToAirFlowLeitstandfrüh,SendToAirFlowLeitstandspät,SendToAirFlowLeitstandnacht } from "../../../api/customTableApi/LeitstandApi";

export const SendToAirFlowLeitstandfrühAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlowLeitstandfrüh(table_name)
          .then((response) => {
            
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
          })
          .catch(error=>{
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            errorHandler(error)
          })
      };

  }

  export const SendToAirFlowLeitstandSpätAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlowLeitstandspät(table_name)
          .then((response) => {
            
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
          })
          .catch(error=>{
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            errorHandler(error)
          })
      };

  }


  export const SendToAirFlowLeitstandNachtAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlowLeitstandnacht(table_name)
          .then((response) => {
            
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
          })
          .catch(error=>{
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            errorHandler(error)
          })
      };

  }