import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import './styling_files/index.css';
import './styling_files/dropdown.css'
import './styling_files/tooltip.css'
import './styling_files/pagination.css'
import './styling_files/loginForm.css'
import './styling_files/airflow_config.css'
import './styling_files/layout.css'
import './styling_files/newUiStyles/breadCrumbs.css'

import './styling_files/newUiStyles/actionContainer.css'
import './styling_files/newUiStyles/agGridstyles.css'
import './styling_files/newUiStyles/Dashboard.css'
import './styling_files/newUiStyles/Sidebar.css'
import './styling_files/newUiStyles/userProfile.css'
import './styling_files/newUiStyles/tableView.css'

import './styling_files/newUiStyles/buttons.css'
import './styling_files/newUiStyles/toast.css'

import './styling_files/newUiStyles/allowedtable.css'
import './styling_files/loader.css'

import App from './App';
import { Provider } from 'react-redux';
import Store from './Redux/store';
import 'bootstrap/dist/css/bootstrap.css';


ReactDOM.render(
  <>
    <BrowserRouter>
    <Provider store={Store}>
       <App />
    </Provider>
        
    </BrowserRouter>
    
  </>,
  document.getElementById('root')
);
