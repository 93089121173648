import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";

// importing actions
import { SetSelectedAppType } from "../../../Redux/sidebar/sidebarControl/sidebarControlActions";

export const SidebarIcon = ({ 
    icon_name,
    selectedType,
    type,
    handleSidebar,
    navigateTo}) => 
    
    
    {
    const navigate=useNavigate()
    const dispatch=useDispatch()

    const handleClick=()=>{


        if(type==='home'){
            handleSidebar('hide')

        }
        else{

            if(selectedType===type){
                handleSidebar()
    
            }
            else{
                handleSidebar('show')
            }

        }
        dispatch(SetSelectedAppType(type))

        if(navigateTo){
            navigate(navigateTo)

        }
    }

    return (
        <>
            <button 
                className="nav-icons"
                onClick={handleClick}
            >
                <img 
                    src={icon_name} 
                    alt={icon_name}
                    width='20px'
                    height='20px' />

            </button>
        </>
    )
}

export const SettingsIcon = ({ 
    icon_name,
    selectedType,
    type,
    handleSidebar,
    navigateTo}) => 
    
    
    {
    const navigate=useNavigate()
    const dispatch=useDispatch()

    const handleClick=()=>{


        if(type=='user'||type=='settings'){
            handleSidebar('hide')

        }
        else{

            if(selectedType===type){
                handleSidebar()
    
            }
            else{
                handleSidebar('show')
            }

        }
        dispatch(SetSelectedAppType(type))

        if(navigateTo){
            navigate(navigateTo)

        }
    }

    return (
        <>
            <button 
                className="nav-icons"
                onClick={handleClick}
            >
                <img 
                    src={icon_name} 
                    alt={icon_name}
                    width='20px'
                    height='20px' />

            </button>
        </>
    )
}
