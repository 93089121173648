import React, { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { PostRoleAction } from "../../../Redux/roles/roleAction";
import ReactDOM from "react-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { MultiSelectDropDown } from "../crudTableComponents/FormSelect";

import { openApp, changeModeFlow } from "../../../Redux/modals/modalActions";

import { crossIcon, noti_info_error, plusIconBlack, left_arrow, right_arrow } from "../../../styling_files/newUiStyles/icons";
import { closeRole, removeRoute, addRoute } from "../../../Redux/modals/modalActions";

const RoleCreateDialog = ({ roleModalOpen, roleModalClose }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate()

 

  const modal_routes = useSelector(state => state.modals.modal_routes)
  const apps = useSelector((state) => state.apps.apps);

  //previous-current-next routes

  const current_modal = useLocation().pathname
  const previous_modal = modal_routes[modal_routes.indexOf(current_modal) - 1]
  const next_modal = modal_routes[modal_routes.indexOf(current_modal) + 1]

  const [currentRoleApps, setCurrentRoleApps] = useState();


  //create options for react-select

  const options = apps?.map((app) => {
    return { value: app.id, label: app.application_name };
  });

  const data = {
    name: "",
    description: "",
    app: '',
    create_app: ""
  }

  const initial_formik_values = JSON.parse(localStorage.getItem('role-create')) ? JSON.parse(localStorage.getItem('role-create')) : data




  const validationSchema = Yup.object({
    name: Yup.string().required("*required"),
    description: Yup.string().required("*required"),
  });

  const onSubmit = (values) => {

    if (modal_routes.length > 0) {

      if(modal_routes.length===1 && modal_routes[0]===current_modal){
      
        dispatch(PostRoleAction({ data: values, apps: currentRoleApps }));
        roleModalClose()
        dispatch(removeRoute(current_modal))
        formik.setValues(data);
        localStorage.removeItem('role-create')

      }
      else{
        navigate(previous_modal)
        dispatch(removeRoute(current_modal))

        dispatch(PostRoleAction({ data: values, apps: currentRoleApps }));
        roleModalClose()
        formik.setValues(data);
        localStorage.removeItem('role-create')
      }
      

    }
    else {
      dispatch(PostRoleAction({ data: values, apps: currentRoleApps }));
      roleModalClose()
      dispatch(removeRoute(current_modal))
      formik.setValues(data);
      localStorage.removeItem('role-create')

    }

  };

  const handleClose = () => {
    if (modal_routes.length > 0) {

      if(modal_routes.length===1 && modal_routes[0]===current_modal){
        
       roleModalClose()
       dispatch(removeRoute(current_modal))
       formik.setValues(data);
       localStorage.removeItem('role-create')

      }
      else{
        navigate(previous_modal?previous_modal:current_modal)
      dispatch(removeRoute(current_modal))
      roleModalClose()
      formik.setValues(data);
      localStorage.removeItem('role-create')

      }
      

    }
    else {
      roleModalClose()
      dispatch(removeRoute(current_modal))
      formik.setValues(data);
      localStorage.removeItem('role-create')

    }

  }

  const navigateToApp = () => {
    navigate('/dashboard/settings/apps')
    dispatch(addRoute('/dashboard/settings/apps'))
    dispatch(openApp())


  }


  //handling app change
  const handleApp = (e) => {
    const dropdownValues = e.map((item) => item);
    setCurrentRoleApps(dropdownValues);
  };

  const formik = useFormik({
    initialValues: initial_formik_values,
    validationSchema,
    onSubmit,

  });

  useEffect(() => {

    localStorage.setItem('role-create', JSON.stringify(formik.values))
    const storedValues = JSON.parse(localStorage.getItem('role-create'));

  }, [formik.values]);


  const dataKeys = data ? Object.keys(data) : [];

  if (!roleModalOpen) {
    return null;
  }


  

  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>

      <div className="modelForm">
        <div className="dialog-header">
          <div className="dialog-title">Create New Role</div>
          <div className="dialog-close bc-clickable">
            <img
              src={crossIcon}
              onClick={handleClose}
            />
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();

            formik.handleSubmit(e);
          }}
          className="dialog-form"
        >
          <div className="dialog-form-fields" 
          >
            {dataKeys.map((data) => {
              return (
                <div className="form-fields">
                  <label htmlFor={data} className="input-label">
                    {data} :
                  </label>

                  {(() => {
                    switch (data) {

                      case "app":
                        return (

                          <MultiSelectDropDown

                            name="app"
                            isMulti={true}
                            value={currentRoleApps}
                            onChangeHandler={handleApp}
                            select_options={options}
                            menuPlacement='bottom'
                            
                            

                          />

                        );
                      case 'create_app':
                        return (
                          <div className="button-container">
                            <span className="button-label mx-2"></span>
                            <button className="Btn attachBtn" onClick={navigateToApp} type='button' >
                              <div>
                                <img
                                  className="mx-1 mb-1"
                                  src={plusIconBlack}
                                  alt="new"
                                  width="15px"
                                  height="15px"
                                  title="New"
                                />
                              </div>
                              <span className="mx-1 fs-6">Create App</span>
                            </button>
                          </div>

                        )

                      default:
                        return (
                          <>
                            <div className="dialog-input-container">
                              <input
                                type={
                                  data == "db_password" ? "password" : "text"
                                }
                                className="form-control my-1"
                                id={data}
                                name={data}
                                value={formik.values[data]}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={`Enter ${data}`}
                              />

                              {formik.touched[data] && formik.errors[data] && (
                                <img
                                  className="input-error-icon"
                                  src={noti_info_error}
                                  width="15px"
                                  height="15px"
                                />
                              )}
                            </div>

                            <div>
                              {formik.touched[data] && formik.errors[data] ? (
                                <div className="error">
                                  {formik.errors[data]}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                    }
                  })()}
                </div>
              );
            })}
          </div>

          <div className="dialog-submit">


            <button
              type="button"
              className={`Btn ${previous_modal?'btn-prevNext-active':'btn-prevNext'}`}
              onClick={() => navigate(previous_modal)}
              disabled={!Boolean(previous_modal)}
            >
              <img src={left_arrow} width='15px' height='15px' />
            </button>
            <button type="subit" className="Btn btn-submit ">
              Save changes
            </button>

            <button
              type="button"
              className={`Btn ${next_modal?'btn-prevNext-active':'btn-prevNext'}`}
              onClick={() => navigate(next_modal)}
              disabled={!Boolean(next_modal)}
            >
              <img src={right_arrow} width='15px' height='15px' />
            </button>
          </div>

        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default RoleCreateDialog;
