import React, { useState, useEffect } from "react";

import * as TiIcons from 'react-icons/ti'

import { GetColumnsPermAction,SavePermissionAction } from "../../../../Redux/appColumnPermission/actions";

import {useParams} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import apiSchemaMapper from "../apiSchemaMapper";
import CheckBox from "../checkBox";

import { downloadIcon } from "../../../../styling_files/newUiStyles/icons";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { DbGridTable } from "../../../../MainView/MainView.Styled";

import SearchField from "../../../crud/crudTableComponents/searchField";
import AgGridPageSize from "../../../crud/crudTableComponents/agGridPageSize";
import { SetCurrentAppTableAction } from "../../../../Redux/currentAppTable/actions";


import {  
  onFilterChange,
  onFilterTextChange,
  RenderState,
  handleReactPagination,
  onDragStopped, } from "../../../crud/tableState";

//importing crud components

import PaginationInfo from "../../../crud/crudTableComponents/pagintaiton";
import { BreadCrumbs } from "../../../crud/crudTableComponents/breadCrumbs";

const TableParmissionGrid = () => {


    
  const {appID}=useParams()
  const {dbID}=useParams()
  const {appname}=useParams()
  const {tablename}=useParams()
  const {allowed_table_id}=useParams()

  const table=`${appname}_${tablename}_permission`

  
  const dispatch = useDispatch()

  const colPerms=useSelector(state=>state.colPermissions.colpermissions)


  const totalPageSaved = localStorage.getItem(`${table}_totalPage`);

  let [pageInfo,setPageInfo]=useState({currentPage:0,totalPage:totalPageSaved||1})

  let [quickFilterText, setQuickFilterText] = useState(null);

  let [pageSize,setPageSize]=useState(null)

  sessionStorage.setItem('colperms',JSON.stringify(colPerms))
 






  //add list and delete list for api call

  const [apiData,setApiData]=useState({addDataList:[],delDataList:[]})

  useEffect(() => {
    dispatch(
      SetCurrentAppTableAction({
        currentTable:'Permissions',
        currentApp:`${appname}- ${tablename}`,
      })
    );
  }, []);

  useEffect(()=>{
    let column_state = JSON.parse(localStorage.getItem(`${table}_column_state`));
    gridColumnApi?.applyColumnState({state:column_state, applyOrder: true});

  },)


  useEffect(() => {
    dispatch(GetColumnsPermAction(appID, dbID, allowed_table_id));
  }, []);

  useEffect(()=>{
    sessionStorage.setItem('colperms',JSON.stringify(colPerms))

  },[])
 
  const columnDefs=[
    {headerName:'Table content',field:'column_name',filter:'agTextColumnFilter'},

    {headerName:'Read',field:'read',
    cellRenderer:CheckBox, 
    cellRendererParams:{currentData:colPerms,allowed_table_id:allowed_table_id,apiData:apiData,setApiData:setApiData}
  },

    {headerName:'Create',field:'create',
    cellRenderer:CheckBox,
    cellRendererParams:{currentData:colPerms,allowed_table_id:allowed_table_id,apiData:apiData,setApiData:setApiData}},

    {headerName:'Update',field:'update',cellRenderer:CheckBox,
     cellRendererParams:{currentData:colPerms,allowed_table_id:allowed_table_id,apiData:apiData,setApiData:setApiData}},

    {headerName:'Delete',field:'delete',cellRenderer:CheckBox,
    cellRendererParams:{currentData:colPerms,allowed_table_id:allowed_table_id,apiData:apiData,setApiData:setApiData}},

  ]

  //dafault column def
  const defaultColDefs={
    minWidth:200,
    headerClass:'header-class',
    cellClass:'cell-class',
    flex:1,
    sortable:true,
    resizable:true,
    icons: {
        sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
        sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
      }
   
   }

 
  const handleSubmit=()=>{
    const initial=JSON.parse(sessionStorage.getItem('colperms'))
    const current=JSON.parse(sessionStorage.getItem('current'))
    
    const {addList,delList}=apiSchemaMapper(initial,current,allowed_table_id)
   
    dispatch(SavePermissionAction(appID,dbID,allowed_table_id,addList,delList))
    
 
    
  }

    //grid api states
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
  
    //grid Ready
    const onGridReady = (params) => {
      RenderState(params, table, setQuickFilterText, setPageSize);
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
    };


    //on first time data rendering
    const onFirstDataRendered = (params) => {
     
      RenderState(params, table, setQuickFilterText, setPageSize);
    };
  

  
  
  

    //page size

      const onPageSizeChange=(pgSize)=>{
        gridApi.paginationSetPageSize(Number(pgSize))
        setPageSize(pgSize)
        localStorage.setItem(`${table}_currentPageSize`, JSON.stringify(pgSize));

        //current page state change
    
        handleReactPagination(gridApi, pageInfo, setPageInfo, table);

      
         }
          
    //Exporting
      
         const onExportasCSV=()=>{
           gridApi.exportDataAsCsv()
      
         }
    

  return (

    <>
    <BreadCrumbs />

    
    <div className="title-layout">
        <div className="title-container">
          <div className="current-app-table">
            <p> {tablename} Permissions</p>
          </div>
        </div>

      


    </div>
    <div className='actions-layout'>
        <div className="actions-container-backdrop">
        <div className="actions-container">
        <div className="searchfield-container">

        <span className="button-label">Search Data</span>
        <SearchField
                quickFilterText={quickFilterText}
                setQuickFilterText={setQuickFilterText}
                onFilterTextChange={onFilterTextChange}
                gridApi={gridApi}
                table={table}
              />
        </div>

        <div className="pagesize-container">
              <span className="button-label">Rows</span>

              <AgGridPageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
        </div>

        <div className="line-seperator-container">
              <div className="line-seperator"></div>
        </div>

        <div className="button-container">
        <span className="button-label"></span>

         {/* export button */}
         <button className="Btn DownloadBtn " onClick={onExportasCSV}>
                <div>
                  <img
                    className="mx-1 mb-1"
                    src={downloadIcon}
                    alt="csv"
                    width="17px"
                    height="17px"
                  />
                  <span className="mx-1">Export CSV</span>
                </div>
        </button>


        </div>

        <div className="button-container">
                <span className="button-label"></span>
                <button className="Btn createBtn" onClick={handleSubmit}>
                  <div>
                  <TiIcons.TiTick className="mx-1"/>
                  </div>
                  <span className="mx-1">Apply</span>
                </button>
              </div>

        </div>
        </div>
    </div>

    <div className="table-layout">

    <div className="table-container">

    <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%" }}
          >
             <AgGridReact
            rowData={colPerms}
            columnDefs={columnDefs}
            rowSelection="single"
            defaultColDef={defaultColDefs}
            onGridReady={onGridReady}

            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}

            headerHeight={30}
            rowHeight={40}
            pagination={true}
            suppressPaginationPanel={true}
            paginationPageSize={pageSize}
            suppressDragLeaveHidesColumns={true}

            onFilterChanged={(params) => onFilterChange(params, table)}
            onFirstDataRendered={(params)=>onFirstDataRendered(params)}
            onDragStopped={(params) => onDragStopped(params, table)}
            onSortChanged={(params) => onDragStopped(params, table)}

           
                       
          ></AgGridReact>
        </div>

    </div>
    <PaginationInfo
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        gridApi={gridApi}
        table={table}
      />
    </div>


   
    </>
  );
};

export default TableParmissionGrid;
