import {toast} from 'react-toastify'
import {SuccessAlertToast,ErrorAlertToast,InfoAlertToast} from './customToast'

const configs={
    position: "top-right",
    autoClose:1500,
    hideProgressBar: true,
    closeButton:false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeOnClick:false,
    className:'react-toast'
   
  }

const error_config={...configs,autoClose:2000}

export const SuccessAlert=(message)=>{
    
    toast(<SuccessAlertToast message={message}/>, configs);

}

export const ErrorAlert=(message)=>{
    toast(<ErrorAlertToast message={message}/>,error_config);

}

export const InfoAlert=(message)=>{
    
    toast(<InfoAlertToast message={message}/>, configs);


}