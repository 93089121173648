import React,{useState,useEffect} from 'react'
import { GetTableForAppAction } from '../../../Redux/appPermission/appPermissionActions'
import { GetDbAction } from '../../../Redux/dbcredentials/dbActions'
import { GetAllowedTable } from '../../../api/allowedTableApi'
import { useLocation,useParams} from 'react-router-dom'
import { mapDbToAllowedTable } from './dbAllowedTable'
import { useDispatch,useSelector } from 'react-redux'
import AppTableList from './appTableList'

const AppTable=()=>{

    const AppTables=useSelector(state=>state.appTables.appTables)
    
    const DbData=useSelector(state=>state.dbcredentials.dbcredentials)

    

   
    const dispatch=useDispatch()
    const {appID}=useParams()
    const {appName}=useParams()

     
    const [appTables,setAppTables]=useState([])
    const [dbList,setDbList]=useState([])
    const [dbIds,setDbIds]=useState([])
    const [dbListID,setDbListID]=useState([])
    const [dbTableOptions,setDbTableOptions]=useState([])
    const [currentdbTableOptions,setCurrentDbTableOptions]=useState([])
    const [currentSelectedTable,setCurrentSelectedTable]=useState([])

    useEffect(()=>{
        
        
        if(parseInt(appID)) dispatch( GetTableForAppAction(appID))
        dispatch(GetDbAction())

    },[])

    useEffect(
        ()=>{
            setAppTables(AppTables)

        },[AppTables])

    useEffect(
        ()=>{
            //setting db list ID
            const db_id=DbData.map(item=>item.id)
            const db_list=DbData.map(item=>item.database_alias)
            const obj = {};

            db_list.forEach((element, index) => {
                obj[element] = db_id[index];
              });

            setDbListID(obj)
            setDbIds(db_id)
            setDbList(db_list)

        },[DbData])

    useEffect(
        ()=>{

            //setting db allowed table
            
           Promise.all(dbIds.map(id=>{

            return GetAllowedTable(id)

           }))
           .then(response=>setDbTableOptions(mapDbToAllowedTable(response)))
           .catch(error=>console.log(error))



        },[dbListID]
    )
    
    
    //select options based on db name
    const selectDbOptions=(dbname)=>{
        const allowed_tables=dbTableOptions.filter(item=>item.db_name==dbname).map(item=>item.allowed_tables)
        
        setCurrentDbTableOptions(allowed_tables)
        
    }
    const selectDbSelected=(dbname)=>{
        const selected=appTables.filter(table=>table.db_alias==dbname).map(table=>{
            return {value:table.id,label:table.table_name}
          })
          
          setCurrentSelectedTable(selected)
    }
    
  
    return (
        <>
        
        <AppTableList 
            AppTables={appTables}
            appID={appID}
            dbList={dbList}
            appName={appName}
            selectDbOptions={selectDbOptions}
            currentdbTableOptions={currentdbTableOptions}

            currentSelectedTable={currentSelectedTable}
            setCurrentSelectedTable={setCurrentSelectedTable}
            selectDbSelected={selectDbSelected}

            

        />
        </>
    )
}


export default AppTable