import React from 'react'
import Auth from './auth'

import {useDispatch} from 'react-redux'
import { ClearCurrentUserAction } from '../../Redux/userProfile/userProfileActions'

import { Navigate, useNavigate } from 'react-router-dom'

function LogOut() {

  const {logOut}=Auth()
  const dispatch=useDispatch()
  const navigate=useNavigate()

  const handleClick=()=>{


    console.log('looggging out.............')
    dispatch(ClearCurrentUserAction())
    navigate('/')
    logOut()

  }
    
  
  return (
    <div>
        <button className='Btn logoutBtn' onClick={handleClick}>
         <span className='mx-3'>Log Out</span> 
        </button>
    </div>
  )
}

export default LogOut