import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppAction } from "../../../Redux/app/appActions";

import { useFormik } from "formik";

import * as Yup from "yup";
import { FormDropDown } from "../crudTableComponents/FormSelect";
import {
  crossIcon,
  noti_info_error,
} from "../../../styling_files/newUiStyles/icons";

const AppUpdateDialog = ({
  selectedID,
  setSelectedID,
  showModal,
  setShowModal,
}) => {
  const dispatch = useDispatch();

  const Apps = useSelector((state) => state.apps.apps);
  const selectedApp = Apps ? Apps.find((App) => App.id == selectedID) : null;

  useEffect(() => {
    setFormData(selectedApp);
  }, [selectedApp]);

  const data = {
    application_name: "",
    application_description: "",
    application_type: "",
    hide_primary_key: "",
  };


  const handleApptype=(e)=>{
    formik.setFieldValue('application_type',e.value)
  }

  const handleHidePrimary=(e)=>{
    formik.setFieldValue('hide_primary_key',e.value)
  }


  const onSubmit = (values) => {
    if (selectedID) {
      dispatch(UpdateAppAction(values, selectedID));
    }

    setShowModal(false);

    formik.resetForm();
    setSelectedID(null);
  };
  const validationSchema = Yup.object({
    application_name: Yup.string().required("required"),
    application_description: Yup.string().required("required"),
  });

  const [formData, setFormData] = useState(selectedApp);
  const dataKeys = data ? Object.keys(data) : [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData || data,
    onSubmit,
    validationSchema,
  });

  if (!showModal) {
    return null;
  }

  console.log('form data',formData)
  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>

      <div className="modelForm">
        <div className="dialog-header">
          <div className="dialog-title">Edit App</div>
          <div className="dialog-close bc-clickable">
            <img
              src={crossIcon}
              onClick={() => {
                setShowModal(false);

                formik.resetForm();
              }}
            />
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
          className="dialog-form"
        >
          <div className="dialog-form-fields">
            {dataKeys.map((data) => {
              return (
                <div className="form-fields">
                  <label htmlFor={data} className="input-label">
                    {data} :
                  </label>

                  {(() => {
                    switch (data) {
                      case "application_type":
                        return (
                          <FormDropDown
                            value={{value:formik.values[data],label:formik.values[data]}}
                            onChangeHandler={handleApptype}
                            select_options={[
                              { value: "crud", label: "CRUD" },
                              { value: "airflow", label: "Airflow" },
                              { value: "custom", label: "Custom" },
                            ]}
                            select_name="type-select"
                          />
                        );
                        case "hide_primary_key":
                          return (
                            <FormDropDown
                            value={{value:formik.values[data],label:formik.values[data]?'Yes':'No'}}
                            onChangeHandler={handleHidePrimary}
                            select_options={[{value:false,label:"No"},{value:true,label:'Yes'}]}
                            select_name='hide_primary-select'
          
                          />
                          );

                      default:
                        return (
                          <>
                            <div className="dialog-input-container">
                              <input
                                type={
                                  data == "db_password" ? "password" : "text"
                                }
                                className="form-control my-1"
                                id={data}
                                name={data}
                                value={formik.values[data]}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={
                                  data === "password" ? "Set new password" : ""
                                }
                              />
                              {formik.touched[data] && formik.errors[data] && (
                                <img
                                  className="input-error-icon"
                                  src={noti_info_error}
                                  width="15px"
                                  height="15px"
                                />
                              )}
                            </div>

                            <div>
                              {formik.touched[data] && formik.errors[data] ? (
                                <div className="error">
                                  {formik.errors[data]}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                    }
                  })()}
                </div>
              );
            })}
          </div>

          <div className="dialog-submit">
            <button type="submit" className="Btn btn-submit">
              Save changes
            </button>
          </div>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default AppUpdateDialog;
