import {FETCH_ALLOWED_TABLES,SAVE_ALLOWED_TABLES} from './actionTypes'

const initialState={
    allowedTables:[],
    errors:[]
}

const AllowedTableReducer=(state=initialState,action)=>{
    switch(action.type){
        
        case FETCH_ALLOWED_TABLES:
            const all_allowedTables=[...action.payload]
            const newfetchState={...state,allowedTables:all_allowedTables,errors:[]}
            return newfetchState

        case SAVE_ALLOWED_TABLES:
            const new_allowedTables=[...action.payload]
            const newupdateState={...state,allowedTables:new_allowedTables,errors:[]}
            return newupdateState

        default:
            return state
    }
}



export default AllowedTableReducer