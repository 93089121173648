import {SHOW_LOADER,HIDE_LOADER,GET_LAST_DAG_STATE,REFRESHING,CLEAR_DAG_STATE} from './LoaderActionTypes'
import { GetLastDagState } from '../../api/customTableApi/getLastDagStateApi'
import { errorHandler } from '../../api/errorHandler'
import { SuccessAlert } from '../../components/notifications/notifications'

export const ShowLoaderAction=(message)=>{

    return {
        type:SHOW_LOADER,
        payload:message
    }


}

export const HideLoaderAction=()=>{
    return {
        type:HIDE_LOADER
    }
}

export const GetLastDagStateAction=(appname,tablename)=>{
   
    return async function (dispatch){

        dispatch({
            type:REFRESHING,
            payload:true
        })
        
        return GetLastDagState(appname,tablename)
        
        
        .then((response)=>{
            

            dispatch({
                
                    type:GET_LAST_DAG_STATE,
                    payload:response.data.state,
                
            })
        })
        .catch(error=>{
            errorHandler(error)
            dispatch({
                type:REFRESHING,
                payload:false
            })
            
        })
    }
    
}

export const ClearDagState=()=>{

    return {
        type:CLEAR_DAG_STATE
    }

}