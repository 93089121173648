import {AxiosRequest} from './rootApi'


const GetApp=()=>{

    return AxiosRequest({url:'/api/v1/applications/',method:'GET'})
}

const PostApp=(data)=>{
    
    return AxiosRequest({url:'/api/v1/applications/',method:'POST',params:data})
}

const UpdateApp=(updateData,id)=>{
    
    return AxiosRequest({url:`/api/v1/applications/${parseInt(id)}`,method:'PUT',params:updateData})
}

const DeleteApp=(id)=>{
   
    return AxiosRequest({url:`/api/v1/applications/${parseInt(id)}`,method:'DELETE'})
}

const GetSingleApp=(id)=>{
    return AxiosRequest({url:`/api/v1/applications/${parseInt(id)}`,method:'GET'})

}
export {GetApp,PostApp,UpdateApp,DeleteApp,GetSingleApp}